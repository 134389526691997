import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { basicSetup, EditorState, EditorView } from '@codemirror/basic-setup';
import { html } from '@codemirror/lang-html';
import { YdocService } from '@app/editor/services/ydoc.service';
import { uuidv4 } from 'lib0/random';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editors.service';
import { citationElementMap } from '@app/editor/services/citable-elements.service';
import { endNoteJSON } from '@app/editor/utils/section-templates/form-io-json/endNoteFormIOJSON';
import { endNote } from '@app/editor/utils/interfaces/endNotes';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { filterFieldsValues } from '@app/editor/utils/fieldsMenusAndScemasFns';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import { MaterialModule } from '@app/shared/material.module';
import { FormControlNameDirective } from '@app/editor/directives/form-control-name.directive';
import { MatFormioModule } from '@app/formio-angular-material/angular-material-formio.module';
import { FormioModule } from '@formio/angular';
import { CommonModule } from '@angular/common';
import { FormBuilderService } from '@app/editor/services/form-builder.service';

@Component({
  selector: 'app-add-end-note',
  templateUrl: './add-end-note.component.html',
  styleUrls: ['./add-end-note.component.scss'],
})
export class AddEndNoteComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container?: ViewContainerRef;

  initFunc: (selfRef: any) => () => void;
  renderCodemMirrorEditors: (selfRef: any) => (endNoteId: string) => string;
  onChange: (selfRef: any) => (change: any) => void;
  submitEndNote: (selfRef: any) => () => void;
  onSubmit: (selfRef: any) => (submission?: any) => void;

  defaultSubmitEndNote: () => void;
  defaultOnChange: (change: any) => void;
  defaultOnSubmit: (submission?: any) => void;

  sectionContent = JSON.parse(JSON.stringify(endNoteJSON));
  renderForm = false;
  section = { mode: 'editMode' };
  sectionForm = new UntypedFormGroup({});
  endNoteID?: string;
  hidehtml = true;
  endNotesTemplatesObj: any;
  codemirrorHTMLEditor: EditorView;
  codemirrorHtmlTemplate: HTMLElement;
  isValid: boolean = false;
  formIoSubmission: any;
  formIoRoot: any;
  modalTemplate: string;

  constructor(
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AddEndNoteComponent>,
    private serviceShare: ServiceShare,
    private ydocService: YdocService,
    private formBuilderService: FormBuilderService,
    @Inject(APP_CONFIG) readonly config: AppConfig,
    @Inject(MAT_DIALOG_DATA)
    public data: { endNote: endNote; updateOnSave: boolean; index: number; endNoteID: string }
  ) {
    this.modalTemplate = this.ydocService.endNotesMap.get('endNotesModalTemplate') as string;
    const self = this;
    this.initFunc = (selfRef: this) => () => {
      try {
        selfRef.codemirrorHtmlTemplate = document.querySelector('.codemirrorHtmlTemplate');
        let endNotesInitialFormIOJson = self.ydocService.endNotesMap!.get(
          'endNotesInitialFormIOJson'
        );
        if (endNotesInitialFormIOJson) {
          selfRef.sectionContent = JSON.parse(JSON.stringify(endNotesInitialFormIOJson));
        }
        selfRef.endNoteID = data.endNoteID || uuidv4();
        selfRef.sectionContent.props = { isCitableElement: true };
        let endNoteHTML = selfRef.renderCodemMirrorEditors(selfRef)(selfRef.endNoteID!);
        self.formBuilderService.setAutoFocusInSchema(selfRef.sectionContent);

        if (selfRef.data.endNote) {
          selfRef.sectionContent.components[0].defaultValue = selfRef.data.endNote.endNote;
          let submision = {
            data: citationElementMap.end_note_citation.getElFormIOSubmission(self.data.endNote),
          };
          filterFieldsValues(
            selfRef.sectionContent,
            submision,
            self.serviceShare,
            undefined,
            false,
            endNoteHTML,
            false
          );
          let filterdTableData = {
            end_note: submision.data.endNote,
          };
          selfRef.sectionContent.components[0].defaultValue = filterdTableData.end_note;
          selfRef.renderForm = true;
        } else {
          selfRef.renderForm = true;
        }
      } catch (e) {
        console.error(e);
      }
    };

    this.renderCodemMirrorEditors = (selfRef: this) => (endNoteID: string) => {
      try {
        selfRef.endNotesTemplatesObj = self.ydocService.endNotesMap?.get('endNotesTemplates');
        let endNotesInitialTemplate = self.ydocService.endNotesMap!.get('endNotesInitialTemplate');

        let currEndNoteTemplate;
        if (!selfRef.endNotesTemplatesObj[endNoteID]) {
          selfRef.endNotesTemplatesObj[endNoteID] = { html: endNotesInitialTemplate };
          currEndNoteTemplate = selfRef.endNotesTemplatesObj[endNoteID];
        } else {
          currEndNoteTemplate = selfRef.endNotesTemplatesObj[endNoteID];
        }
        let prosemirrorNodesHtml = currEndNoteTemplate.html;

        selfRef.codemirrorHTMLEditor = new EditorView({
          state: EditorState.create({
            doc: prosemirrorNodesHtml,
            extensions: [basicSetup, html()],
          }),
          parent: selfRef.codemirrorHtmlTemplate,
        });
        return prosemirrorNodesHtml;
      } catch (e) {
        console.error(e);
      }
    };

    this.onChange = (selfRef: any) => (change: any) => {
      if (change instanceof Event) {
      } else {
        selfRef.isValid = change.data.endNote?.length > 0;
        selfRef.formIoSubmission = change.data;
        if (change.changed && change.changed.instance) {
          selfRef.formIoRoot = change.changed.instance.root;
        }
      }
    };

    this.submitEndNote = (selfRef: this) => () => {
      if (selfRef.formIoRoot) {
        selfRef.formIoRoot.submit();
      }
    };

    this.onSubmit = (selfRef: this) => async (submision?: any) => {
      try {
        const tr = selfRef.codemirrorHTMLEditor?.state.update();
        selfRef.codemirrorHTMLEditor?.dispatch(tr);
        const prosemirrorNewNodeContent = selfRef.codemirrorHTMLEditor?.state.doc.sliceString(
          0,
          selfRef.codemirrorHTMLEditor?.state.doc.length
        );

        selfRef.endNotesTemplatesObj[selfRef.endNoteID] = { html: prosemirrorNewNodeContent };
        ydocService.endNotesMap?.set('endNotesTemplates', selfRef.endNotesTemplatesObj);

        submision.data.end_note_ID = selfRef.endNoteID;
        submision.data.end_note_number = selfRef.data.index;
        const newEndNote: endNote = {
          endNote: submision.data.endNote,
          end_note_number: selfRef.data.index,
          end_note_ID: submision.data.end_note_ID,
        };

        if (self.prosemirrorEditorsService.shouldTrackChanges) {
          const dataIdRegex = /^<p[^>]*><span[^>]*data-id="([^"]*)"[^>]*>[^<]*<\/span><\/p>$/;
          const match = submision.data.endNote.match(dataIdRegex);

          if (match) {
            const dataId = match[1];
            newEndNote['trackChangeId'] = dataId;
          }
        }

        let result = { endNote: newEndNote };
        dialogRef.close(result);
      } catch (error) {
        console.error(error);
      }
    };
    this.defaultOnChange = this.onChange(this);
    this.defaultSubmitEndNote = this.submitEndNote(this);
    this.defaultOnSubmit = this.onSubmit(this);
  }

  ngAfterViewInit(): void {
    if (this.modalTemplate) {
      setTimeout(() => {
        this.createComponent(this.modalTemplate);
      }, 0);
    } else {
      this.initFunc(this)();
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  component: ComponentRef<any>;
  createComponent(template: string): void {
    const self = this;
    const data = this.data;
    const config = this.config;

    const component = Component({
      template,
      standalone: true,
      imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatFormioModule,
        FormioModule,
        FormControlNameDirective,
      ],
    })(
      class implements AfterViewInit {
        sectionContent = JSON.parse(JSON.stringify(endNoteJSON));
        renderForm = false;
        data = data;
        config = config;
        section = { mode: 'editMode' };
        sectionForm = new UntypedFormGroup({});
        endNoteID?: string;
        hidehtml = true;

        initFunc = self.initFunc;
        renderCodemMirrorEditors = self.renderCodemMirrorEditors;
        onChange = self.onChange(this);
        submitEndNote = self.submitEndNote(this);
        onSubmit = self.onSubmit(this);

        ngAfterViewInit(): void {
          this.initFunc(this)();
        }

        endNotesTemplatesObj: any;
        codemirrorHTMLEditor: EditorView;
        codemirrorHtmlTemplate: HTMLElement;

        isValid: boolean = false;
        formIoSubmission: any;
        formIoRoot: any;
      }
    );
    this.component = this.container.createComponent(component);
  }

  ngOnDestroy(): void {
    this.component?.destroy();
    this.container?.clear();
  }
}
