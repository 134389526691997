import { Component } from '@angular/core';
import { EnforcerService } from '@app/casbin/services/enforcer.service';
import { PermissionObjectAction } from '@app/core/models/auth.model';
import { ArticleRole, ArticleRoleAbbreviationMapping } from './article-role.models';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';

@Component({
  selector: 'awt-article-role',
  templateUrl: './article-role.component.html',
  styleUrls: ['./article-role.component.scss'],
})
export class ArticleRoleComponent {
  private editModePermissions: PermissionObjectAction<'editMode'>[];

  private commentPermissions: PermissionObjectAction<'comments'>[];

  private readonly errorMessage = 'No proper article role was retrieved!';

  private readonly abbreviationRoleMap: ArticleRoleAbbreviationMapping = {
    [ArticleRole.canCommentOnly]: {
      short: 'CO',
      details: 'Comment only role: You can only add comments to this article.',
    },
    [ArticleRole.editor]: {
      short: 'ED',
      details: 'Editor role: You have full access to modify this article.',
    },
    [ArticleRole.readOnly]: {
      short: 'RO',
      details: 'Read only role: You can only view this article.',
    },
    [ArticleRole.suggester]: {
      short: 'SG',
      details: 'Suggestion role: You can make suggestions about the article.',
    },
  };

  constructor(
    private enforcerService: EnforcerService,
    private snackBar: SnackbarService
  ) {
    this.editModePermissions = this.enforcerService.getAllowedActions('editMode');
    this.commentPermissions = this.enforcerService.getAllowedActions('comments');
  }

  get role(): ArticleRole {
    if (this.isEditor) {
      return ArticleRole.editor;
    } else if (this.isSuggester) {
      return ArticleRole.suggester;
    } else if (this.canCommentOnly) {
      return ArticleRole.canCommentOnly;
    } else if (!this.isReadOnly) {
      this.riseError();
    }

    return ArticleRole.readOnly;
  }

  get shortRoleName(): string {
    return this.abbreviationRoleMap[this.role].short;
  }

  get details(): string {
    return this.abbreviationRoleMap[this.role].details;
  }

  private get isEditor(): boolean {
    return this.editModePermissions.includes('edit');
  }

  private get isSuggester(): boolean {
    return (
      !this.editModePermissions.includes('edit') && this.editModePermissions.includes('suggest')
    );
  }

  private get canCommentOnly(): boolean {
    return this.editModePermissions.length === 0 && this.commentPermissions.includes('view');
  }

  private get isReadOnly(): boolean {
    return (
      this.commentPermissions.length === 0 &&
      this.editModePermissions.length === 1 &&
      this.editModePermissions[0] === 'preview'
    );
  }

  private riseError(): void {
    console.error(this.errorMessage);
    this.snackBar.error(this.errorMessage);
  }
}
