import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '../notifications/notifications.service';

@Component({
  selector: 'app-allnotifications',
  templateUrl: './allnotifications.component.html',
  styleUrls: ['./allnotifications.component.scss'],
})
export class AllnotificationsComponent implements AfterViewInit {
  displayedColumns: string[] = ['status', 'event', 'document', 'date'];
  allNotifications = [];

  constructor(
    private notificationService: NotificationsService,
    private changeDetection: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AllnotificationsComponent>
  ) {}

  ngAfterViewInit(): void {
    this.notificationService.notificationsBehaviorSubject.subscribe((notifications: any[]) => {
      this.allNotifications = notifications.sort((a, b) => b.date - a.date);
      this.changeDetection.detectChanges();
    });
    this.notificationService.getAllNotifications();
  }

  viewNotification(event) {
    this.notificationService.viewNotification(event);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getName(element) {
    return element.data?.article_title || element.docName;
  }

  getEvent(element) {
    return element.event;
  }
}
