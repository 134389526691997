import { taxonSection } from '@core/services/custom_sections/taxon';

export const taxonTreatmentSection = {
  id: 9909,
  name: 'Taxon treatments',
  label: 'Taxon treatments',
  label_read_only: true,
  schema: {
    display: 'form',
    components: [],
  },
  sections: [taxonSection],
  template: '<div></div>',
  type: 1,
  version_id: 525,
  version: 9,
  version_pre_defined: false,
  version_date: '2022-06-08T21:02:49.000000Z',
  complex_section_settings: [],
  settings: null,
  compatibility: {
    allow: {
      all: false,
      values: [9999],
    },
    deny: {
      all: true,
      values: [],
    },
  },
  created_at: '2022-06-05T12:18:05.000000Z',
};
