// TODO - move in the component or where applicable
export abstract class CONSTANTS {
  static readonly DISMISS = 'dismiss-alert';
  static readonly ERROR = 'error-alert';
  static readonly SHOW_LOADER = 'show-loader';
  static readonly AUTH_HEADER = 'Authorization';
  static readonly refreshToken = 'refreshToken';
  static readonly USERNAME = 'username';
  static readonly PASSWORD = 'password';
  static readonly EMAIL = 'email';
  static readonly NAME = 'name';
  static readonly TOKEN_PREFIX = 'ps-';
  static readonly GRANT_TYPE = 'grant_type';
  static readonly PASSOWRD_GRANT_TYPE = 'password';
  static readonly REFRESH_TOKEN_GRANT_TYPE = 'refresh_token';
  static readonly CLIENT_ID = 'client_id';
  static readonly CLIENT_SECRET = 'client_secret';
  static readonly LATEST = 'latest';
  static readonly OWN = 'own';
}
