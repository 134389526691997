import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-jats-export-modal',
  templateUrl: './jats-export-modal.component.html',
  styleUrls: ['./jats-export-modal.component.scss'],
})
export class JATSExportModalComponent {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<JATSExportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string }
  ) {}

  download() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }
}
