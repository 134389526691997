import { Injectable, ComponentRef, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '@app/shared/custom-snackbar/custom-snackbar.component';
import { SNACKBAR_DATA } from '@app/shared/custom-snackbar/snackbar-tokens';
import { DispatcherMessage } from '@app/layout/widgets/arpha-navigation/notifications/models';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private overlayRefs: { ref: OverlayRef; position: number }[] = [];
  private snackbarCount: number = 0;
  private activePositions: Set<number> = new Set(); // Track active positions
  private baseOffset: number = 60; // Default snackbar height

  private defaultConfig: MatSnackBarConfig = {
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    duration: 7000,
  };

  constructor(private overlay: Overlay, private snackBar: MatSnackBar) {}

  /**
   * Display a snackbar with a custom message, duration, and optional action.
   * @param message The message to display.
   * @param config Optional configuration for the snackbar (e.g., duration, position).
   * @param actionLabel Optional action label (e.g., 'Retry', 'Dismiss').
   * @param callback Optional callback for when the action is clicked.
   */
  showMessage(
    message: string,
    type?: DispatcherMessage['type'],
    config: MatSnackBarConfig = {},
    actionLabel?: string,
    callback?: () => void
  ) {
    const panelType = type || 'info';
    config.panelClass = [`${panelType}-snackbar`];
    const mergedConfig = this.mergeConfig(config);

    // Find the first available vertical position
    const positionOffset = this.getFirstAvailablePosition(
      mergedConfig.verticalPosition
    );

    // Create overlay with calculated position
    const overlayRef = this.createOverlay(mergedConfig, positionOffset);
    this.attachSnackbarComponent(overlayRef, {
      message,
      actionLabel,
      callback: () => {
        if (callback) {
          callback();
        }
        this.dismissSnackbar(overlayRef); // Ensure dismiss after callback
      },
    });

    // Auto-dismiss after the specified duration
    if (mergedConfig.duration) {
      setTimeout(() => this.dismissSnackbar(overlayRef), mergedConfig.duration);
    }
  }

  /**
   * Show a custom component inside the snackbar using native MatSnackBar openFromComponent.
   * @param component The component to display.
   * @param config Optional configuration for the snackbar.
   */
  openFromComponent<T>(
    component: ComponentType<T>, // The custom component to display
    config: MatSnackBarConfig = {}
  ) {
    const mergedConfig = this.mergeConfig(config);

    // Use MatSnackBar's openFromComponent method
    const snackBarRef = this.snackBar.openFromComponent(
      component,
      mergedConfig
    );

    return snackBarRef;
  }

  /**
   * Show a custom component inside the snackbar using native MatSnackBar openFromComponent.
   * @param component The component to display.
   * @param config Optional configuration for the snackbar.
   */
  showComponent<T>(
    component: ComponentType<T>, // The custom component to display
    config: MatSnackBarConfig = {}
  ) {
    return this.openFromComponent(component, config);
  }

  /**
   * Dismiss a specific snackbar.
   * @param overlayRef The overlay reference to dismiss.
   */
  dismissSnackbar(overlayRef: OverlayRef) {
    const position = this.getPositionByOverlayRef(overlayRef);
    if (position !== null) {
      this.activePositions.delete(position); // Free up the position
    }

    overlayRef.dispose(); // Handle overlay disposal
    this.snackbarCount = Math.max(0, this.snackbarCount - 1); // Decrease count
    this.removeOverlay(overlayRef);
  }

  /**
   * Remove a snackbar from the tracking list when it is dismissed.
   * @param overlayRef The overlay reference to remove.
   */
  private removeOverlay(overlayRef: OverlayRef) {
    const index = this.overlayRefs.findIndex(
      (entry) => entry.ref === overlayRef
    );
    if (index !== -1) {
      this.overlayRefs.splice(index, 1); // Remove from tracking list
    }
  }

  /**
   * Dismiss all open snackbars.
   */
  dismissAll() {
    this.overlayRefs.forEach((entry) => entry.ref.dispose());
    this.overlayRefs = [];
    this.snackbarCount = 0;
    this.activePositions.clear();
  }

  /**
   * Display a success snackbar.
   * @param message The success message to display.
   * @param actionLabel Optional action label (e.g., 'OK').
   * @param config Optional configuration for the snackbar.
   * @param callback Optional callback for when the action is clicked.
   */
  success(
    message: string,
    actionLabel: string = 'Ok',
    config: MatSnackBarConfig = {},
    callback?: () => void
  ) {
    this.showMessage(message, 'success', config, actionLabel, callback);
  }

  /**
   * Display an error snackbar.
   * @param message The error message to display.
   * @param config Optional configuration for the snackbar.
   * @param actionLabel Optional action label (e.g., 'Retry').
   * @param callback Optional callback for when the action is clicked.
   */
  error(
    message: string,
    actionLabel: string = 'Ok',
    config: MatSnackBarConfig = {},
    callback?: () => void
  ) {
    this.showMessage(message, 'error', config, actionLabel, callback);
  }

  /**
   * Display a warning snackbar.
   * @param message The warning message to display.
   * @param config Optional configuration for the snackbar.
   * @param actionLabel Optional action label (e.g., 'Dismiss').
   * @param callback Optional callback for when the action is clicked.
   */
  warn(
    message: string,
    actionLabel: string = 'Dismiss',
    config: MatSnackBarConfig = {},
    callback?: () => void
  ) {
    this.showMessage(message, 'warning', config, actionLabel, callback);
  }

  /**
   * Display an info snackbar.
   * @param message The info message to display.
   * @param config Optional configuration for the snackbar.
   * @param actionLabel Optional action label (e.g., 'Got it').
   * @param callback Optional callback for when the action is clicked.
   */
  info(
    message: string,
    actionLabel: string = 'Close',
    config: MatSnackBarConfig = {},
    callback?: () => void
  ) {
    this.showMessage(message, 'info', config, actionLabel, callback);
  }

  /**
   * Create a new overlay with custom positioning and configuration.
   * @param config Configuration for positioning and styling the snackbar.
   * @param offset Vertical offset to calculate position.
   */
  private createOverlay(config: MatSnackBarConfig, offset: number): OverlayRef {
    let positionStrategy = this.overlay.position().global();

    // Apply horizontal positioning
    if (
      config.horizontalPosition === 'start' ||
      config.horizontalPosition === 'left'
    ) {
      positionStrategy = positionStrategy.left('0');
    } else if (
      config.horizontalPosition === 'end' ||
      config.horizontalPosition === 'right'
    ) {
      positionStrategy = positionStrategy.right('0');
    } else {
      positionStrategy = positionStrategy.centerHorizontally();
    }

    // Apply vertical positioning based on the provided offset
    if (config.verticalPosition === 'top') {
      positionStrategy = positionStrategy.top(`${offset}px`);
    } else {
      positionStrategy = positionStrategy.bottom(`${offset}px`);
    }

    const overlayConfig = new OverlayConfig({
      positionStrategy,
      panelClass: config.panelClass,
      direction: config.direction,
      disposeOnNavigation: true,
    });

    const overlayRef = this.overlay.create(overlayConfig);
    this.overlayRefs.push({ ref: overlayRef, position: offset });
    this.snackbarCount++;
    return overlayRef;
  }

  /**
   * Attach the custom snackbar component to the overlay and pass the data.
   * @param overlayRef The reference to the overlay.
   * @param data The data to pass to the snackbar component (message, actionLabel, etc.).
   */
  private attachSnackbarComponent(
    overlayRef: OverlayRef,
    data: any
  ): ComponentRef<CustomSnackbarComponent> {
    const injector = this.createInjector(data, overlayRef);
    const portal = new ComponentPortal(CustomSnackbarComponent, null, injector);
    const componentRef = overlayRef.attach(portal);

    return componentRef;
  }

  /**
   * Find the first available vertical position for the next snackbar.
   * @param verticalPosition The vertical position (top or bottom).
   * @returns The first available offset for the new snackbar.
   */
  private getFirstAvailablePosition(
    verticalPosition: 'top' | 'bottom' = 'bottom'
  ): number {
    let positionOffset = 0;

    // Check for the first available offset
    while (this.activePositions.has(positionOffset)) {
      positionOffset += this.baseOffset;
    }

    this.activePositions.add(positionOffset);
    return positionOffset;
  }

  /**
   * Retrieve the position associated with an overlayRef.
   * @param overlayRef The overlay reference.
   * @returns The position of the snackbar associated with the overlayRef.
   */
  private getPositionByOverlayRef(overlayRef: OverlayRef): number | null {
    const entry = this.overlayRefs.find((item) => item.ref === overlayRef);
    return entry ? entry.position : null;
  }

  /**
   * Create an injector to provide data to the custom snackbar component.
   * @param data The data to pass to the snackbar.
   * @param overlayRef The overlay reference to inject for dismissal control.
   */
  private createInjector(data: any, overlayRef: OverlayRef): Injector {
    return Injector.create({
      providers: [
        { provide: SNACKBAR_DATA, useValue: data },
        { provide: OverlayRef, useValue: overlayRef },
      ],
    });
  }

  /**
   * Merge user-provided config with the default config.
   * @param config The user-provided configuration.
   */
  private mergeConfig(config: MatSnackBarConfig): MatSnackBarConfig {
    return {
      ...this.defaultConfig,
      ...config,
      panelClass: [
        ...(this.defaultConfig.panelClass || []),
        ...(config.panelClass || []),
      ],
    };
  }
}
