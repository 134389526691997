import { Injectable } from '@angular/core';
import { ServiceShare } from '@app/editor/services/service-share.service';
import {
  EditorState,
  EditorStateConfig,
  Plugin,
  PluginKey,
  TextSelection,
} from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { Subject } from 'rxjs';
import { Typo } from './typo-js/typo';

export const spellcheckSubject = new Subject<string>();

const aff = 'assets/hunspell_dictionaries/bg_BG.aff';
const dic = 'assets/hunspell_dictionaries/bg_BG.dic';

function loadTypo() {
  return Promise.all([fetch(aff), fetch(dic)]);
}

@Injectable({
  providedIn: 'root',
})
export class SpellcheckService {
  spellcheckPluginKey = new PluginKey('spellcheckServicePlugin');
  spellchecPlugin: Plugin;

  typo: any;
  toggleSpellcheck = false;

  parentElement: HTMLDivElement;

  regexObj = {
    bg_BG: { regex: `[а-яА-ЯёЁѝЍ]+`, flags: 'gu' },
    en_US: { regex: `/\w+/`, flags: 'g' },
  };

  constructor(private sharedService: ServiceShare) {
    loadTypo()
      .then(([aff, dic]: any) => {
        return Promise.all([aff.arrayBuffer(), dic.arrayBuffer()]);
      })
      .then(([aff, dic]) => {
        const decoder1 = new TextDecoder('windows-1251');
        const decoder2 = new TextDecoder('windows-1251');
        this.typo = new Typo('bg_BG', decoder1.decode(aff), decoder2.decode(dic));
      });

    spellcheckSubject.subscribe(() => {
      this.toggleSpellcheck = !this.toggleSpellcheck;
      this.sharedService.ProsemirrorEditorsService.dispatchEmptyTransaction();
    });

    const self = this;

    this.spellchecPlugin = new Plugin({
      key: this.spellcheckPluginKey,
      state: {
        init(config: EditorStateConfig, state: EditorState) {
          return {
            decos: DecorationSet.empty,
            cursorDeco: null,
          };
        },
        apply(tr, prev, oldState, state) {
          if (!self.typo || !self.toggleSpellcheck) {
            return {
              decos: DecorationSet.empty,
              cursorDeco: null,
            };
          }
          let isInitial = false;
          let isOnlyTitle = false;
          let { decos, cursorDeco } = this.getState(oldState);
          decos = decos.map(tr.mapping, tr.doc);

          if (cursorDeco) {
            decos = decos.add(state.doc, [cursorDeco]);
            cursorDeco = null;
          }

          let { trFrom, trTo } = self.rangeFromTransform(tr);
          if (!trFrom || !trTo) {
            trTo = state.doc.content.size;
            //@ts-ignore
            if (state.doc.content?.content?.[1]) {
              isInitial = true;
              //@ts-ignore
              trFrom = state.doc.content.content[0].nodeSize;
            } else {
              isOnlyTitle = true;
              trFrom = 0;
            }
          }

          const $t = state.doc.resolve(trTo);
          const txtFrom = $t.start();
          const txtTo = $t.end();

          let txt = state.doc.textBetween(txtFrom, txtTo, ' ');
          const reg = new RegExp(self.regexObj['bg_BG'].regex, self.regexObj['bg_BG'].flags);
          let textNodes = self.getTextNodes(state.doc);
          textNodes.forEach(({ text, from, to }) => {
            let match = null;
            while ((match = reg.exec(text)) != null) {
              let token = match[0];
              let tokenFrom = from + match.index;
              let tokenTo = from + match.index + token.length;
              if (tokenTo < trFrom && tokenFrom > trTo) continue;
              decos = decos.remove(decos.find(txtFrom + tokenFrom, txtFrom + tokenTo));

              if (self.typo && !self.typo.check(match[0])) {
                const deco = Decoration.inline(txtFrom + tokenFrom, txtFrom + tokenTo, {
                  class: 'spell-error',
                });
                if ($t.pos == txtFrom + tokenTo) {
                  cursorDeco = deco;
                } else {
                  decos = decos.add(state.doc, [deco]);
                }
              }
              if (self.typo && !self.typo.check(match[0])) {
                const deco = Decoration.inline(txtFrom + tokenFrom, txtFrom + tokenTo, {
                  class: 'spell-error',
                });
                if ($t.pos == txtFrom + tokenTo) {
                  cursorDeco = deco;
                } else {
                  decos = decos.add(state.doc, [deco]);
                }
              }
            }
          });

          return {
            decos,
            cursorDeco,
          };
        },
      },

      props: {
        decorations(state) {
          let { decos } = this.getState(state);
          return decos;
        },
        handleClick: (view: EditorView, pos: number, event: MouseEvent) => {
          if (!self.typo || !self.toggleSpellcheck) return;

          const { from, to } = view.state.selection;
          if (from != to) {
            self.toggleModal(false);
            return;
          }
          let { decos } = this.spellchecPlugin.getState(view.state);
          let deco = decos.find(from, from)[0];
          if (!deco) {
            self.toggleModal(false);
            return;
          }

          const $f = view.state.doc.resolve(deco.from),
            $t = view.state.doc.resolve(deco.to);
          let token = $f.parent.textBetween(deco.from - $f.start(), deco.to - $f.start(), ' ');
          if (!token) {
            self.toggleModal(false);
            return;
          }

          let coords = view.coordsAtPos(from);
          const screenPos = {
            x: event.pageX,
            y: coords.bottom - 4,
          };

          function correct(word: string) {
            let tr = view.state.tr.replaceWith(deco.from, deco.to, view.state.schema.text(word));
            let $newPos = tr.doc.resolve(tr.mapping.map(deco.from + word.length));
            tr = tr.setSelection(new TextSelection($newPos, $newPos));
            view.dispatch(tr);
            view.focus();
          }

          self.getSuggestions(token).then((result: string[]) => {
            if (result.length > 0) {
              this.getModal(view.dom, token, screenPos, result, true, correct);
              self.toggleModal(true);
            } else {
              self.toggleModal(false);
            }
          });

          event.preventDefault();
          return false;
        },
      },
    });
  }

  getTextNodes(node) {
    let nodes = [];
    node.descendants((child, pos) => {
      if (child.isText) {
        nodes.push({ text: child.text, from: pos, to: pos + child.nodeSize });
      }
    });
    return nodes;
  }

  getSuggestions(word: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.typo.suggest(word));
      }, 0);
    });
  }

  rangeFromTransform(tr) {
    let trFrom, trTo;
    for (let i = 0; i < tr.steps.length; i++) {
      let step = tr.steps[i],
        map = step.getMap();
      let stepFrom = map.map(step.from || step.pos, -1);
      let stepTo = map.map(step.to || step.pos, 1);
      trFrom = trFrom ? map.map(trFrom, -1).pos.min(stepFrom) : stepFrom;
      trTo = trTo ? map.map(trTo, 1).pos.max(stepTo) : stepTo;
    }
    return {
      trFrom,
      trTo,
    };
  }

  toggleModal(shouldShow: boolean): void {
    if (this.parentElement && shouldShow) {
      this.parentElement.style.display = 'block';
    } else if (this.parentElement && !shouldShow) {
      this.parentElement.style.display = 'none';
    }
  }

  attachChildren(suggestions: string[], correctFunc: (word: string) => void) {
    const options = [];

    suggestions.forEach((word: string) => {
      const el = document.createElement('button');
      el.setAttribute(
        'style',
        `
            background-color: #eff9ef;
            border-radius: 13px;
            padding: 4px;
            padding-left: 9px;
            padding-right: 9px;
            border: 2px solid black;
            display: block;cursor: pointer;
            width: 100%;
            user-select: none;`
      );
      el.value = word;
      el.textContent = word;
      options.push(el);
      el.addEventListener('click', (event: Event) => {
        correctFunc((event.target as HTMLButtonElement).value);
        this.toggleModal(false);
      });
    });

    return options;
  }

  getModal(
    viewDom: any,
    token: string,
    screenPos: any,
    items: string[],
    hourglass: boolean,
    correctFunc: (word: string) => void
  ): void {
    if (this.parentElement) {
      Array.from(this.parentElement.children).forEach((ch) => ch.remove());

      let viewrect = viewDom.getBoundingClientRect();
      let widgetRect = this.parentElement.getBoundingClientRect();
      if (
        screenPos.x + widgetRect.width > viewrect.right &&
        viewrect.right - widgetRect.width > viewrect.left
      ) {
        screenPos.x = viewrect.right - widgetRect.width - 2;
      }
      if (
        screenPos.y + widgetRect.height > viewrect.bottom &&
        viewrect.bottom - this.parentElement.offsetHeight > viewrect.top
      ) {
        screenPos.y = viewrect.bottom - this.parentElement.offsetHeight - 8;
      }

      this.parentElement.style.left = screenPos.x + 'px';
      this.parentElement.style.top = screenPos.y + 'px';
      const options = this.attachChildren(items, correctFunc);

      let arrow = document.createElement('div');
      arrow.setAttribute('class', 'arrow');

      let changePlaceholder = document.createElement('div');
      changePlaceholder.style.position = 'absolute';

      changePlaceholder.setAttribute(
        'style',
        `
        position: absolute;
        display: inline;
        transform: translate(-20px, 30px);
        background-color: green;
        border-radius: 2px;
        width: 150px;
        z-index: 10;
        padding: 6px;`
      );

      arrow.setAttribute(
        'style',
        `
        position: absolute;
        border-bottom: 10px solid green;
        border-left: 6px solid rgba(0, 0, 0, 0);
        border-right: 6px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        height: 0;
        vertical-align: top;
        width: 0;
        top: 0;
        transform: translate(8px, -10px);
        `
      );

      changePlaceholder.append(...options, arrow);
      this.parentElement.append(changePlaceholder);
    } else {
      const relativeElement = document.createElement('div');
      relativeElement.style.zIndex = '100000';
      relativeElement.setAttribute(
        'style',
        'position: absolute;display: inline;line-height: 21px;font-size: 14px; cursor: default;'
      );
      relativeElement.setAttribute('class', 'citat-menu-context');

      let changePlaceholder = document.createElement('div');
      changePlaceholder.style.position = 'absolute';

      let btn = document.createElement('button');
      btn.setAttribute(
        'style',
        `
    background-color: #eff9ef;
    border-radius: 13px;
    padding: 4px;
    padding-left: 9px;
    padding-right: 9px;
    border: 2px solid black;
    display: block;cursor: pointer;
    width: 100%;
    user-select: none;`
      );

      const options = this.attachChildren(items, correctFunc);

      let arrow = document.createElement('div');
      arrow.setAttribute('class', 'citat-menu-context');

      changePlaceholder.append(...options, arrow);
      relativeElement.append(changePlaceholder);
      this.parentElement = relativeElement;
      document.body.append(relativeElement);
    }
  }
}
