export enum ArticleRole {
  readOnly = 'Read only',
  suggester = 'Can suggest',
  canCommentOnly = 'Can comment only',
  editor = 'Can edit',
}

export interface ArticleRoleDetails {
  short: string;
  details: string;
}

export type ArticleRoleAbbreviationMapping = Record<ArticleRole, ArticleRoleDetails>;
