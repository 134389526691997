/**
 * @module bindings/prosemirror
 */

import { createMutex } from 'lib0/mutex.js';
import * as PModel from 'prosemirror-model';
import { Plugin, TextSelection } from 'prosemirror-state'; // eslint-disable-line
import * as math from 'lib0/math.js';
import * as object from 'lib0/object.js';
import * as set from 'lib0/set.js';
import { simpleDiff } from 'lib0/diff.js';
import * as error from 'lib0/error.js';
import { ySyncPluginKey, ySyncPluginKeyObj } from './keys.js';
//@ts-ignore
import * as Y from 'yjs';
import { absolutePositionToRelativePosition, relativePositionToAbsolutePosition } from '../lib.js';
import * as random from 'lib0/random.js';
import * as environment from 'lib0/environment.js';
import * as dom from 'lib0/dom.js';
import * as eventloop from 'lib0/eventloop';
import { BehaviorSubject, Subject } from 'rxjs';

export const changeVersionSubject = new BehaviorSubject('');

let _pluginState = {};

/**
 * @param {Y.Item} item
 * @param {Y.Snapshot} [snapshot]
 */
export const isVisible = (item, snapshot) => {
  if (snapshot === undefined) {
    return !item.deleted;
  } else {
    const a = snapshot.sv.has(item.id.client);
    const b = snapshot.sv.get(item.id.client) > item.id.clock;
    const c = !Y.isDeleted(snapshot.ds, item.id);
    return a && b && c;
  }
};

/**
 * Either a node if type is YXmlElement or an Array of text nodes if YXmlText
 * @typedef {Map<Y.AbstractType, PModel.Node | Array<PModel.Node>>} ProsemirrorMapping
 */

/**
 * @typedef {Object} ColorDef
 * @property {string} ColorDef.light
 * @property {string} ColorDef.dark
 */

/**
 * @typedef {Object} YSyncOpts
 * @property {Array<ColorDef>} [YSyncOpts.colors]
 * @property {Map<string,ColorDef>} [YSyncOpts.colorMapping]
 * @property {Y.PermanentUserData|null} [YSyncOpts.permanentUserData]
 */

/**
 * @type {Array<ColorDef>}
 */
export const defaultColors = [{ light: '#ecd44433', dark: '#ecd444' }];

export const hashCode = (email) => {
  let hash = 0;
  for (let i = 0; i < email.length; i++) {
    hash = email.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};
export const intToRGB = (i) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
};
export const isColorDark = (color) => {
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  const brightness = Math.sqrt(r * r * 0.299 + g * g * 0.587 + b * b * 0.114);

  return brightness < 128;
};

/**
 * @param {Map<string,ColorDef>} colorMapping
 * @param {Array<ColorDef>} colors
 * @param {string} user
 * @return {ColorDef}
 */
const getUserColor = (colorMapping, colors, user) => {
  // @todo do not hit the same color twice if possible
  if (!colorMapping.has(user)) {
    if (!user) {
      return {
        userColor: '#' + intToRGB(hashCode('admin@pensoft.net')),
        userContrastColor: '#000000',
      };
    }
    const email = user.split('&');
    const userColorHex = intToRGB(hashCode(email[email.length - 1]));
    const userColor = '#' + userColorHex;
    let userContrastColor = '#000000';

    if (isColorDark(userColorHex)) {
      userContrastColor = '#ffffff';
    }

    const userColors = {
      userColor,
      userContrastColor,
    };

    return userColors;
  }
  return /** @type {ColorDef} */ (colorMapping.get(user));
};
let sync = true;
let trackStatus = undefined;
let userInfo = undefined;
/**
 * This plugin listens to changes in prosemirror view and keeps yXmlState and view in sync.
 *
 * This plugin also keeps references to the type and the shared document so other plugins can access it.
 * @param {Y.XmlFragment} yXmlFragment
 * @param {YSyncOpts} opts
 * @return {any} Returns a prosemirror plugin that binds to this type
 */
export const ySyncPlugin = (
  yXmlFragment,
  {
    colors = defaultColors,
    colorMapping = new Map(),
    permanentUserData = null,
    prevSnapshot = null,
    snapshot = null,
    removedSection = null,
  } = {}
) => {
  changeVersionSubject.subscribe((event) => {
    if (event == 'returnToNewest') {
      sync = false;
    } else if (event == 'sync') {
      sync = true;
    }
  });
  let changedInitialContent = false;
  let rerenderTimeout = null;
  const plugin = new Plugin({
    props: {
      // editable: (state) => {
      //   const syncState = ySyncPluginKeyObj.ySyncPluginKey.getState(state)
      //   return syncState.snapshot == null && syncState.prevSnapshot == null
      // }
    },
    key: ySyncPluginKeyObj.ySyncPluginKey,
    state: {
      init: (initargs, state) => {
        return {
          type: yXmlFragment,
          doc: yXmlFragment.doc,
          binding: null,
          snapshot,
          prevSnapshot,
          isChangeOrigin: false,
          colors,
          colorMapping,
          permanentUserData,
          removedSection,
        };
      },
      apply: (tr, pluginState) => {
        let canSync = !window.location.href.includes('#');
        const change = tr.getMeta(ySyncPluginKeyObj.ySyncPluginKey);
        if (change !== undefined) {
          pluginState = Object.assign({}, pluginState);
          for (const key in change) {
            pluginState[key] = change[key];
          }
        }
        _pluginState = pluginState;
        // always set isChangeOrigin. If undefined, this is not change origin.
        pluginState.isChangeOrigin = change !== undefined && !!change.isChangeOrigin;
        if (pluginState.binding !== null) {
          if (change !== undefined && (change.snapshot != null || change.prevSnapshot != null)) {
            // snapshot changed, rerender next
            if (pluginState.trackStatus !== undefined || pluginState.userInfo !== undefined) {
              trackStatus = pluginState.trackStatus;
              userInfo = pluginState.userInfo;
            }
            eventloop.timeout(0, () => {
              if (pluginState.binding == null || pluginState.binding.isDestroyed) {
                return;
              }
              pluginState.binding._renderSnapshot(
                change.snapshot,
                change.prevSnapshot,
                pluginState
              );
              // reset to current prosemirror state
              delete pluginState.snapshot;
              delete pluginState.prevSnapshot;
              _pluginState = pluginState;
              if ((canSync && sync) || pluginState.restore) {
                pluginState.binding.mux(() => {
                  pluginState.binding._prosemirrorChanged(
                    pluginState.binding.prosemirrorView.state.doc
                  );
                });
              }
            });
            // setTimeout(() => {
            //   pluginState.binding._renderSnapshot(change.snapshot, change.prevSnapshot, pluginState)
            //   if(canSync) {
            //     pluginState.binding._prosemirrorChanged(pluginState.binding.prosemirrorView.state.doc);
            //     delete pluginState.restore
            //     delete pluginState.snapshot
            //     delete pluginState.prevSnapshot
            //   }
            // }, 0)
          }
        }
        return pluginState;
      },
    },
    view: (view) => {
      const binding = new ProsemirrorBinding(yXmlFragment, view);
      if (rerenderTimeout != null) {
        rerenderTimeout.destroy();
      }

      const pluginState = plugin.getState(view.state);
      _pluginState = pluginState;
      // Make sure this is called in a separate context
      rerenderTimeout = eventloop.timeout(0, () => {
        binding._forceRerender();
        view.dispatch(
          view.state.tr.setMeta(ySyncPluginKeyObj.ySyncPluginKey, { ...pluginState, binding })
        );
        // onFirstRender()
      });
      return {
        update: (view, prevState) => {
          let canSync = !window.location.href.includes('#');
          const pluginState = plugin.getState(view.state);
          if (
            pluginState.snapshot == null &&
            pluginState.prevSnapshot == null &&
            ((canSync && sync) || pluginState.restore)
          ) {
            if (
              changedInitialContent ||
              view.state.doc.content.findDiffStart(view.state.doc.type.createAndFill().content) !==
                null
            ) {
              changedInitialContent = true;
              binding.mux(() => {
                /** @type {Y.Doc} */ (pluginState.doc).transact((tr) => {
                  binding._prosemirrorChanged(view.state.doc);
                }, ySyncPluginKey);
              });
            }
          }
          delete pluginState.returnToNewest;
        },
        destroy: () => {
          rerenderTimeout?.destroy();
          binding.destroy();
        },
      };
    },
  });
  return plugin;
};

/**
 * @param {any} tr
 * @param {any} relSel
 * @param {ProsemirrorBinding} binding
 */
export const restoreRelativeSelection = (tr, relSel, binding) => {
  if (relSel !== null && relSel.anchor !== null && relSel.head !== null) {
    const anchor = relativePositionToAbsolutePosition(
      binding.doc,
      binding.type,
      relSel.anchor,
      binding.mapping
    );
    const head = relativePositionToAbsolutePosition(
      binding.doc,
      binding.type,
      relSel.head,
      binding.mapping
    );
    if (anchor !== null && head !== null) {
      tr = tr.setSelection(TextSelection.create(tr.doc, anchor, head));
    }
  }
};

export const getRelativeSelection = (pmbinding, state) => {
  return {
    anchor: absolutePositionToRelativePosition(
      state.selection.anchor,
      pmbinding.type,
      pmbinding.mapping
    ),
    head: absolutePositionToRelativePosition(
      state.selection.head,
      pmbinding.type,
      pmbinding.mapping
    ),
  };
};

export const getRelativeSelectionV2 = (pmbinding, anchor, head) => {
  return {
    anchor: absolutePositionToRelativePosition(anchor, pmbinding.type, pmbinding.mapping),
    head: absolutePositionToRelativePosition(head, pmbinding.type, pmbinding.mapping),
  };
};

/**
 * Binding for prosemirror.
 *
 * @protected
 */
export class ProsemirrorBinding {
  /**
   * @param {Y.XmlFragment} yXmlFragment The bind source
   * @param {any} prosemirrorView The target binding
   */
  constructor(yXmlFragment, prosemirrorView) {
    this.type = yXmlFragment;
    this.prosemirrorView = prosemirrorView;
    this.mux = createMutex();
    this.isDestroyed = false;
    /**
     * @type {ProsemirrorMapping}
     */
    this.mapping = new Map();
    this._observeFunction = this._typeChanged.bind(this);
    /**
     * @type {Y.Doc}
     */
    // @ts-ignore
    this.doc = yXmlFragment.doc;
    /**
     * current selection as relative positions in the Yjs model
     */
    this.beforeTransactionSelection = null;
    this.beforeAllTransactions = () => {
      if (this.beforeTransactionSelection === null) {
        this.beforeTransactionSelection = getRelativeSelection(this, prosemirrorView.state);
      }
    };
    this.afterAllTransactions = () => {
      this.beforeTransactionSelection = null;
    };

    this.doc.on('beforeAllTransactions', this.beforeAllTransactions);
    this.doc.on('afterAllTransactions', this.afterAllTransactions);
    yXmlFragment.observeDeep(this._observeFunction);

    this._domSelectionInView = null;
  }

  _isLocalCursorInView() {
    if (!this.prosemirrorView.hasFocus()) return false;
    if (environment.isBrowser && this._domSelectionInView === null) {
      // Calculate the domSelectionInView and clear by next tick after all events are finished
      eventloop.timeout(0, () => {
        this._domSelectionInView = null;
      });
      this._domSelectionInView = this._isDomSelectionInView();
    }
    return this._domSelectionInView;
  }

  _isDomSelectionInView() {
    const selection = this.prosemirrorView._root.getSelection();

    const range = this.prosemirrorView._root.createRange();
    range.setStart(selection.anchorNode, selection.anchorOffset);
    range.setEnd(selection.focusNode, selection.focusOffset);

    // This is a workaround for an edgecase where getBoundingClientRect will
    // return zero values if the selection is collapsed at the start of a newline
    // see reference here: https://stackoverflow.com/a/59780954
    const rects = range.getClientRects();
    if (rects.length === 0) {
      // probably buggy newline behavior, explicitly select the node contents
      if (range.startContainer && range.collapsed) {
        range.selectNodeContents(range.startContainer);
      }
    }

    const bounding = range.getBoundingClientRect();
    const documentElement = dom.doc.documentElement;

    return (
      bounding.bottom >= 0 &&
      bounding.right >= 0 &&
      bounding.left <= (window.innerWidth || documentElement.clientWidth || 0) &&
      bounding.top <= (window.innerHeight || documentElement.clientHeight || 0)
    );
  }

  /**
   * @param {Y.Snapshot} snapshot
   * @param {Y.Snapshot} prevSnapshot
   */
  renderSnapshot(snapshot, prevSnapshot) {
    if (!prevSnapshot) {
      prevSnapshot = Y.createSnapshot(Y.createDeleteSet(), new Map());
    }
    this.prosemirrorView.dispatch(
      this.prosemirrorView.state.tr.setMeta(ySyncPluginKeyObj.ySyncPluginKey, {
        snapshot,
        prevSnapshot,
      })
    );
  }

  unrenderSnapshot() {
    this.mapping = new Map();
    this.mux(() => {
      const fragmentContent = this.type
        .toArray()
        .map((t) =>
          createNodeFromYElement(
            /** @type {Y.XmlElement} */ (t),
            this.prosemirrorView.state.schema,
            this.mapping
          )
        )
        .filter((n) => n !== null);
      const tr = this.prosemirrorView.state.tr.replace(
        0,
        this.prosemirrorView.state.doc.content.size,
        new PModel.Slice(new PModel.Fragment(fragmentContent), 0, 0)
      );
      tr.setMeta(ySyncPluginKeyObj.ySyncPluginKey, { snapshot: null, prevSnapshot: null });
      this.prosemirrorView.dispatch(tr);
    });
  }

  _forceRerender() {
    this.mapping = new Map();
    this.mux(() => {
      const fragmentContent = this.type
        .toArray()
        .map((t) =>
          createNodeFromYElement(
            /** @type {Y.XmlElement} */ (t),
            this.prosemirrorView.state.schema,
            this.mapping
          )
        )
        .filter((n) => n !== null);
      // @ts-ignore
      const tr = this.prosemirrorView.state.tr.replace(
        0,
        this.prosemirrorView.state.doc.content.size,
        new PModel.Slice(new PModel.Fragment(fragmentContent), 0, 0)
      );
      this.prosemirrorView.dispatch(tr);
    });
  }

  /**
   * @param {Y.Snapshot} snapshot
   * @param {Y.Snapshot} prevSnapshot
   * @param {Object} pluginState
   */
  _renderSnapshot(snapshot, prevSnapshot, pluginState) {
    if (!snapshot) {
      snapshot = Y.snapshot(this.doc);
    }
    // clear mapping because we are going to rerender
    this.mapping = new Map();
    this.mux(() => {
      this.doc.transact((transaction) => {
        // before rendering, we are going to sanitize ops and split deleted ops
        // if they were deleted by seperate users.
        const pud = _pluginState.permanentUserData;
        if (pud) {
          pud.dss.forEach((ds) => {
            try {
              Y.iterateDeletedStructs(transaction, ds, (_item) => {});
            } catch (e) {
              console.log(e);
            }
          });
        }
        /**
         * @param {'removed'|'added'} type
         * @param {Y.ID} id
         */
        const computeYChange = (type, id) => {
          if (pud) {
            const user =
              type === 'added' ? pud.getUserByClientId(id.client) : pud.getUserByDeletedId(id);
            return {
              user: user?.split('&')?.[0] || user,
              type,
              color: getUserColor(pluginState.colorMapping, pluginState.colors, user),
            };
          } else {
            return null;
          }
        };
        // Create document fragment and render
        const arr = Y.typeListToArraySnapshot(
          this.type,
          new Y.Snapshot(prevSnapshot.ds, snapshot.sv)
        );
        const fragmentContent = arr
          .map((t) => {
            if (
              !t._item.deleted ||
              isVisible(t._item, snapshot) ||
              isVisible(t._item, prevSnapshot)
            ) {
              const nodeFromY = createNodeFromYElement(
                t,
                this.prosemirrorView.state.schema,
                new Map(),
                snapshot,
                prevSnapshot,
                computeYChange
              );
              return nodeFromY;
            } else {
              // No need to render elements that are not visible by either snapshot.
              // If a client adds and deletes content in the same snapshot the element is not visible by either snapshot.
              return null;
            }
          })
          .filter((n) => n !== null);
        const tr = this.prosemirrorView.state.tr.replace(
          0,
          this.prosemirrorView.state.doc.content.size,
          new PModel.Slice(new PModel.Fragment(fragmentContent), 0, 0)
        );
        this.prosemirrorView.dispatch(tr);
      }, ySyncPluginKey);
    });
  }

  /**
   * @param {Array<Y.YEvent>} events
   * @param {Y.Transaction} transaction
   */
  _typeChanged(events, transaction) {
    const syncState = ySyncPluginKeyObj.ySyncPluginKey.getState(this.prosemirrorView.state);
    if (events.length === 0 || syncState.snapshot != null || syncState.prevSnapshot != null) {
      // drop out if snapshot is active
      this.renderSnapshot(syncState.snapshot, syncState.prevSnapshot);
      return;
    }
    this.mux(() => {
      /**
       * @param {any} _
       * @param {Y.AbstractType} type
       */
      const delType = (_, type) => this.mapping.delete(type);
      Y.iterateDeletedStructs(transaction, transaction.deleteSet, (struct) => {
        if (struct.constructor === Y.Item) {
          const type = /** @type {Y.ContentType} */ (/** @type {Y.Item} */ (struct).content).type;
          type && this.mapping.delete(type);
        }
      });
      // struct => struct.constructor === Y.Item && this.mapping.delete( /** @type {Y.ContentType} */ ( /** @type {Y.Item} */ (struct).content).type))
      transaction.changed.forEach(delType);
      transaction.changedParentTypes.forEach(delType);
      const fragmentContent = this.type
        .toArray()
        .map((t) =>
          createNodeIfNotExists(
            /** @type {Y.XmlElement | Y.XmlHook} */ (t),
            this.prosemirrorView.state.schema,
            this.mapping
          )
        )
        .filter((n) => n !== null);
      // @ts-ignore
      let tr = this.prosemirrorView.state.tr.replace(
        0,
        this.prosemirrorView.state.doc.content.size,
        new PModel.Slice(new PModel.Fragment(fragmentContent), 0, 0)
      );
      restoreRelativeSelection(tr, this.beforeTransactionSelection, this);
      tr = tr.setMeta(ySyncPluginKeyObj.ySyncPluginKey, { isChangeOrigin: true });
      if (
        (this.beforeTransactionSelectionFromUndoRedoItem !== null ||
          this.beforeTransactionSelection !== null) &&
        this._isLocalCursorInView()
      ) {
        this.prosemirrorView.focus();
        tr.scrollIntoView();
      }

      this.prosemirrorView.dispatch(tr);
    });
  }

  _prosemirrorChanged(doc) {
    this.doc.transact(() => {
      updateYFragment(this.doc, this.type, doc, this.mapping);
      this.beforeTransactionSelection = getRelativeSelection(this, this.prosemirrorView.state);
    }, ySyncPluginKey);
  }

  destroy() {
    this.isDestroyed = true;
    this.type.unobserveDeep(this._observeFunction);
    this.doc.off('beforeAllTransactions', this.beforeAllTransactions);
    this.doc.off('afterAllTransactions', this.afterAllTransactions);
  }
}

/**
 * @private
 * @param {Y.XmlElement | Y.XmlHook} el
 * @param {PModel.Schema} schema
 * @param {ProsemirrorMapping} mapping
 * @param {Y.Snapshot} [snapshot]
 * @param {Y.Snapshot} [prevSnapshot]
 * @param {function('removed' | 'added', Y.ID):any} [computeYChange]
 * @param {boolean | undefined} noChange
 * @return {PModel.Node | null}
 */
const createNodeIfNotExists = (
  el,
  schema,
  mapping,
  snapshot,
  prevSnapshot,
  computeYChange,
  noChange
) => {
  const node = /** @type {PModel.Node} */ (mapping.get(el));
  if (node === undefined) {
    if (el instanceof Y.XmlElement) {
      return createNodeFromYElement(
        el,
        schema,
        mapping,
        snapshot,
        prevSnapshot,
        computeYChange,
        noChange
      );
    } else {
      throw error.methodUnimplemented(); // we are currently not handling hooks
    }
  }
  return node;
};

/**
 * @private
 * @param {Y.XmlElement} el
 * @param {any} schema
 * @param {ProsemirrorMapping} mapping
 * @param {Y.Snapshot} [snapshot]
 * @param {Y.Snapshot} [prevSnapshot]
 * @param {function('removed' | 'added', Y.ID):any} [computeYChange]
 * @param {PModel.Node} node
 * @param {boolean | undefined} noChange
 * @return {PModel.Node | null} Returns node if node could be created. Otherwise it deletes the yjs type and returns null
 */
const createNodeFromYElement = (
  el,
  schema,
  mapping,
  snapshot,
  prevSnapshot,
  computeYChange,
  noChange
) => {
  const children = [];
  const createChildren = (type, noChange) => {
    if (type.constructor === Y.XmlElement) {
      const n = createNodeIfNotExists(
        type,
        schema,
        mapping,
        snapshot,
        prevSnapshot,
        computeYChange,
        noChange
      );
      if (n !== null) {
        children.push(n);
      }
    } else {
      const ns = createTextNodesFromYText(
        type,
        schema,
        mapping,
        snapshot,
        prevSnapshot,
        computeYChange,
        noChange
      );
      if (ns !== null) {
        ns.forEach((textchild) => {
          if (textchild !== null) {
            children.push(textchild);
          }
        });
      }
    }
  };
  if (snapshot === undefined || prevSnapshot === undefined) {
    el.toArray().forEach(createChildren);
  } else {
    if (
      el.nodeName == 'block_figure' ||
      el.nodeName == 'block_table' ||
      el.nodeName == 'tables_nodes_container' ||
      el.nodeName == 'figures_nodes_container'
    ) {
      Y.typeListToArraySnapshot(el, new Y.Snapshot(prevSnapshot.ds, snapshot.sv)).forEach((type) =>
        createChildren(type, true)
      );
    } else {
      Y.typeListToArraySnapshot(el, new Y.Snapshot(prevSnapshot.ds, snapshot.sv)).forEach((type) =>
        createChildren(type, noChange)
      );
    }
  }
  try {
    const attrs = el.getAttributes(snapshot);
    if (snapshot !== undefined) {
      if (!isVisible(/** @type {Y.Item} */ (el._item), snapshot)) {
        attrs.ychange = computeYChange
          ? computeYChange('removed', /** @type {Y.Item} */ (el._item).id)
          : { type: 'removed' };
      } else if (!isVisible(/** @type {Y.Item} */ (el._item), prevSnapshot)) {
        attrs.ychange = computeYChange
          ? computeYChange('added', /** @type {Y.Item} */ (el._item).id)
          : { type: 'added' };
      }
    }

    if ((el.nodeName == 'math_display' || el.nodeName == 'math_inline') && attrs.ychange) {
      children.forEach((ch) => {
        ch.marks = [];
      });

      attrs['style'] =
        attrs.ychange.type == 'added'
          ? `background-color: ${attrs.ychange.color.userColor}; color: ${attrs.ychange.color.userContrastColor}`
          : `background-color: #fee7e7;`;
    }

    if (el.nodeName == 'form_field' && snapshot) {
      if (children.length == 0) {
        children.push(schema.node('paragraph', {}));
      } else {
        const children2 = [];
        children.forEach((ch) => {
          if (ch.type.name == 'ordered_list' || ch.type.name == 'bullet_list') {
            ch.content?.content?.forEach((listItem) => {
              if (
                listItem.content.content?.[0]?.type?.name == 'paragraph' &&
                listItem.content.content?.[0].childCount > 0
              ) {
                children2.push(listItem);
              } else {
              }
            });
            ch.content.content = children2;
          }
        });
      }
    }
    /**
     * @type {PModel.Node}
     */
    const hasAttrs = Object.keys(attrs).filter((key) => key != 'ychange').length > 0;
    // console.log(hasAttrs);
    if (el.nodeName == 'block_end_note' && !hasAttrs) {
      attrs['end_note_number'] = el._map.get('end_note_number')?.content?.arr[0];
      attrs['end_note_id'] = el._map.get('end_note_id')?.content?.arr[0];
    } else if (el.nodeName == 'block_supplementary_file' && !hasAttrs) {
      attrs['supplementary_file_number'] = el._map.get(
        'supplementary_file_number'
      )?.content?.arr[0];
      attrs['supplementary_file_id'] = el._map.get('supplementary_file_id')?.content?.arr[0];
      if (children[0]?.type?.name == 'supplementary_file_title') {
        children[0].content.content = children[0].content.content.map((node) => {
          node.attrs.tagName = 'H3';
          node.attrs.styling = 'display: inline;';
          return node;
        });
      }
    }
    // else if (el.nodeName == "figure_component" && !hasAttrs) {
    //   attrs["component_number"] = el._map.get("component_number")?.content?.arr[0];
    //   attrs["viewed_by_citat"] = el._map.get("viewed_by_citat")?.content?.arr[0];
    //   attrs["contenteditableNode"] = "false";
    // } else if (el.nodeName == "block_figure" && !hasAttrs) {
    //   attrs["figure_number"] = el._map.get("figure_number")?.content?.arr[0];
    //   attrs["figure_id"] = el._map.get("figure_id")?.content?.arr[0];
    //   attrs["figure columns"] = el._map.get("figure columns")?.content?.arr[0];
    //   attrs["viewed_by_citat"] = el._map.get("viewed_by_citat")?.content?.arr[0];
    // } else if (el.nodeName == "block_table" && !hasAttrs) {
    //   attrs["table_number"] = el._map.get("table_number")?.content?.arr[0];
    //   attrs["viewed_by_citat"] = el._map.get("viewed_by_citat")?.content?.arr[0];
    //   attrs["table_id"] = el._map.get("table_id")?.content?.arr[0];
    // }
    //  else if (el.nodeName == "figure_component_description" && !hasAttrs) {
    //   attrs["component_number"] = el._map.get("component_number")?.content?.arr[0];
    //   attrs["viewed_by_citat"] = el._map.get("viewed_by_citat")?.content?.arr[0];
    //   attrs["styling"] = el._map.get("styling")?.content?.arr[0];
    // } else if (el.nodeName == "image" && !hasAttrs) {
    //   attrs["src"] = el._map.get("src")?.content?.arr[0];
    //   attrs["alt"] = el._map.get("alt")?.content?.arr[0];
    //   attrs["title"] = el._map.get("title")?.content?.arr[0];
    //   attrs["width"] = el._map.get("width")?.content?.arr[0];
    //   attrs["numberOfColumns"] = el._map.get("numberOfColumns")?.content?.arr[0];
    //   attrs["isFromCDN"] = el._map.get("isFromCDN")?.content?.arr[0];
    // }
    // console.log(el.nodeName, el, attrs, children);
    /**
     * @type {PModel.Node}
     */
    if (
      el.nodeName == 'paragraph' &&
      attrs.ychange?.type == 'added' &&
      !el.firstChild &&
      !noChange
    ) {
      attrs.change = `border-left: 6px solid ${attrs.ychange.color.userColor};`;
    } else if (
      el.nodeName == 'paragraph' &&
      attrs.ychange?.type == 'removed' &&
      !children.length &&
      !noChange
    ) {
      attrs.change = `border-left: 6px solid #fee7e7;`;
    }
    const node = schema.node(el.nodeName, attrs, children);

    if (node.type.name == 'list_item' && node.textContent == '') {
      return null;
    }
    if (node.type.name == 'reference_block_container' && node.content.content[0].childCount == 0)
      return null;
    mapping.set(el, node);
    return node;
  } catch (e) {
    // console.error(e);
    // an error occured while creating the node. This is probably a result of a concurrent action.
    /** @type {Y.Doc} */
    (el.doc).transact((transaction) => {
      /** @type {Y.Item} */
      (el._item).delete(transaction);
    }, ySyncPluginKey);
    mapping.delete(el);
    return null;
  }
};

/**
 * @private
 * @param {Y.XmlText} text
 * @param {any} schema
 * @param {ProsemirrorMapping} _mapping
 * @param {Y.Snapshot} [snapshot]
 * @param {Y.Snapshot} [prevSnapshot]
 * @param {function('removed' | 'added', Y.ID):any} [computeYChange]
 * @param {boolean | undefined} noChange
 * @return {Array<PModel.Node>|null}
 */
const createTextNodesFromYText = (
  text,
  schema,
  _mapping,
  snapshot,
  prevSnapshot,
  computeYChange,
  noChange
) => {
  const nodes = [];
  try {
    const deltas = text.toDelta(snapshot, prevSnapshot, computeYChange);
    if (snapshot && prevSnapshot && !_pluginState.restore) {
      for (let i = 0; i < deltas.length; i++) {
        const delta = deltas[i];
        const marks = [];
        for (const markName in delta.attributes) {
          const attrs = Object.keys(delta.attributes);

          if (
            markName == 'ychange' &&
            snapshot &&
            _pluginState.userData &&
            !noChange &&
            !attrs.includes('insertion') &&
            !attrs.includes('deletion')
          ) {
            let mark = delta.attributes[markName].type == 'added' ? 'insertion' : 'deletion';

            if (mark == 'deletion') {
              marks.push(
                schema.marks[mark].create({
                  username: delta.attributes[markName].user,
                  userColor: delta.attributes[markName].color.userColor,
                  userContrastColor: delta.attributes[markName].color.userContrastColor,
                  date: Date.now(),
                  id: _pluginState.userData.data.id,
                })
              );
            } else {
              marks.push(
                schema.marks[mark].create({
                  username: delta.attributes[markName].user,
                  style: `color: ${delta.attributes[markName].color.userContrastColor}; background-color: ${delta.attributes[markName].color.userColor} !important;`,
                  userContrastColor: delta.attributes[markName].color.userContrastColor,
                  userColor: delta.attributes[markName].color.userColor,
                  date: Date.now(),
                  id: _pluginState.userData.data.id,
                })
              );
            }
          } else {
            if (
              delta.attributes['ychange'] &&
              delta.attributes['ychange'].type == 'removed' &&
              delta.attributes['insertion']
            ) {
              delta.insert = '';
            } else {
              if (markName !== 'ychange') {
                const attrs = delta.attributes[markName];

                const historyRemoved = attrs['historyRemoved'];
                delete attrs['historyRemoved'];

                if (markName == 'insertion' && snapshot) {
                  marks.push(
                    schema.mark(markName, {
                      ...attrs,
                      style: historyRemoved
                        ? `color: ${attrs.userContrastColor}; background-color: ${attrs.userColor} !important;`
                        : `text-decoration-line: underline; text-decoration-style: dashed; background-color: ${attrs.userColor} !important; `,
                    })
                  );
                } else if (markName == 'deletion' && snapshot) {
                  marks.push(
                    schema.mark(markName, {
                      ...attrs,
                      style: `text-decoration: line-through; text-decoration-style: dashed !important;`,
                    })
                  );
                } else {
                  marks.push(schema.mark(markName, attrs));
                }
              }
            }
          }
        }

        if (delta.insert !== '') {
          nodes.push(schema.text(delta.insert, marks));
        }
      }
    } else {
      for (let i = 0; i < deltas.length; i++) {
        const delta = deltas[i];
        const marks = [];
        if (delta.attributes) {
          for (const markName in delta.attributes) {
            if (markName != 'ychange') {
              marks.push(schema.mark(markName, delta.attributes[markName]));
            }
          }
        }
        if (delta.insert !== '') {
          nodes.push(schema.text(delta.insert, marks));
        }
      }
    }
  } catch (e) {
    // an error occured while creating the node. This is probably a result of a concurrent action.
    /** @type {Y.Doc} */
    (text.doc).transact((transaction) => {
      /** @type {Y.Item} */
      (text._item).delete(transaction);
    }, ySyncPluginKey);
    console.error(e);
    return null;
  }

  return nodes;
};

/**
 * @private
 * @param {Array<any>} nodes prosemirror node
 * @param {ProsemirrorMapping} mapping
 * @return {Y.XmlText}
 */
const createTypeFromTextNodes = (nodes, mapping) => {
  const type = new Y.XmlText();
  const delta = nodes.map((node) => ({
    // @ts-ignore
    insert: node.text,
    attributes: marksToAttributes(node.marks),
  }));
  type.applyDelta(delta);
  mapping.set(type, nodes);
  return type;
};

/**
 * @private
 * @param {any} node prosemirror node
 * @param {ProsemirrorMapping} mapping
 * @return {Y.XmlElement}
 */
const createTypeFromElementNode = (node, mapping) => {
  const type = new Y.XmlElement(node.type.name);
  for (const key in node.attrs) {
    const val = node.attrs[key];
    if (val !== null && key !== 'ychange') {
      type.setAttribute(key, val);
    }
  }
  type.insert(
    0,
    normalizePNodeContent(node).map((n) => createTypeFromTextOrElementNode(n, mapping))
  );
  mapping.set(type, node);
  return type;
};

/**
 * @private
 * @param {PModel.Node|Array<PModel.Node>} node prosemirror text node
 * @param {ProsemirrorMapping} mapping
 * @return {Y.XmlElement|Y.XmlText}
 */
const createTypeFromTextOrElementNode = (node, mapping) =>
  node instanceof Array
    ? createTypeFromTextNodes(node, mapping)
    : createTypeFromElementNode(node, mapping);

const isObject = (val) => typeof val === 'object' && val !== null;

const equalAttrs = (pattrs, yattrs) => {
  const keys = Object.keys(pattrs).filter((key) => pattrs[key] !== null);
  let eq = keys.length === Object.keys(yattrs).filter((key) => yattrs[key] !== null).length;
  for (let i = 0; i < keys.length && eq; i++) {
    const key = keys[i];
    const l = pattrs[key];
    const r = yattrs[key];
    eq = key === 'ychange' || l === r || (isObject(l) && isObject(r) && equalAttrs(l, r));
  }
  return eq;
};

/**
 * @typedef {Array<Array<PModel.Node>|PModel.Node>} NormalizedPNodeContent
 */

/**
 * @param {any} pnode
 * @return {NormalizedPNodeContent}
 */
const normalizePNodeContent = (pnode) => {
  const c = pnode.content.content;
  const res = [];
  for (let i = 0; i < c.length; i++) {
    const n = c[i];
    if (n.isText) {
      const textNodes = [];
      for (let tnode = c[i]; i < c.length && tnode.isText; tnode = c[++i]) {
        textNodes.push(tnode);
      }
      i--;
      res.push(textNodes);
    } else {
      res.push(n);
    }
  }
  return res;
};

/**
 * @param {Y.XmlText} ytext
 * @param {Array<any>} ptexts
 */
const equalYTextPText = (ytext, ptexts) => {
  const delta = ytext.toDelta();
  return (
    delta.length === ptexts.length &&
    delta.every(
      (d, i) =>
        d.insert === /** @type {any} */ (ptexts[i]).text &&
        object.keys(d.attributes || {}).length === ptexts[i].marks.length &&
        ptexts[i].marks.every((mark) => equalAttrs(d.attributes[mark.type.name] || {}, mark.attrs))
    )
  );
};

/**
 * @param {Y.XmlElement|Y.XmlText|Y.XmlHook} ytype
 * @param {any|Array<any>} pnode
 */
const equalYTypePNode = (ytype, pnode) => {
  if (ytype instanceof Y.XmlElement && !(pnode instanceof Array) && matchNodeName(ytype, pnode)) {
    const normalizedContent = normalizePNodeContent(pnode);
    return (
      ytype._length === normalizedContent.length &&
      equalAttrs(ytype.getAttributes(), pnode.attrs) &&
      ytype.toArray().every((ychild, i) => equalYTypePNode(ychild, normalizedContent[i]))
    );
  }
  return ytype instanceof Y.XmlText && pnode instanceof Array && equalYTextPText(ytype, pnode);
};

/**
 * @param {PModel.Node | Array<PModel.Node> | undefined} mapped
 * @param {PModel.Node | Array<PModel.Node>} pcontent
 */
const mappedIdentity = (mapped, pcontent) =>
  mapped === pcontent ||
  (mapped instanceof Array &&
    pcontent instanceof Array &&
    mapped.length === pcontent.length &&
    mapped.every((a, i) => pcontent[i] === a));

/**
 * @param {Y.XmlElement} ytype
 * @param {PModel.Node} pnode
 * @param {ProsemirrorMapping} mapping
 * @return {{ foundMappedChild: boolean, equalityFactor: number }}
 */
const computeChildEqualityFactor = (ytype, pnode, mapping) => {
  const yChildren = ytype.toArray();
  const pChildren = normalizePNodeContent(pnode);
  const pChildCnt = pChildren.length;
  const yChildCnt = yChildren.length;
  const minCnt = math.min(yChildCnt, pChildCnt);
  let left = 0;
  let right = 0;
  let foundMappedChild = false;
  for (; left < minCnt; left++) {
    const leftY = yChildren[left];
    const leftP = pChildren[left];
    if (mappedIdentity(mapping.get(leftY), leftP)) {
      foundMappedChild = true; // definite (good) match!
    } else if (!equalYTypePNode(leftY, leftP)) {
      break;
    }
  }
  for (; left + right < minCnt; right++) {
    const rightY = yChildren[yChildCnt - right - 1];
    const rightP = pChildren[pChildCnt - right - 1];
    if (mappedIdentity(mapping.get(rightY), rightP)) {
      foundMappedChild = true;
    } else if (!equalYTypePNode(rightY, rightP)) {
      break;
    }
  }
  return {
    equalityFactor: left + right,
    foundMappedChild,
  };
};

const ytextTrans = (ytext) => {
  let str = '';
  /**
   * @type {Y.Item|null}
   */
  let n = ytext._start;
  const nAttrs = {};
  while (n !== null) {
    if (!n.deleted) {
      if (n.countable && n.content instanceof Y.ContentString) {
        str += n.content.str;
      } else if (n.content instanceof Y.ContentFormat) {
        nAttrs[n.content.key] = null;
      }
    }
    n = n.right;
  }
  return {
    str,
    nAttrs,
  };
};

/**
 * @todo test this more
 *
 * @param {Y.Text} ytext
 * @param {Array<any>} ptexts
 * @param {ProsemirrorMapping} mapping
 */
const updateYText = (ytext, ptexts, mapping) => {
  mapping.set(ytext, ptexts);
  const { nAttrs, str } = ytextTrans(ytext);
  const content = ptexts.map((p) => ({
    insert: /** @type {any} */ (p).text,
    attributes: Object.assign({}, nAttrs, marksToAttributes(p.marks)),
  }));
  const { insert, remove, index } = simpleDiff(str, content.map((c) => c.insert).join(''));
  ytext.delete(index, remove);
  ytext.insert(index, insert);
  ytext.applyDelta(content.map((c) => ({ retain: c.insert.length, attributes: c.attributes })));
};

const marksToAttributes = (marks) => {
  const pattrs = {};
  marks.forEach((mark) => {
    if (mark.type.name !== 'ychange') {
      pattrs[mark.type.name] = mark.attrs;
    }
  });
  return pattrs;
};

/**
 * @private
 * @param {Y.Doc} y
 * @param {Y.XmlFragment} yDomFragment
 * @param {any} pNode
 * @param {ProsemirrorMapping} mapping
 */
export const updateYFragment = (y, yDomFragment, pNode, mapping) => {
  if (yDomFragment instanceof Y.XmlElement && yDomFragment.nodeName !== pNode.type.name) {
    throw new Error('node name mismatch!');
  }
  mapping.set(yDomFragment, pNode);
  // update attributes
  if (yDomFragment instanceof Y.XmlElement) {
    const yDomAttrs = yDomFragment.getAttributes();
    const pAttrs = pNode.attrs;
    for (const key in pAttrs) {
      if (pAttrs[key] !== null) {
        if (yDomAttrs[key] !== pAttrs[key] && key !== 'ychange') {
          yDomFragment.setAttribute(key, pAttrs[key]);
        }
      } else {
        yDomFragment.removeAttribute(key);
      }
    }
    // remove all keys that are no longer in pAttrs
    for (const key in yDomAttrs) {
      if (pAttrs[key] === undefined) {
        yDomFragment.removeAttribute(key);
      }
    }
  }
  // update children
  const pChildren = normalizePNodeContent(pNode);
  const pChildCnt = pChildren.length;
  const yChildren = yDomFragment.toArray();
  const yChildCnt = yChildren.length;
  const minCnt = math.min(pChildCnt, yChildCnt);
  let left = 0;
  let right = 0;
  // find number of matching elements from left
  for (; left < minCnt; left++) {
    const leftY = yChildren[left];
    const leftP = pChildren[left];
    if (!mappedIdentity(mapping.get(leftY), leftP)) {
      if (equalYTypePNode(leftY, leftP)) {
        // update mapping
        mapping.set(leftY, leftP);
      } else {
        break;
      }
    }
  }
  // find number of matching elements from right
  for (; right + left + 1 < minCnt; right++) {
    const rightY = yChildren[yChildCnt - right - 1];
    const rightP = pChildren[pChildCnt - right - 1];
    if (!mappedIdentity(mapping.get(rightY), rightP)) {
      if (equalYTypePNode(rightY, rightP)) {
        // update mapping
        mapping.set(rightY, rightP);
      } else {
        break;
      }
    }
  }
  y.transact(() => {
    // try to compare and update
    while (yChildCnt - left - right > 0 && pChildCnt - left - right > 0) {
      const leftY = yChildren[left];
      const leftP = pChildren[left];
      const rightY = yChildren[yChildCnt - right - 1];
      const rightP = pChildren[pChildCnt - right - 1];
      if (leftY instanceof Y.XmlText && leftP instanceof Array) {
        if (!equalYTextPText(leftY, leftP)) {
          updateYText(leftY, leftP, mapping);
        }
        left += 1;
      } else {
        let updateLeft = leftY instanceof Y.XmlElement && matchNodeName(leftY, leftP);
        let updateRight = rightY instanceof Y.XmlElement && matchNodeName(rightY, rightP);
        if (updateLeft && updateRight) {
          // decide which which element to update
          const equalityLeft = computeChildEqualityFactor(
            /** @type {Y.XmlElement} */ (leftY),
            /** @type {PModel.Node} */ (leftP),
            mapping
          );
          const equalityRight = computeChildEqualityFactor(
            /** @type {Y.XmlElement} */ (rightY),
            /** @type {PModel.Node} */ (rightP),
            mapping
          );
          if (equalityLeft.foundMappedChild && !equalityRight.foundMappedChild) {
            updateRight = false;
          } else if (!equalityLeft.foundMappedChild && equalityRight.foundMappedChild) {
            updateLeft = false;
          } else if (equalityLeft.equalityFactor < equalityRight.equalityFactor) {
            updateLeft = false;
          } else {
            updateRight = false;
          }
        }
        if (updateLeft) {
          updateYFragment(
            y,
            /** @type {Y.XmlFragment} */ (leftY),
            /** @type {PModel.Node} */ (leftP),
            mapping
          );
          left += 1;
        } else if (updateRight) {
          updateYFragment(
            y,
            /** @type {Y.XmlFragment} */ (rightY),
            /** @type {PModel.Node} */ (rightP),
            mapping
          );
          right += 1;
        } else {
          mapping.delete(yDomFragment.get(left));
          yDomFragment.delete(left, 1);
          yDomFragment.insert(left, [createTypeFromTextOrElementNode(leftP, mapping)]);
          left += 1;
        }
      }
    }
    const yDelLen = yChildCnt - left - right;
    if (yChildCnt === 1 && pChildCnt === 0 && yChildren[0] instanceof Y.XmlText) {
      mapping.delete(yChildren[0]);
      yChildren[0].delete(0, yChildren[0].length);
    } else if (yDelLen > 0) {
      yDomFragment.delete(left, yDelLen);
    }
    if (left + right < pChildCnt) {
      const ins = [];
      for (let i = left; i < pChildCnt - right; i++) {
        ins.push(createTypeFromTextOrElementNode(pChildren[i], mapping));
      }
      // maybe we can find and add to the fragment which user updates the view
      yDomFragment.insert(left, ins);
    }
  }, ySyncPluginKey);
};

/**
 * @function
 * @param {Y.XmlElement} yElement
 * @param {any} pNode Prosemirror Node
 */
const matchNodeName = (yElement, pNode) =>
  !(pNode instanceof Array) && yElement.nodeName === pNode.type.name;
