import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { uuidv4 } from 'lib0/random';
import { EditorView } from 'prosemirror-view';
import { TextSelection } from 'prosemirror-state';
import { Mark } from 'prosemirror-model';

import { ProsemirrorEditorsService } from '../../services/prosemirror-editors.service';
import { YdocService } from '../../services/ydoc.service';
import { AuthService } from '@core/services/auth.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { EditCommentDialogComponent } from '../edit-comment-dialog/edit-comment-dialog.component';
import { AskBeforeDeleteComponent } from '@app/editor/dialogs/ask-before-delete/ask-before-delete.component';
import { ArticleCollaborator, User } from '@app/core/models/article.models';
import {
  Comment,
  YdocCommentThread,
  ResolveAction,
  ResolveRecord,
  CommentRecord,
  YdocComment,
  Colors,
  RecordType,
} from '../comment.models';
import { CommentService } from './comment.service';
import { commentConfig } from './comment.config';
import { CommentsService } from '@app/editor/utils/commentsService/comments.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  providers: [CommentService],
})
export class CommentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() comment?: Comment;
  @Input() doneRenderingComments$?: Subject<string>;

  @Output() selected = new EventEmitter<boolean>();

  @ViewChild('content') private elementView: ElementRef | undefined;
  @ViewChild('input') private input: ElementRef | undefined;
  @ViewChild('replyDiv') private replyDiv: ElementRef | undefined;

  initialShowMore = false;
  userComment?: YdocCommentThread;
  commentFormControl = new UntypedFormControl('');
  activeReply = false;
  threadOfComments: Comment[] = [];
  repliesShowMore: boolean[] = [];

  private lastAddBoxHeight = commentConfig.defaultLastAddBoxHeight;
  private contentWidth: number = commentConfig.maxContentWidth;
  private moreLessBtnView = {};
  private originalComment?: Comment;
  private selectedCommentIndex: number;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    public ydocService: YdocService,
    public sharedDialog: MatDialog,
    private prosemirrorEditorService: ProsemirrorEditorsService,
    public sharedService: ServiceShare,
    private router: Router,
    public dialog: MatDialog,
    private changeDetection: ChangeDetectorRef,
    private commentService: CommentService,
    private commentsService: CommentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
  }

  get mobileVersion(): boolean {
    return this.commentService.mobileVersion;
  }

  get canShowHeaderActions(): boolean {
    return this.commentService.canShowHeaderActions;
  }

  get canActOnThread(): boolean {
    return this.commentService.canActOnThread;
  }

  get canUserInteractInPreviewMode(): boolean {
    return this.commentService.canUserInteractInPreviewMode;
  }

  private get selectedComment(): Comment {
    return this.threadOfComments[this.selectedCommentIndex];
  }

  private get commentData(): YdocCommentThread {
    return this.commentService.getCommentThread(this.comment?.commentAttrs.id);
  }

  private get canAddInNewVersion(): boolean {
    return !this.sharedService.oldVersion && this.sharedService.canAddComments;
  }

  ngOnInit(): void {
    this.selectedCommentIndex = 0;
    this.originalComment = this.comment;
    this.loadCommentThread();

    this.userComment = this.commentService.getCommentThread(this.comment!.commentAttrs.id) || {
      initialComment: undefined,
      commentReplies: undefined,
    };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.doneRenderingComments$.next('rendered');
    }, 10);

    this.subscribeToYdocCommentsChange();
    this.subscribeToCommentsChange();
    this.initializeRepliesShowMore();
    this.handleLastSelectedComment();
  }

  adjustRows(): void {
    const textarea: HTMLTextAreaElement = this.input?.nativeElement;
    if (textarea) {
      if (this.lastAddBoxHeight !== textarea.getBoundingClientRect().height) {
        this.lastAddBoxHeight = textarea.getBoundingClientRect().height;
        this.showMoreLessClick();
      }
      textarea.rows = 1;
      while (textarea.rows < 20 && textarea.scrollHeight > textarea.clientHeight) {
        textarea.rows += 1;
      }
    }
  }

  onTabChange(index: number): void {
    this.selectedCommentIndex = index;
    this.comment = this.selectedComment;
    this.userComment = this.commentService.getCommentThread(this.comment!.commentAttrs.id) || {
      initialComment: undefined,
      commentReplies: undefined,
    };
    this.changeDetection.detectChanges();
    setTimeout(() => {
      this.commentsService.commentsChangeSubject.next('change');
    }, 200);
  }

  getCommentThread(id: string): YdocCommentThread | undefined {
    return this.commentService.getCommentThread(id);
  }

  getThreadColor(colorKey: keyof Colors, comment: Comment): string {
    const { userData } = this.getCommentThread(comment.commentAttrs.id).initialComment;
    return this.commentService.getUserColor(colorKey, userData);
  }

  getCurrentUserColor(colorKey: keyof Colors, comment?: YdocComment): string {
    const { userData } = comment ? comment : this.userComment!.initialComment;
    return this.commentService.getUserColor(colorKey, userData);
  }

  commentIsChangedInYdoc(): void {
    this.doneRenderingComments$.next('change_in_comments_in_ydoc');
  }

  trackById(index: number, item: Comment): string {
    return item.commentAttrs.id;
  }

  showMoreLessClick(): void {
    setTimeout(() => {
      this.doneRenderingComments$.next('show_more_less_click');
    }, 30);
  }

  handleCommentButtonClick(comment: Comment): void {
    const commentTxt = this.commentFormControl.value?.trim();
    const record = this.generateCommentRecord();
    const isResolved = comment.commentAttrs.resolved === 'true';
    if (isResolved) {
      this.addResolveComment(false);
      this.updateResolveState(false);
    }
    this.addCommentRecord(commentTxt, record);
  }

  checkIfCommentHasChanged(commentInYdoc: YdocCommentThread): void {
    let changed = false;
    if (commentInYdoc) {
      if (commentInYdoc.initialComment.comment != this.userComment.initialComment.comment) {
        changed = true;
      }
      if (commentInYdoc.commentReplies.length != this.userComment.commentReplies.length) {
        changed = true;
      } else {
        commentInYdoc.commentReplies.forEach((reply, index) => {
          const localReply = this.userComment.commentReplies[index];
          if (localReply.comment != reply.comment) {
            changed = true;
          }
        });
      }
    } else {
      // comment deleted
      changed = true;
    }
    if (changed && commentInYdoc) {
      this.userComment = JSON.parse(JSON.stringify(commentInYdoc));

      setTimeout(() => {
        this.commentIsChangedInYdoc();
      }, 20);
    }
  }

  selectComment(): void {
    if (!this.comment) return;

    const view = this.getEditorView();
    const actualComment = this.findActualComment();

    if (actualComment) {
      this.focusAndScrollToComment(view, actualComment);
    }

    this.triggerThreadChangeIfNeeded();
  }

  onDelete(view: EditorView, commentId: string): void {
    const state = view.state;
    const docSize = state.doc.content.size;
    let from: number, to: number;
    let markForRemove: Mark;

    state.doc.nodesBetween(0, docSize, (node, position) => {
      const comment = node?.marks.find((c) => c.attrs.id == commentId);

      if (comment) {
        markForRemove = comment;
        if (!from) {
          from = position;
        }
        to = position += node.nodeSize;
      }
    });
    view.focus();
    view.dispatch(state.tr.removeMark(from, to, markForRemove));

    setTimeout(() => {
      this.commentService.deleteCommentThread(this.comment?.commentAttrs.id);
    }, 500);
  }

  resolveComment(isResolved: boolean, event: Event): void {
    event.stopPropagation();
    this.addResolveComment(isResolved);
    this.updateResolveState(isResolved);
  }

  deleteComment(showConfirmDialog: boolean, comment: string): void {
    const viewRef =
      this.sharedService.ProsemirrorEditorsService.editorContainers[this.comment.section]
        .editorView;
    if (showConfirmDialog) {
      const dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
        data: { objName: comment, type: 'comment' },
        panelClass: 'ask-before-delete-dialog',
      });
      dialogRef.afterClosed().subscribe((data: unknown) => {
        if (data) {
          this.onDelete(viewRef, this.comment.commentAttrs.id);
        }
      });
      return;
    }
    this.onDelete(viewRef, this.comment.commentAttrs.id);
  }

  deleteReply(id: string, reply: string): void {
    const dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
      data: { objName: reply, type: 'reply' },
      panelClass: 'ask-before-delete-dialog',
    });
    dialogRef.afterClosed().subscribe((data: unknown) => {
      if (data) {
        this.comment = this.selectedComment;
        const commentData: YdocCommentThread = this.commentData;
        commentData.commentReplies.splice(
          commentData.commentReplies.findIndex((el) => {
            return el.id == id;
          }),
          1
        );
        this.commentService.setCommentThread(this.comment?.commentAttrs.id, commentData);
        this.userComment = commentData;
        this.loadCommentThread();
      }
    });
  }

  showReplyFocusHandle(autocomplete): void {
    this.activeReply = true;
    autocomplete.showResults();
  }

  hideReplyBlurHandle(): void {
    if (this.commentFormControl.value == '') {
      this.activeReply = false;
    }
  }

  handleClickOutside(event, autocomplete): void {
    if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'MAT-ICON') {
      autocomplete.hideResults();
    }
  }

  cancelReplyBtnHandle(): void {
    this.input.nativeElement.rows = 1;
    this.commentFormControl.setValue('');
    this.activeReply = false;
  }

  canActOnComment(comment: YdocComment): boolean {
    const isNotResolveRecord = !this.commentService.isResolveRecord(comment);
    return isNotResolveRecord && this.canAddInNewVersion;
  }

  canActOnReply(comment: YdocComment, user: User): boolean {
    return (
      !this.mobileVersion &&
      comment.userData.email == user.email &&
      this.sharedService.canAddComments
    );
  }

  editComment(commentContent: string): void {
    this.comment = this.selectedComment;
    const commentData: YdocCommentThread = this.commentData;
    const dialogRef = this.sharedDialog.open(EditCommentDialogComponent, {
      panelClass: 'comment-edit-dialog',
      width: '582px',
      data: {
        comment: commentContent,
        type: 'comment',
        actualCommentId: this.comment,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      const newCommentContent = result;
      if (result && newCommentContent != commentContent) {
        commentData.initialComment.comment = newCommentContent;
        this.userComment = commentData;
        this.commentService.setCommentThread(this.comment?.commentAttrs.id, commentData);
        this.changeDetection.detectChanges();
        this.contentWidth = this.elementView?.nativeElement.firstChild.offsetWidth;
        this.moreLessBtnView[this.comment!.commentAttrs.id] =
          this.contentWidth >= commentConfig.maxContentWidth;
      }
    });
  }

  editReply(id: string, commentContent: string): void {
    this.comment = this.selectedComment;
    const commentData: YdocCommentThread = this.commentData;
    const dialogRef = this.sharedDialog.open(EditCommentDialogComponent, {
      panelClass: 'comment-edit-dialog',
      width: '582px',
      data: { comment: commentContent, type: 'comment', actualCommentId: this.comment },
    });
    dialogRef.afterClosed().subscribe((result) => {
      const newCommentContent = result;
      if (result && newCommentContent != commentContent) {
        commentData.commentReplies.forEach((userComment, index, array) => {
          if (userComment.id == id) {
            array[index].comment = newCommentContent;
          }
        });
        this.userComment = commentData;
        this.commentService.setCommentThread(this.comment?.commentAttrs.id, commentData);
        this.changeDetection.detectChanges();
        this.contentWidth = this.elementView?.nativeElement.firstChild.offsetWidth;
        this.moreLessBtnView[this.comment!.commentAttrs.id] =
          this.contentWidth >= commentConfig.maxContentWidth;
      }
    });
  }

  getCommentButtonText(comment: Comment): string {
    const { resolveButtonText } = commentConfig;
    return comment.commentAttrs.resolved == 'true'
      ? resolveButtonText['unresolved']
      : resolveButtonText['resolved'];
  }

  ngOnDestroy(): void {
    this.commentService.destroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private addResolveComment(isResolved: boolean): void {
    const resolveAction = isResolved ? 'resolved' : 'unresolved';
    const record = this.generateResolveRecord(resolveAction);
    const commentTxt = this.getResolveRecordText(resolveAction);
    this.addCommentRecord(commentTxt, record);
  }

  private updateResolveState(isResolved: boolean): void {
    // Retrieve the ProseMirror editor view for the current comment section
    const view =
      this.sharedService.ProsemirrorEditorsService.editorContainers[this.comment.section]
        .editorView;
    let state = view.state;
    const docSize = state.doc.content.size;
    let from: number, to: number;
    let markForRemove: Mark;

    // Identify the mark associated with the comment to be resolved/unresolved
    state.doc.nodesBetween(0, docSize, (node, position) => {
      const comment = node?.marks.find((c) => c.attrs.id == this.comment.commentAttrs.id);

      if (comment) {
        markForRemove = comment;
        if (!from) {
          from = position;
        }
        to = position += node.nodeSize;
      }
    });

    // Remove the existing mark and re-add it with the updated resolved state
    let tr = state.tr.removeMark(from, to, markForRemove);
    view.dispatch(tr);

    // Re-fetch state and add a new mark with updated resolved state
    state = view.state;
    const newMark = state.schema.marks[markForRemove.type.name].create({
      ...markForRemove.attrs,
      resolved: `${isResolved}`,
    });
    tr = state.tr.addMark(from, to, newMark);
    view.dispatch(tr);

    // Update the component's comment state to reflect the new resolved status
    this.comment.commentAttrs.resolved = newMark.attrs.resolved;
    this.comment.resolved = newMark.attrs.resolved;

    this.commentsService.updateAllComments();
    this.changeDetection.detectChanges();
  }

  private getEditorView(): EditorView {
    return this.sharedService.ProsemirrorEditorsService.editorContainers[this.comment.section]
      .editorView;
  }

  private findActualComment(): Comment | undefined {
    const allComments = this.commentsService.commentsObj;
    this.commentsService.selectedThreadComment = undefined;

    for (const commentId of Object.keys(allComments)) {
      const commentData = allComments[commentId];
      if (commentData) {
        if (commentData.commentAttrs.id === this.comment.commentAttrs.id) {
          return commentData;
        }

        const threadComment = commentData.threadComments.find(
          (c) => c.commentAttrs.id === this.comment.commentAttrs.id
        );

        if (threadComment) {
          this.commentsService.selectedThreadComment = threadComment.commentAttrs.id;
          return threadComment;
        }
      }
    }
  }

  private focusAndScrollToComment(view: EditorView, comment: Comment): void {
    view.focus();
    view.dispatch(
      view.state.tr
        .setSelection(
          new TextSelection(
            view.state.doc.resolve(comment.pmDocStartPos),
            view.state.doc.resolve(comment.pmDocStartPos)
          )
        )
        .setMeta('selected-comment', true)
        .scrollIntoView()
    );
  }

  private triggerThreadChangeIfNeeded(): void {
    if (this.threadOfComments.length > 1) {
      setTimeout(() => {
        this.commentsService.commentsChangeSubject.next('change');
        this.changeDetection.detectChanges();
      }, 500);
    }
  }

  private subscribeToYdocCommentsChange(): void {
    this.commentsService.ydocCommentsChangeSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((commentsObj) => {
        if (commentsObj) {
          const ydocCommentInstance = commentsObj[this.comment.commentAttrs.id];
          this.checkIfCommentHasChanged(ydocCommentInstance);
        } else {
          this.loadCommentThread();
        }
        this.changeDetection.detectChanges();
      });
  }

  private subscribeToCommentsChange(): void {
    this.commentsService.commentsChangeSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.loadCommentThread();
      this.changeDetection.detectChanges();
    });
  }

  private initializeRepliesShowMore(): void {
    this.userComment?.commentReplies.forEach((_, index) => {
      this.repliesShowMore[index] = false;
    });
  }

  private generateCommentRecord(): CommentRecord {
    const commentRecord: CommentRecord = {
      type: 'comment',
      pmDocStartPos: this.comment!.pmDocStartPos,
      pmDocEndPos: this.comment!.pmDocEndPos,
      commentTxt: this.comment!.commentTxt,
      sectionId: this.comment!.section,
    };

    this.commentFormControl.setValue('');
    this.activeReply = false;
    return commentRecord;
  }

  private generateResolveRecord(resolveAction: ResolveAction): ResolveRecord {
    const resolveRecord: ResolveRecord = {
      type: 'action',
      actionType: resolveAction,
    };
    return resolveRecord;
  }

  private getResolveRecordText(resolveAction: ResolveAction): string {
    const { resolveRecordText } = commentConfig;
    return resolveRecordText[resolveAction];
  }

  private addCommentRecord(commentTxt: string, record: RecordType): void {
    const commentData: YdocCommentThread = this.commentData;

    this.commentFormControl.setValue('');
    this.activeReply = false;
    commentData.commentReplies.push({
      ...record,
      date: Date.now(),
      id: uuidv4(),
      userData: {
        ...this.prosemirrorEditorService.userInfo.data,
        userColor: this.prosemirrorEditorService.userInfo.color.userColor,
        userContrastColor: this.prosemirrorEditorService.userInfo.color.userContrastColor,
      },
      comment: commentTxt,
    });

    this.commentService.setCommentThread(this.comment?.commentAttrs.id, commentData);
    this.userComment = commentData;

    setTimeout(() => {
      this.input.nativeElement.rows = 1;
      this.doneRenderingComments$.next('replay_rerender');
    }, 400);
  }

  private loadCommentThread(): void {
    this.originalComment = this.commentsService.commentsObj[this.originalComment?.commentAttrs.id];
    if (this.originalComment) {
      const hasThread = !!this.originalComment.threadComments.length;
      const currentUser = this.sharedService.YdocService.currUser;
      const collaborators = this.sharedService.YdocService.getCollaborators().filter(
        (c: ArticleCollaborator) => c.id != currentUser?.id
      );
      const idsThatShouldBeHidden = collaborators
        .filter(
          (collaborator) =>
            collaborator.hide_my_comments_from_user?.includes(currentUser?.auth_role) ||
            collaborator.hide_my_comments_from_user?.includes(currentUser?.id)
        )
        .map((collaborator) => collaborator.id);

      if (!hasThread) {
        this.threadOfComments = [this.originalComment];
        this.comment = this.originalComment;
      } else {
        if (this.commentsService.showResolved) {
          this.threadOfComments = [
            this.originalComment,
            ...this.originalComment.threadComments.filter(
              (c) => !idsThatShouldBeHidden.includes(c.commentAttrs.userid)
            ),
          ];
          this.comment = this.selectedComment || this.threadOfComments[0];
        } else {
          if (this.originalComment.commentAttrs.resolved == 'false') {
            this.threadOfComments = [
              this.originalComment,
              ...this.originalComment.threadComments
                .filter((c) => c.commentAttrs.resolved == 'false')
                .filter((c) => !idsThatShouldBeHidden.includes(c.commentAttrs.userid)),
            ];
          } else {
            this.threadOfComments = [
              ...this.originalComment.threadComments
                .filter((c) => c.commentAttrs.resolved == 'false')
                .filter((c) => !idsThatShouldBeHidden.includes(c.commentAttrs.userid)),
            ];
          }
          this.comment = this.selectedComment || this.threadOfComments[0] || this.originalComment;
        }
      }
      this.userComment = this.commentService.getCommentThread(this.comment.commentAttrs.id) || {
        initialComment: undefined,
        commentReplies: undefined,
      };
    }
  }

  private handleLastSelectedComment(): void {
    let timeout: NodeJS.Timeout;

    this.commentsService.lastSelectedCommentSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((comment) => {
        if (this.ydocService.curUserAccess && this.ydocService.curUserAccess === 'Reader') {
          return;
        }

        const isThreadContainsComment = this.threadOfComments.some(
          (c) => c.commentAttrs.id === comment.commentId
        );
        const displayValue = isThreadContainsComment ? 'block' : 'none';
        const emitValue = isThreadContainsComment;

        if (this.replyDiv) {
          (this.replyDiv.nativeElement as HTMLDivElement).style.display = displayValue;
        }
        this.selected.emit(emitValue);

        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.loadCommentThread();
        }, 200);
      });
  }
}
