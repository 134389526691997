import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Map } from 'yjs';
import { Node } from 'prosemirror-model';

import { YdocService } from '../../services/ydoc.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { supplementaryFile } from '@app/editor/utils/interfaces/supplementaryFile';
import { AddSupplementaryFileComponent } from './add-supplementary-file/add-supplementary-file.component';
import { AskBeforeDeleteComponent } from '../ask-before-delete/ask-before-delete.component';

@Component({
  selector: 'app-supplementary-files-dialog',
  templateUrl: './supplementary-files.component.html',
  styleUrls: ['./supplementary-files.component.scss'],
})
export class SupplementaryFilesDialogComponent {
  supplementaryFilesMap?: Map<any>;
  supplementaryFilesNumbers?: string[];
  supplementaryFiles?: { [key: string]: supplementaryFile };
  editedSupplementaryFiles: { [key: string]: boolean } = {};
  newSupplementaryFilesNodes: { [key: string]: Node } = {};
  deletedSupplementaryFiles: string[] = [];

  constructor(
    private ydocService: YdocService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<SupplementaryFilesDialogComponent>,
    private serviceShare: ServiceShare
  ) {
    let supplementaryFilesNumbersArray = this.ydocService.supplementaryFilesMap!.get(
      'supplementaryFilesNumbers'
    );
    let supplementaryFiles = this.ydocService.supplementaryFilesMap!.get('supplementaryFiles');
    this.supplementaryFilesNumbers = JSON.parse(JSON.stringify(supplementaryFilesNumbersArray));
    this.supplementaryFiles = JSON.parse(JSON.stringify(supplementaryFiles));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.supplementaryFilesNumbers!, event.previousIndex, event.currentIndex);
    this.serviceShare.CitableElementsService.writeElementDataGlobal(
      this.supplementaryFiles!,
      this.supplementaryFilesNumbers!,
      'supplementary_file_citation'
    );
  }

  editSupplementaryFile(supplementaryFile: supplementaryFile, supplementaryFileIndex: number) {
    this.dialog
      .open(AddSupplementaryFileComponent, {
        data: {
          supplementaryFile,
          updateOnSave: false,
          index: supplementaryFileIndex,
          supplementaryFileID: supplementaryFile.supplementary_file_ID,
        },
        disableClose:
          this.serviceShare.CitableElementsService.closeOnClickOutsideSupplementaryFiles,
      })
      .afterClosed()
      .subscribe(
        (result: { supplementaryFile: supplementaryFile; supplementaryFileNode: Node }) => {
          if (result && result.supplementaryFile) {
            this.supplementaryFilesNumbers?.splice(
              supplementaryFileIndex,
              1,
              result.supplementaryFile.supplementary_file_ID
            );
            this.supplementaryFiles![result.supplementaryFile.supplementary_file_ID] =
              result.supplementaryFile;
            // this.newSupplementaryFilesNodes[result.supplementaryFile.supplementary_file_ID] = result.supplementaryFileNode;
            this.editedSupplementaryFiles[result.supplementaryFile.supplementary_file_ID] = true;
            this.serviceShare.CitableElementsService.writeElementDataGlobal(
              this.supplementaryFiles!,
              this.supplementaryFilesNumbers!,
              'supplementary_file_citation'
            );
          }
        }
      );
  }

  deleteSupplementaryFile(supplementaryFile: supplementaryFile, supplementaryFileIndex: number) {
    let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
      data: {
        type: 'supplementaryFile',
        dontshowType: true,
        objName: 'Suppl. material №' + (supplementaryFileIndex + 1),
      },
      panelClass: 'ask-before-delete-dialog',
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.supplementaryFilesNumbers?.splice(supplementaryFileIndex, 1);
        delete this.supplementaryFiles![supplementaryFile.supplementary_file_ID];
        if (this.editedSupplementaryFiles[supplementaryFile.supplementary_file_ID]) {
          delete this.editedSupplementaryFiles[supplementaryFile.supplementary_file_ID];
        }
        this.serviceShare.YdocService.ydoc.getMap('change').set('change', 'change');
        this.serviceShare.CitableElementsService.writeElementDataGlobal(
          this.supplementaryFiles,
          this.supplementaryFilesNumbers,
          'supplementary_file_citation'
        );
      }
    });
  }

  addSupplementaryFile() {
    this.dialog
      .open(AddSupplementaryFileComponent, {
        data: {
          supplementaryFile: undefined,
          updateOnSave: false,
          index: this.supplementaryFilesNumbers?.length,
        },
        disableClose:
          this.serviceShare.CitableElementsService.closeOnClickOutsideSupplementaryFiles,
      })
      .afterClosed()
      .subscribe(
        (result: { supplementaryFile: supplementaryFile; supplementaryFileNode: Node }) => {
          if (result && result.supplementaryFile) {
            this.supplementaryFilesNumbers?.push(result.supplementaryFile.supplementary_file_ID);
            this.supplementaryFiles![result.supplementaryFile.supplementary_file_ID] =
              result.supplementaryFile;
            // this.newSupplementaryFilesNodes[result.supplementaryFile.supplementary_file_ID] = result.supplementaryFileNode;
            this.serviceShare.CitableElementsService.writeElementDataGlobal(
              this.supplementaryFiles!,
              this.supplementaryFilesNumbers!,
              'supplementary_file_citation'
            );
          }
        }
      );
  }

  saveSupplementaryFiles() {
    this.dialogRef.close(true);
  }

  deleteAll() {
    let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
      data: { objName: 'all', type: 'supplementaryFiles' },
      panelClass: 'ask-before-delete-dialog',
      width: '300px !important',
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.supplementaryFilesNumbers = [];
        Object.keys(this.editedSupplementaryFiles).forEach((id) => {
          if (this.editedSupplementaryFiles[id]) {
            delete this.editedSupplementaryFiles[id];
          }
        });
        this.supplementaryFiles = {};
        this.serviceShare.CitableElementsService.writeElementDataGlobal(
          this.supplementaryFiles,
          this.supplementaryFilesNumbers,
          'supplementary_file_citation'
        );
      }
    });
  }

  cancelSupplementaryFilesEdit() {
    this.dialogRef.close();
  }
}
