import { InjectionToken } from '@angular/core';

export const ECHO_CONFIG = new InjectionToken<EchoConfig>('ECHO_CONFIG');

/**
 * Service configuration
 */
export interface EchoConfig {
  /**
   * The name of the user model of the backend application
   */
  userModel: string;
  /**
   * The name of the namespace for notifications of the backend application
   */
  notificationNamespace: string | null;
  /**
   * Laravel Echo configuration
   */
  options: any;
}
