import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as katex from 'katex';

@Component({
  selector: 'app-add-comment-dialog',
  templateUrl: './add-comment-dialog.component.html',
  styleUrls: ['./add-comment-dialog.component.scss'],
})
export class AddCommentDialogComponent implements AfterViewInit {
  text = new UntypedFormControl('', [Validators.required]);
  mathType = new UntypedFormControl('', [Validators.required]);
  type: string;
  @ViewChild('dialogInput', { read: ElementRef }) dialogInput?: ElementRef;

  mathPreviews = [];

  selectedMathExample = 'math_inline';

  constructor(
    public dialogRef: MatDialogRef<AddCommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      url: string;
      from?: number;
      to?: number;
      text?: any;
      typeOfNode?: string;
    },
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    this.type = data.type;

    this.text.valueChanges.subscribe((katexFormula) => {
      this.mathPreviews = [];
      if (katexFormula) {
        let singleLineExample;
        try {
          const singlelineFormula = katex.renderToString(katexFormula);
          singleLineExample = {
            value: 'math_inline',
            html: 'Lorem Ipsum lorem ipsum ' + singlelineFormula + ' lorem ipsum lorem ipsum.',
          };
          this.mathPreviews.push(singleLineExample);
          this.selectedMathExample = 'math_inline';
        } catch {}

        try {
          const multilineFormula = katex.renderToString(katexFormula, {
            displayMode: true,
          });
          const multilineExample = {
            value: 'math_display',
            html: 'Lorem Ipsum lorem ipsum:' + multilineFormula + 'ipsum lorem ipsum.',
          };
          this.mathPreviews.push(multilineExample);

          if (!singleLineExample) {
            this.selectedMathExample = 'math_display';
          }
        } catch {}
      }
    });
  }

  ngAfterViewInit(): void {
    this.dialogInput.nativeElement.focus();
    if (this.data.text) {
      this.text.setValue(this.data.text);
      this.mathType.setValue(this.data.typeOfNode);
    }
    this.ref.detectChanges();
  }

  sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getErrorMessage() {
    if (this.text.hasError('required')) {
      return 'You must enter a value';
    }

    return '';
  }
}
