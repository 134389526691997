import { CdkDragEnter, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import {
  editorContainer,
  ProsemirrorEditorsService,
} from '@app/editor/services/prosemirror-editors.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { figure } from '@app/editor/utils/interfaces/figureComponent';
import { PMDomParser, schema } from '@app/editor/utils/Schema';
import { figureJson } from '@app/editor/utils/section-templates/form-io-json/FIGUREjson';
import { MatFormioModule } from '@app/formio-angular-material/angular-material-formio.module';
import { MaterialModule } from '@app/shared/material.module';
import { basicSetup, EditorState, EditorView } from '@codemirror/basic-setup';
import { html } from '@codemirror/lang-html';
import { FormioModule } from '@formio/angular';
import { uuidv4 } from 'lib0/random';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { TextSelection } from 'prosemirror-state';
import { AskBeforeDeleteComponent } from '../../ask-before-delete/ask-before-delete.component';
import { AddFigureComponentDialogComponent } from './add-figure-component-dialog/add-figure-component-dialog.component';
import { FigureComponentPreviewComponent2 } from './figure-component-preview/figure-component-preview.component';
import { FigurePdfPreviewComponent } from './figure-pdf-preview/figure-pdf-preview.component';
import { AddFromGalleryComponentDialogComponent } from './add-from-gallery-component-dialog/add-from-gallery-component';
import { ArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { CommonModule } from '@angular/common';

export let figuresHtmlTemplate = `
<block-figure [attr.viewed_by_citat]="data.viewed_by_citat||''" [attr.figure_number]="data.figureNumber" [attr.figure_id]="data.figureID" [attr.figure_columns]="data.nOfColumns">
  <figure-components-container contenteditablenode="false">
    <ng-container *ngFor="let figure of data.figureComponents;let i = index">
      <ng-container *ngIf="figure">
        <figure-component [attr.actual_number]="figure.container.componentNumber" [attr.component_number]="i" contenteditablenode="false" [attr.viewed_by_citat]="data.viewed_by_citat||''">
         <figure-actions>
          <code *ngIf="data.figureComponents.length>1">{{getCharValue(i)}}</code>
          <fig-action class="figure-zoom" src="{{figure.container.url}}" ></fig-action>
          <fig-action class="figure-download" src="{{figure.container.url}}" ></fig-action>
         </figure-actions>
          <img-placeholder></img-placeholder>
          <img *ngIf="figure.container.componentType == 'image'" src="" alt="image" numberOfColumns="{{data.nOfColumns}}" title="image" contenteditable="false" draggable="true" />
          <iframe *ngIf="figure.container.componentType == 'video' && !figure.container.url.includes('scalewest.com')" [src]="figure.container.url | safe" controls="" contenteditable="false" draggable="true"></iframe>
          <video *ngIf="figure.container.componentType == 'video' && figure.container.url.includes('scalewest.com')" src='{{figure.container.url}}' height='350' controls></video>
        </figure-component>
      </ng-container>
    </ng-container>
  </figure-components-container>
  <figure-descriptions-container>
      <h3 tagname="h3" contenteditablenode="false"><p contenteditablenode="false">Figure {{data.figureNumber+1}}.</p></h3>
      <figure-description *ngIf="data.figureDescription" formControlName="figureDescription" style="display:block;">
      </figure-description>
      <ng-container  formArrayName="figureComponents" >
        <ng-container *ngFor="let control of formGroup.controls.figureComponents.controls;let i = index" formGroupName="{{i}}">
          <figure-component-description [attr.actual_number]="data.figureComponents[i].container.componentNumber" [attr.viewed_by_citat]="data.viewed_by_citat||''" [attr.component_number]="i" style="display:flex;">
            <form-field contenteditablenode="false" *ngIf="data.figureComponents.length>1">
                <p align="set-align-left"  class="set-align-left">{{getCharValue(i)}}:&nbsp;</p>
            </form-field>
            <form-field formControlName="figureComponentDescription">
            </form-field>
          </figure-component-description>
        </ng-container>
      </ng-container>
  </figure-descriptions-container>
  <spacer></spacer>
</block-figure>
`;

export interface GalleryImageData {
  url: string;
  typeStatus: string;
  scientificName: string;
  class: string;
  recordNumber: string;
}

export interface AddFromGalleryComponentData {
  description: string;
  url: string;
  componentType: string;
}

@Component({
  selector: 'app-add-figure-dialog-v2',
  templateUrl: './add-figure-dialog-v2.component.html',
  styleUrls: ['./add-figure-dialog-v2.component.scss'],
})
export class AddFigureDialogV2Component implements AfterViewInit, AfterViewChecked, OnDestroy {
  showHTMLEditor = false;

  @ViewChild('container', { read: ViewContainerRef }) container?: ViewContainerRef;
  codemirrorHtmlTemplate?: HTMLElement;
  gridContainer?: HTMLElement;
  figureDescription?: HTMLElement;
  figureDescriptionPmContainer: editorContainer;

  figureID?: string;
  figuresTemplatesObj: any;
  codemirrorHTMLEditor?: EditorView;
  sectionContent = JSON.parse(JSON.stringify(figureJson));
  figData: any;
  figNewComponents: any[] = [];

  figureComponentsInPrevew = [];
  // offset is in persentage
  rowOrder: number[] = [];
  displayPreviewComponents = false;
  figureCanvasData: any;

  columns = [1, 2, 3, 4];
  columnsFormControl = new UntypedFormControl();
  columnWidth: number;

  showPdfView = false;
  figureRows: any[][] = [];
  maxImgHeightPers;
  rowTemplate: any[] = [];
  maxImgWidthPers?: number;
  bottomOffset = 0.3; // offset the figures images from the bottom of the list in preview- figure description space

  initFunc: (selfRef: any) => () => void;
  renderProsemirrorEditor: (selfRef: any) => () => void;
  renderCodemMirrorEditors: (selfRef: any) => (figID: string) => void;
  editComponent: (selfRef: any) => (component: any, i: number) => void;
  addComponent: (selfRef: any) => () => void;
  addFromGallery: (selfRef: any) => () => void;
  deleteComponent: (selfRef: any) => (component: any, i: number) => void;
  dragEntered: (selfRef: any) => (event: CdkDragEnter<number>) => void;
  submitFigure: (selfRef: any) => () => void;
  getMappedComponentsForPreviw: (selfRef: any) => () => any;
  updatePreview: (selfRef: any) => (checkDiff: boolean) => any;
  openFigurePdfPreview: (selfRef: any) => () => any;
  getMaterialsSectionData: (selfRef: any) => (sections: string[]) => any;
  getImageGalleryData: (selfRef: any) => (defaultFormIOValues: any) => any;
  createImageData: (selfRef: any) => (url: string, item: any) => any;
  trimAndRemoveSemicolon: (selfRef: any) => (text: string) => any;

  defaultOpenFigurePdfPreview: any;
  defaultSubmitFigure: any;
  defaultAddComponent: any;
  defaultAddFromGallery: any;
  defaultEditComponent: any;
  defaultDeleteComponent: any;
  defaultDragEntered: any;
  defaultGetMaterialsSectionData: any;
  defaultGetImageGalleryData: any;
  defaultCreateImageData: any;
  defaultTrimAndRemoveSemicolon: any;

  modalTemplate: string;
  urlMapping: any = {};

  articleSectionsStructure: string[] = this.ydocService.articleStructureMap.get(
    'articleSectionsStructureFlat'
  );
  galleryImageArray: GalleryImageData[] = [];

  constructor(
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AddFigureDialogV2Component>,
    private ydocService: YdocService,
    private serviceShare: ServiceShare,
    public dialog: MatDialog,
    @Inject(APP_CONFIG) readonly config: AppConfig,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fig: figure | undefined;
      updateOnSave: boolean;
      index: number;
      figID: string | undefined;
    }
  ) {
    this.modalTemplate = this.ydocService.figuresMap.get('FiguresModalTemplate') as string;
    const self = this;

    this.initFunc = (selfRef: this) => () => {
      try {
        selfRef.gridContainer = document.querySelector('.reorder-grid');
        selfRef.figureDescription = document.querySelector('.pm-editor');
        selfRef.codemirrorHtmlTemplate = document.querySelector('.codemirror-editor');

        selfRef.figureID = selfRef.data.figID || uuidv4();
        selfRef.renderCodemMirrorEditors(selfRef)(selfRef.figureID!);
        selfRef.renderProsemirrorEditor(selfRef)();
        selfRef.columnsFormControl.valueChanges.subscribe((columns) => {
          let gridParent = selfRef.gridContainer as HTMLDivElement;

          gridParent.setAttribute('data-cols', columns);
          setTimeout(() => {
            selfRef.updatePreview(selfRef)(false);
          });
        });
        if (!selfRef.data.fig) {
          selfRef.columnsFormControl.setValue(1);
        } else {
          let nOfColumns = selfRef.data.fig.canvasData.nOfColumns;
          selfRef.columnsFormControl.setValue(nOfColumns);
          selfRef.figData = selfRef.data.fig;
          let descContainer = document.createElement('div');
          descContainer.innerHTML = selfRef.data.fig.description;
          let prosemirrorNode = PMDomParser.parse(descContainer);
          let descPmView = selfRef.figureDescriptionPmContainer.editorView;
          let state = descPmView.state;
          descPmView.dispatch(
            state.tr.replaceWith(0, state.doc.content.size, prosemirrorNode.content)
          );
        }
        setTimeout(() => {
          const view = selfRef.figureDescriptionPmContainer.editorView;
          view.focus();
          view.dispatch(
            view.state.tr.setSelection(
              TextSelection.create(view.state.doc, view.state.doc.content.size - 1)
            )
          );
        }, 40);
        selfRef.figNewComponents = JSON.parse(JSON.stringify(selfRef.figData?.components || []));
      } catch (e) {
        console.error(e);
      }
    };

    this.renderProsemirrorEditor = (selfRef: any) => () => {
      let header = selfRef.figureDescription;
      selfRef.figureDescriptionPmContainer =
        self.prosemirrorEditorsService.renderSeparatedEditorWithNoSync(
          header,
          'popup-menu-container',
          schema.nodes.paragraph.create({})
        );

      let view = selfRef.figureDescriptionPmContainer.editorView;
      //@ts-ignore
      view.isPopupEditor = true;
    };

    this.renderCodemMirrorEditors = (selfRef: any) => (figID: string) => {
      try {
        selfRef.figuresTemplatesObj = self.ydocService.figuresMap?.get('figuresTemplates');
        let currFigTemplates;
        if (!selfRef.figuresTemplatesObj[figID]) {
          selfRef.figuresTemplatesObj[figID] = { html: figuresHtmlTemplate };
          currFigTemplates = selfRef.figuresTemplatesObj[figID];
        } else {
          currFigTemplates = selfRef.figuresTemplatesObj[figID];
        }
        let prosemirrorNodesHtml = currFigTemplates.html;

        selfRef.codemirrorHTMLEditor = new EditorView({
          state: EditorState.create({
            doc: prosemirrorNodesHtml,
            extensions: [basicSetup, html()],
          }),
          parent: selfRef.codemirrorHtmlTemplate,
        });
      } catch (e) {
        console.error(e);
      }
    };

    this.submitFigure = (selfRef: any) => () => {
      let figureForSubmit = {
        canvasData: selfRef.figureCanvasData,
        components: selfRef.figNewComponents.map((c) => {
          c.description = c.description.replace(/<br[^>]+>/g, '');
          return c;
        }),
        description: selfRef.figureDescriptionPmContainer.editorView.dom.innerHTML.replace(
          /<br[^>]+>/g,
          ''
        ),
        figureID: selfRef.figureID,
        figureNumber: selfRef.data.index,
        figurePlace: selfRef.data.fig ? selfRef.data.fig.figurePlace : 'endEditor',
        viewed_by_citat: selfRef.data.fig ? selfRef.data.fig.viewed_by_citat : 'endEditor',
        clientID: this.ydocService.ydoc.clientID,
      };
      self.ydocService.figuresMap?.set('figuresTemplates', selfRef.figuresTemplatesObj);

      figureForSubmit.components.forEach((component) => {
        if (selfRef.urlMapping[component.pdfImgOrigin]) {
          component.pdfImgResized = selfRef.urlMapping[component.pdfImgOrigin];
        }
      });
      self.dialogRef.close({ figure: figureForSubmit });
    };

    this.editComponent = (selfRef: any) => (component: any, i: number) => {
      self.dialog
        .open(AddFigureComponentDialogComponent, {
          width: '840px',
          data: { component },
          disableClose: false,
        })
        .afterClosed()
        .subscribe((result: { component: any }) => {
          if (result) {
            selfRef.figNewComponents.splice(i, 1, result.component);
            selfRef.updatePreview(selfRef)(false);
          } else {
          }
        });
    };

    this.deleteComponent = (selfRef: any) => (component: any, i: number) => {
      let dialogRef = self.dialog.open(AskBeforeDeleteComponent, {
        data: {
          objName: component.componentType,
          type: component.componentType,
          dontshowType: true,
        },
        panelClass: 'ask-before-delete-dialog',
      });
      dialogRef.afterClosed().subscribe((data: any) => {
        if (data) {
          selfRef.figNewComponents.splice(i, 1);
          selfRef.updatePreview(selfRef)(false);
        }
      });
    };

    this.addComponent = (selfRef: any) => () => {
      self.dialog
        .open(AddFigureComponentDialogComponent, {
          width: '840px',
          disableClose: false,
        })
        .afterClosed()
        .subscribe((result: { component: any }) => {
          if (result) {
            result.component.id = uuidv4();
            selfRef.figNewComponents.push(result.component);
            selfRef.updatePreview(selfRef)(false);
          } else {
          }
        });
    };

    this.addFromGallery = (selfRef: any) => () => {
      const componentData: AddFromGalleryComponentData = {
        description: '',
        url: '',
        componentType: 'image',
      };
      self.dialog
        .open(AddFromGalleryComponentDialogComponent, {
          width: '840px',
          disableClose: false,
          data: {
            component: componentData,
            galleryImageArray: selfRef.galleryImageArray,
          },
        })
        .afterClosed()
        .subscribe((result: { component: AddFromGalleryComponentData }) => {
          if (result) {
            selfRef.figNewComponents.push(result.component);
            selfRef.updatePreview(selfRef)(false);
          }
        });
    };

    this.getMaterialsSectionData = (selfRef: any) => (sections: string[]) => {
      const foundMaterials: any = [];

      for (const sectionID of sections) {
        const articleSection = this.ydocService.articleSectionsMap.get(sectionID);
        if (articleSection.title && articleSection.title.name === 'Material') {
          foundMaterials.push(articleSection.defaultFormIOValues);
        }

        if (articleSection.children) {
          selfRef.getMaterialsSectionData(
            articleSection.children.map((sec: ArticleSection) => sec.sectionID)
          );
        }
      }

      return foundMaterials;
    };

    this.getImageGalleryData = (selfRef: any) => (item: any) => {
      if (item.associatedMedia && item.associatedMedia !== '') {
        const urls = item.associatedMedia.split('|');
        urls.forEach((url) => selfRef.createImageData(url, item));
      }
    };

    this.createImageData = (selfRef: any) => (url: string, item: any) => {
      url = selfRef.trimAndRemoveSemicolon(url);

      const imageData: GalleryImageData = {
        url: url,
        typeStatus: selfRef.trimAndRemoveSemicolon(item.typeStatus),
        scientificName: selfRef.trimAndRemoveSemicolon(item.scientificName),
        class: selfRef.trimAndRemoveSemicolon(item.class),
        recordNumber: selfRef.trimAndRemoveSemicolon(item.recordNumber),
      };

      selfRef.galleryImageArray.push(imageData);
    };

    this.trimAndRemoveSemicolon = (selfRef: any) => (text: string) => {
      return text ? text.trim().replace(/;$/, '') : '';
    };

    this.dragEntered = (selfRef: any) => (event: CdkDragEnter<number>) => {
      const drag = event.item;
      const dropList = event.container;
      const dragIndex = drag.data;
      const dropIndex = dropList.data;

      const phContainer = dropList.element.nativeElement;
      const phElement = phContainer.querySelector('.cdk-drag-placeholder');
      phContainer.removeChild(phElement);
      phContainer.parentElement.insertBefore(phElement, phContainer);

      moveItemInArray(selfRef.figNewComponents, dragIndex, dropIndex);
      selfRef.updatePreview(selfRef)(false);
    };

    this.getMappedComponentsForPreviw = (selfRef: any) => () => {
      return JSON.parse(
        JSON.stringify(
          selfRef.figNewComponents.map((x) => {
            return { container: x };
          })
        )
      );
    };

    this.updatePreview = this.serviceShare.updatePreview;

    this.openFigurePdfPreview = (selfRef: any) => () => {
      self.dialog.open(FigurePdfPreviewComponent, {
        width: '640px',
        disableClose: false,
        data: {
          numOfImages: selfRef.figNewComponents.length,
          figureRows: selfRef.figureRows,
          maxImgHeightPers: selfRef.maxImgHeightPers,
          rowTemplate: selfRef.rowTemplate,
          maxImgWidthPers: selfRef.maxImgWidthPers,
          bottomOffset: selfRef.bottomOffset,
        },
      });
    };

    this.defaultOpenFigurePdfPreview = this.openFigurePdfPreview(this);
    this.defaultSubmitFigure = this.submitFigure(this);
    this.defaultAddComponent = this.addComponent(this);
    this.defaultAddFromGallery = this.addFromGallery(this);
    this.defaultEditComponent = this.editComponent(this);
    this.defaultDeleteComponent = this.deleteComponent(this);
    this.defaultDragEntered = this.dragEntered(this);
    this.defaultGetMaterialsSectionData = this.getMaterialsSectionData(this);
    this.defaultGetImageGalleryData = this.getImageGalleryData(this);
    this.defaultCreateImageData = this.createImageData(this);
    this.defaultTrimAndRemoveSemicolon = this.trimAndRemoveSemicolon(this);
  }

  ngAfterViewInit(): void {
    if (this.modalTemplate) {
      setTimeout(() => {
        this.createComponent(this.modalTemplate);
      }, 0);
    } else {
      this.initFunc(this)();
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  component: ComponentRef<any>;
  createComponent(template: string): void {
    const self = this;
    const data = this.data;
    const config = this.config;

    const component = Component({
      template,
      standalone: true,
      imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatFormioModule,
        FormioModule,
        DropzoneModule,
        NgxDropzoneModule,
        DragDropModule,
        FigureComponentPreviewComponent2,
      ],
    })(
      class implements AfterViewInit {
        showHTMLEditor = false;
        data = data;
        config = config;
        codemirrorHtmlTemplate?: HTMLElement;
        gridContainer?: HTMLElement;
        figureDescription?: HTMLElement;
        figureDescriptionPmContainer: editorContainer;

        figureID?: string;
        figuresTemplatesObj: any;
        codemirrorHTMLEditor?: EditorView;
        sectionContent = JSON.parse(JSON.stringify(figureJson));
        figData: any;
        figNewComponents: any[] = [];

        figureComponentsInPrevew = [];
        rowOrder: number[] = [];
        displayPreviewComponents = false;
        figureCanvasData: any;

        columns = [1, 2, 3, 4];
        columnsFormControl = new UntypedFormControl();
        columnWidth: number;

        showPdfView = false;
        figureRows: any[][] = [];
        maxImgHeightPers;
        rowTemplate: any[] = [];
        maxImgWidthPers?: number;
        bottomOffset = 0.3;
        urlMapping: any = {};

        articleSectionsStructure: string[] = self.ydocService.articleStructureMap.get(
          'articleSectionsStructureFlat'
        );
        galleryImageArray: GalleryImageData[] = [];

        initFunc = self.initFunc;
        renderCodemMirrorEditors = self.renderCodemMirrorEditors;
        renderProsemirrorEditor = self.renderProsemirrorEditor;
        editComponent = self.editComponent(this);
        addComponent = self.addComponent(this);
        addFromGallery = self.addFromGallery(this);
        deleteComponent = self.deleteComponent(this);
        dragEntered = self.dragEntered(this);
        submitFigure = self.submitFigure(this);
        getMappedComponentsForPreviw = self.getMappedComponentsForPreviw;
        updatePreview = self.updatePreview;
        openFigurePdfPreview = self.openFigurePdfPreview(this);
        getMaterialsSectionData = self.getMaterialsSectionData(this);
        getImageGalleryData = self.getImageGalleryData(this);
        createImageData = self.createImageData(this);
        trimAndRemoveSemicolon = self.trimAndRemoveSemicolon(this);

        ngAfterViewInit(): void {
          this.initFunc(this)();
          const materialsSectionData = this.getMaterialsSectionData(this.articleSectionsStructure);
          materialsSectionData.forEach((item) => {
            this.getImageGalleryData(item);
          });
        }

        isValid: boolean = false;
        formIoSubmission: any;
        formIoRoot: any;
      }
    );

    this.component = this.container.createComponent(component);
  }

  ngOnDestroy(): void {
    this.component?.destroy();
    this.container?.clear();
  }
}
