import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss'],
})
export class ImageLoaderComponent implements OnInit, OnChanges {
  @Input() imageSrc: any;
  @Input() imageAlt: string;
  @Input() style: string;

  @Output() loadedImageEmitter = new EventEmitter<any>();

  isImageLoading = true;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.imageLoader(this.imageSrc)
      .then(() => (this.isImageLoading = false))
      .catch((err) => console.error(err));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imageSrc && this.isImageLoading) {
      this.imageLoader(this.imageSrc)
        .then(() => (this.isImageLoading = false))
        .catch((err) => console.error(err));
    }
  }

  imageLoader = async (imageSrc: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.src = this.sanitizer.sanitize(SecurityContext.URL, imageSrc) || '';
      if (img.complete) {
        this.loadedImageEmitter.emit(true);
        resolve();
        return;
      }
      this.isImageLoading = true;
      console.log('img.complete', img.complete);

      img.onload = () => {
        this.loadedImageEmitter.emit(true);
        resolve();
      };
      img.onerror = () => {
        this.loadedImageEmitter.emit(false);
        reject({ message: 'Failed to load image!' });
      };
    });
  };
}

@NgModule({
  declarations: [ImageLoaderComponent],
  imports: [CommonModule],
  exports: [ImageLoaderComponent],
})
export class ImageLoaderModule {}
