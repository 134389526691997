import { genericAttributtesToDom, getGenericAttributes, parseGenericAttributes } from '../helpers';

export const reference_citation = {
  group: 'inline',
  inline: true,
  inclusive: false,
  attrs: {
    ...getGenericAttributes(),
    refCitationID: { default: '' },
    citedRefsIds: { default: [] },
    nonexistingelement: { default: 'false' },
    citedRefsCiTOs: { default: [] },
    tooltip: { default: '' },
  },
  parseDOM: [
    {
      tag: 'reference-citation',
      getAttrs(dom: any) {
        return {
          ...parseGenericAttributes(dom),
          nonexistingelement: dom.getAttribute('nonexistingelement'),
          refCitationID: dom.getAttribute('refCitationID'),
          citedRefsIds: dom.getAttribute('citedRefsIds')
            ? dom.getAttribute('citedRefsIds').split(',')
            : [],
          citedRefsCiTOs: dom.getAttribute('citedRefsCiTOs')
            ? dom.getAttribute('citedRefsCiTOs').split(',')
            : [],
          contenteditableNode: 'false',
          tooltip: dom.getAttribute('tooltip') || '',
        };
      },
    },
  ],
  toDOM(node: any) {
    let attributesToDom: any = {
      ...genericAttributtesToDom(node),
      refCitationID: node.attrs.refCitationID,
      nonexistingelement: node.attrs.nonexistingelement,
      citedRefsIds: node.attrs.citedRefsIds.join(','),
      citedRefsCiTOs: node.attrs.citedRefsCiTOs.join(','),
      contenteditableNode: 'false',
      tooltip: node.attrs.tooltip || '',
    };
    return ['reference-citation', attributesToDom, 0];
  },
};

export const reference_container = {
  content: 'block*',
  group: 'block',
  attrs: {
    ...getGenericAttributes({ contenteditableNode: { default: false } }),
  },
  parseDOM: [
    {
      tag: 'ul.reference-container',
      getAttrs(dom: any) {
        return {
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: any) {
    let attributesToDom: any = {
      class: 'reference-container',
      ...genericAttributtesToDom(node),
    };
    return ['ul', attributesToDom, 0];
  },
};

export const reference_block_container = {
  content: 'block*',
  group: 'block',
  attrs: {
    ...getGenericAttributes({ contenteditableNode: { default: false } }),
  },
  parseDOM: [
    {
      tag: 'li.reference-block-container',
      getAttrs(dom: any) {
        return {
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: any) {
    let attributesToDom: any = {
      class: 'reference-block-container',
      ...genericAttributtesToDom(node),
    };
    return ['li', attributesToDom, 0];
  },
};

export const reference_citation_end = {
  content: 'inline*',
  group: 'block',
  attrs: {
    class: { default: '' },
    ...getGenericAttributes({ contenteditableNode: { default: false } }),
    refInstance: { default: 'local' },
    refCitationID: { default: '' },
    referenceData: { default: '' },
    referenceStyle: { default: '' },
    referenceType: { default: '' },
  },
  parseDOM: [
    {
      tag: 'reference-citation-end',
      getAttrs(dom: any) {
        let refData = dom.getAttribute('referencedata').split('|!|');
        let refStyle = dom.getAttribute('referencestyle').split('|!|');
        let refType = dom.getAttribute('referencetype').split('|!|');
        let className = dom.getAttribute('class');

        let referenceData = { refId: refData[0], last_modified: refData[1] };
        let referenceStyle = { name: refStyle[0], last_modified: refStyle[1] };
        let referenceType = { name: refType[0], last_modified: refType[1] };
        return {
          class: className,
          ...parseGenericAttributes(dom),
          refCitationID: dom.getAttribute('refCitationID'),
          referenceData,
          referenceStyle,
          referenceType,
        };
      },
    },
  ],
  toDOM(node: any) {
    let referenceData =
      node.attrs.referenceData.refId + '|!|' + node.attrs.referenceData.last_modified;
    let referenceStyle =
      node.attrs.referenceStyle.name + '|!|' + node.attrs.referenceStyle.last_modified;
    let referenceType =
      node.attrs.referenceType.name + '|!|' + node.attrs.referenceType.last_modified;

    let attributesToDom: any = {
      class: node.attrs.class,
      ...genericAttributtesToDom(node),
      refCitationID: node.attrs.refCitationID,
      referenceData,
      referenceStyle,
      referenceType,
    };
    return ['reference-citation-end', attributesToDom, 0];
  },
};
