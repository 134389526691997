import { InjectionToken } from '@angular/core';

export class AppConfig {
  production: boolean;
  standalone: boolean;
  websocketHost: string;
  externalRefsApi: string;
  websocketPort: string;
  authUrl: string;
  apiUrl: string;
  // eslint-disable-next-line spellcheck/spell-checker
  pkceClientId: string;
  pgClientId: string;
  pgClientSecret: string;
  validateJats: string;
  eventDispatcherService: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CDNService: string;
  authService: string;
  apiGatewayService: string;
  articleApiVersion: string;
  taxonSearch: string;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
