export const endNoteJSON = {
  components: [
    {
      label: 'Endnote',
      autoExpand: false,
      tableView: true,
      validate: {
        required: true,
      },
      autofocus: true,
      key: 'endNote',
      type: 'textarea',
      input: true,
    },
  ],
};
