import { Component, OnInit, Renderer2 } from '@angular/core';
import { ServiceShare } from '../services/service-share.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-custom-snackbar',
  template: `
    <div class="custom-snackbar-container">
      <div class="snackbar-header">
        <span class="message">{{ data.message }}</span>
        <button class="close-btn" (click)="closeSnackbar()">X</button>
      </div>
      <button mat-button class="refresh-btn" (click)="refreshPage()">
        Refresh page
      </button>
    </div>
  `,
  styles: [
    `
      .custom-snackbar-container {
        display: flex !important;
        flex-direction: column !important;
        padding: 4px !important;
        width: 360px !important;
        max-width: 100% !important;
      }

      .snackbar-header {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
      }

      .emoji {
        font-size: 24px !important;
        margin-right: 8px !important;
      }

      .message {
        font-size: 16px !important;
        font-weight: 600 !important;
        flex-grow: 1 !important;
      }

      .close-btn {
        background: none !important;
        border: none !important;
        font-size: 24px !important;
        color: #fff !important;
        cursor: pointer !important;
      }

      .close-btn:hover {
        background-color: rgba(25, 118, 210, 0.1) !important;
      }

      .refresh-btn {
        border-radius: 8px !important;
        font-size: 14px !important;
        font-weight: bold !important;
        color: #1976d2 !important;
        padding: 10px 0 !important;
        margin-top: 16px !important;
        width: 100% !important;
      }

      .refresh-btn:hover {
        background-color: rgba(25, 118, 210, 0.1) !important;
      }
    `,
  ],
})
export class CustomSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }

  refreshPage(): void {
    (this.data.updates as SwUpdate).activateUpdate().then(() => {
      document.location.reload();
      this.snackBarRef.dismiss();
    });
  }
}

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
})
export class MaintenancePageComponent implements OnInit {
  template: SafeHtml | null = null;

  constructor(
    public serviceShare: ServiceShare,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const rawTemplate = this.serviceShare.YdocService.ydoc
      .getMap('maintenance-page')
      .get('template') as string;

    const css = this.serviceShare.YdocService.ydoc.getMap('maintenance-page').get('css') as string;

    if (rawTemplate) {
      this.template = this.sanitizer.bypassSecurityTrustHtml(rawTemplate);
    }
    if (css) {
      this.injectCSS(css);
    } else {
      this.injectCSS(`
        .maintenance-page {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Roboto", sans-serif;
          margin-top: 120px;
        }

        .maintenance-wrapper {
          text-align: center;
          background: white;
          padding: 40px;
          border-radius: 12px;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
          max-width: 500px;
          height: 315px;

          .headline {
            font-size: 2.2rem;
            font-weight: bold;
            color: #2c3e50;
            margin-bottom: 20px;
          }

          .description {
            font-size: 1.2rem;
            color: #7f8c8d;
            margin-bottom: 30px;
          }

          .icon-container {
            margin: 20px 0;

            .emoji {
              font-size: 5rem;
            }
          }

          .footer-message {
            font-size: 1rem;
            color: #95a5a6;
            margin-top: 20px;
          }
        }`);
    }
  }

  injectCSS(cssContent: string): void {
    const styleElement = this.renderer.createElement('style');
    styleElement.type = 'text/css';
    styleElement.innerHTML = cssContent;
    this.renderer.appendChild(document.head, styleElement);
  }
}
