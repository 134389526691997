import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';

import { AuthService } from '@core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  // The replay subject will emit the last value that has been passed in
  subject = new ReplaySubject<any>(1);

  constructor(
    public router: Router,
    private _authservice: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const logged = this._authservice.isLoggedIn();

    if (logged) {
      this.router.navigate(['dashboard']);
    }
    return true;
  }
}
