import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';

import { figure as figure_interface } from 'src/app/editor/utils/interfaces/figureComponent';

@Component({
  selector: 'app-figure',
  templateUrl: './figure.component.html',
  styleUrls: ['./figure.component.scss'],
})
export class FigureComponent {
  @Input() figure?: figure_interface;
  @Output() figureChange = new EventEmitter<figure_interface>();
  @Input() figureIndex?: number;

  urlSafe?: SafeResourceUrl;
  constructor(
    public sanitizer: DomSanitizer,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  getResizedImg(url: string, num: number) {
    return url.includes(this.config.CDNService)
      ? this.sanitizer.bypassSecurityTrustResourceUrl(url + '/fit_crop/' + num + 'x' + num)
      : url;
  }
}
