export const materials = {
  id: 9944,
  sectionID: 'db5d1a7a-202c-4a11-9d58-692ad2f29944',
  name: '[MM] Materials',
  label: 'Materials',
  edit: { active: true, main: true },
  add: { active: false, main: false },
  delete: { active: false, main: false },
  select: { active: true, main: true },
  schema: {
    components: [],
  },
  template: `
  <h3 style="padding-left: 20px;font-weight: bold;">
\t<p style="font-weight: bold;">Materials&nbsp;&nbsp;&nbsp;<a href="/download" download="export.csv">Download as CSV</a>
</p>
</h3>
  `,
  type: 1,
  sections: [],
  version_id: 473,
  version: 3,
  customSection: true,
  version_pre_defined: false,
  version_date: '2022-05-03T04:12:10.000000Z',
  complex_section_settings: [
    {
      min_instances: 1,
      max_instances: 1000,
      label: 'Material',
      version_id: 325,
      index: 0,
    },
  ],
  settings: null,
  compatibility: {
    allow: {
      all: false,
      values: [44],
    },
    deny: {
      all: false,
      values: [],
    },
  },
  created_at: '2022-05-02T21:22:05.000000Z',
};
