import { Inject, Injectable } from '@angular/core';
//@ts-ignore
import * as Y from 'yjs';
import { IndexeddbPersistence } from 'y-indexeddb';
import * as awarenessProtocol from 'y-protocols/awareness.js';
import { HttpClient } from '@angular/common/http';
import { fromEvent, Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { WebsocketProvider } from '../../http-web-worker-client/WebsocketProvider';
import { YdocData } from '../utils/interfaces/ydocData';
import {
  ArticleSection,
  basicArticleSection,
  editorData,
  taxonomicCoverageContentData,
} from '../utils/interfaces/articleSection';
import { ServiceShare } from './service-share.service';
import { ArticlesService } from '@app/core/services/articles.service';
import {
  mapSchemaDef,
  parseSecFormIOJSONMenuAndSchemaDefs,
  parseSecHTMLMenuAndSchemaDefs,
} from '../utils/fieldsMenusAndScemasFns';
import { APP_CONFIG, AppConfig } from '@core/services/app-config';
import { hashCode, intToRGB, isColorDark } from '../../y-prosemirror-src/plugins/sync-plugin';
import { renderSectionFunc } from '../utils/articleBasicStructure';
import { roleMapping } from '@app/core/services/all-users.service';
import { IArticleVersion } from '../utils/interfaces/articleVersion.interface';
import { CONSTANTS } from '@app/core/services/constants';
import { AuthService } from '@app/core/services/auth.service';
import { ArticleCollaborator, Collaborator } from '@app/core/models/article.models';
import { YdocCommentThread } from '../comments-section/comment.models';
import { Role } from '@app/core/models/user.model';
import { VersionChange } from '@app/core/models/version.models';

export interface mainSectionValidations {
  [pivot_id: string]: { min: number; max: number };
}

@Injectable({
  providedIn: 'root',
})
export class YdocService {
  ydocStateObservable: Subject<any> = new Subject<any>();
  allowedVersionsStateSubject: Subject<any> = new Subject<any>();

  returnToNewestVersion: () => void;

  editorIsBuild = false;
  doNotRenderEndEditor = false;

  ydoc = new Y.Doc({ gc: false });
  //gcFilter: (item: Y.Item) => {
  //   const articleSectionsStructureFlat = this.ydoc.getMap("articleStructure").get('articleSectionsStructureFlat') as string[];
  //   const deletedSections = this.ydoc.getArray("deletedSections")?.toArray();
  //   if(item?.parent instanceof Y.XmlElement ||
  //     item?.parent instanceof Y.XmlFragment ||
  //     item?.parent instanceof  Y.XmlText ||
  //     //@ts-ignore
  //     (item?.parent instanceof Y.AbstractType && item?.parent._first?.content.type instanceof Y.XmlElement) ||
  //     (item?.parent instanceof Y.AbstractType && item?.parent._start?.parent instanceof Y.XmlFragment) ||
  //     articleSectionsStructureFlat?.includes(item.parentSub) ||
  //     deletedSections?.includes(item.parentSub) ||
  //     item.parentSub == "taxonsDataObj" ||
  //     item.parentSub == "figuresTemplates" ||
  //     item.parentSub == "ArticleFiguresNumbers" ||
  //     item.parentSub == "collaborators" ||
  //     item.parentSub == "authorsList" ||
  //     item.parentSub == "ArticleFigures" ||
  //     item.parentSub == "ArticleTablesNumbers" ||
  //     item.parentSub == "tablesTemplates" ||
  //     item.parentSub == "ArticleTables" ||
  //     item.parentSub == "supplementaryFiles" ||
  //     item.parentSub == "supplementaryFilesTemplates" ||
  //     item.parentSub == "supplementaryFilesNumbers" ||
  //     item.parentSub == "endNotes" ||
  //     item.parentSub == "endNotesNumbers" ||
  //     item.parentSub == "endNotesTemplates" ||
  //     item.parentSub == "articleSectionsStructureFlat" ||
  //     item.parentSub == "refsAddedToArticle" ||
  //     item.parentSub == "articleSectionsStructure") {
  //     return false;
  //   }
  //   console.log('item.parentSub', item.parentSub);
  //   console.log('item', item.parent.constructor.name);
  //   console.log('item', item.parent);
  //   console.log(Object.getPrototypeOf(item.parent) === Y.XmlFragment.prototype);

  //   console.log('item', item);
  //   console.log("================================");
  //   return true;
  // }});
  versionDoc: Y.Doc | null;
  shouldReconnect = true;

  versionData:
    | {
        snapshot: Y.Snapshot;
        prevSnapshot: Y.Snapshot;
      }
    | undefined;
  lastSelectedVersion: number | undefined;
  versionSubject = new Subject<VersionChange>();
  shouldRestore = false;

  usersInArticle = [];

  citableElementsSections = [];

  //provider?: OriginalWebRtc;
  provider?: WebsocketProvider;
  roomName = 'webrtc-test3';
  providerIndexedDb?: IndexeddbPersistence;
  constructor(
    private http: HttpClient,
    private serviceShare: ServiceShare,
    private authService: AuthService,
    private articleService: ArticlesService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.serviceShare.shareSelf('YdocService', this);
  }
  articleVersions: Y.Array<IArticleVersion>;

  articleStructureFromBackend: ArticleSection[];
  articleStructureMap?: Y.Map<any>;
  articleSectionsMap: Y.Map<any>;
  articleData: any;
  sectionFormGroupsStructures?: Y.Map<any>;
  comments?: Y.Map<YdocCommentThread>;
  creatingANewArticle = false;
  figuresMap?: Y.Map<any>;
  citableElementsMap?: Y.Map<any>;
  tablesMap?: Y.Map<any>;
  supplementaryFilesMap?: Y.Map<any>;
  endNotesMap?: Y.Map<any>;
  trackChangesMetadata?: Y.Map<any>;
  usersDataMap?: Y.Map<any>;
  mathMap?: Y.Map<any>;
  referenceCitationsMap?: Y.Map<any>;
  printMap?: Y.Map<any>;
  customSectionProps?: Y.Map<any>;
  collaborators?: Y.Map<any>;
  PMMenusAndSchemasDefsMap?: Y.Map<any>;
  TaxonsMap?: Y.Map<any>;
  userInfo: any;
  getCommentsMap(): Y.Map<any> {
    return this.comments!;
  }

  getYDoc() {
    return this.versionDoc ? this.versionDoc : this.ydoc;
  }

  getSectionByID(sectionId: string): ArticleSection {
    return this.articleSectionsMap.get(sectionId);
  }

  applySectionChange(value: {
    contentData: editorData | string | editorData | taxonomicCoverageContentData;
    sectionData: ArticleSection;
    type: string;
  }) {
    const articleSectionsStructure = this.articleStructureMap.get(
      'articleSectionsStructure'
    ) as basicArticleSection[];
    const nodeRef = this.articleSectionsMap.get(value.sectionData.sectionID) as ArticleSection;
    nodeRef![value.type].contentData = value.contentData;

    const articleSectionsStructureFlat: basicArticleSection[] = [];
    const makeFlat = (structure: basicArticleSection[]) => {
      structure.forEach((section) => {
        if (section.active) {
          articleSectionsStructureFlat.push(section);
        }
        if (section.children.length > 0) {
          makeFlat(section.children);
        }
      });
    };
    makeFlat(articleSectionsStructure);
    this.articleStructureMap.set('articleSectionsStructure', articleSectionsStructure);
    this.articleStructureMap.set('articleSectionsStructureFlat', articleSectionsStructureFlat);
  }

  saveSectionMenusAndSchemasDefs(sectionStructure: basicArticleSection[]) {
    const menusAndSchemasDefs = this.PMMenusAndSchemasDefsMap?.get('menusAndSchemasDefs');
    const loopSection = (section: basicArticleSection, fn: any) => {
      if (section.children && section.children.length > 0) {
        section.children.forEach((child) => {
          loopSection(child, fn);
        });
      }
      const articleSection = this.articleSectionsMap.get(section.sectionID);

      fn(articleSection);
    };
    sectionStructure.forEach((section) =>
      loopSection(section, (section: ArticleSection) => {
        if (
          section.menusAndSchemasDefs &&
          (section.menusAndSchemasDefs.menus || section.menusAndSchemasDefs.schemas) &&
          (Object.keys(section.menusAndSchemasDefs.menus).length > 0 ||
            Object.keys(section.menusAndSchemasDefs.schemas).length > 0)
        ) {
          menusAndSchemasDefs[section.sectionID] = section.menusAndSchemasDefs;
        }
      })
    );

    if (
      this.serviceShare.compareObjects(
        this.PMMenusAndSchemasDefsMap.get('menusAndSchemasDefs'),
        menusAndSchemasDefs
      )
    ) {
      this.PMMenusAndSchemasDefsMap.set('menusAndSchemasDefs', menusAndSchemasDefs);
    }
  }

  getData(): YdocData {
    let articleSectionsStructure: basicArticleSection[] = this.articleStructureMap.get(
      'articleSectionsStructure'
    );
    let articleSectionsStructureFlat: string[] = this.articleStructureMap.get(
      'articleSectionsStructureFlat'
    );
    let citatsObj = this.figuresMap!.get('articleCitatsObj');
    let tableCitatsObj = this.tablesMap!.get('tableCitatsObj');
    try {
      if (articleSectionsStructure == undefined) {
        citatsObj = {};
        tableCitatsObj = {};
        articleSectionsStructureFlat = [];

        articleSectionsStructure = [];

        const makeFlat = (structure: ArticleSection[], parent?: basicArticleSection) => {
          structure?.forEach((section) => {
            this.articleSectionsMap.set(section.sectionID, section);
            const basicSection: basicArticleSection = {
              sectionID: section.sectionID,
              label: section.title.label,
              name: section.title.name,
              children: [],
              active: section.active,
              subsectionValidations: section.subsectionValidations,
              pivotId: section.pivotId,
              id: section.id,
              sectionTypeID: section.sectionTypeID,
            };
            if (section.active) {
              articleSectionsStructureFlat.push(section.sectionID);
            }
            if (parent) {
              parent.children.push(basicSection);
            } else {
              articleSectionsStructure.push(basicSection);
            }
            if (section.children.length > 0) {
              makeFlat(section.children, basicSection);
            }
          });
        };
        makeFlat(this.articleStructureFromBackend);

        this.saveSectionMenusAndSchemasDefs(articleSectionsStructure);
        this.articleStructureMap.set('articleSectionsStructure', articleSectionsStructure);
        this.articleStructureMap.set('articleSectionsStructureFlat', articleSectionsStructureFlat);
      }
      if (!citatsObj) {
        citatsObj = {};
        articleSectionsStructureFlat.forEach((sectionID) => {
          citatsObj[sectionID] = {}; // citats obj [key:string](citateID):{citatedFigures:[](citated figures-Ids),posiition:number(citatePosition)}
        });
        this.figuresMap!.set('articleCitatsObj', citatsObj);
      }
      if (!tableCitatsObj) {
        tableCitatsObj = {};
        articleSectionsStructureFlat.forEach((sectionID) => {
          tableCitatsObj[sectionID] = {}; // citats obj [key:string](citateID):{citatedFigures:[](citated figures-Ids),posiition:number(citatePosition)}
        });
        this.tablesMap!.set('tableCitatsObj', tableCitatsObj);
      }
    } catch (e) {
      console.error(e);
    }

    return {
      ydoc: this.ydoc,
      versionDoc: this.versionDoc,
      version: this.lastSelectedVersion,
      versionData: this.versionData,
      provider: this.provider,
      userInfo: this.userInfo,
      providerIndexedDb: this.providerIndexedDb!,
      articleSectionsStructure: articleSectionsStructure,
    };
  }

  turnOnOffPreviewModeEditorFn: () => void;

  buildLayoutMenusAndSchemasDefs(defs: { menus: {}; schemas: {} }) {
    const layoutMapedDefs = { menus: {}, schemas: {} };
    if (defs && (defs.menus || defs.schemas)) {
      if (defs.menus) {
        Object.keys(defs.menus).forEach((menuKey) => {
          layoutMapedDefs.menus[menuKey] = defs.menus[menuKey];
        });
      }
      if (defs.schemas) {
        Object.keys(defs.schemas).forEach((schemaKey) => {
          layoutMapedDefs.schemas[schemaKey] = mapSchemaDef(defs.schemas[schemaKey]);
        });
      }
    }
    return { layoutDefinitions: layoutMapedDefs };
  }

  buildEditor(ydoc: Y.Doc, oldVersion?: number | string) {
    let YDoc: Y.Doc;
    this.articleVersions = this.ydoc.getArray('versions');
    const versions = Array.from(this.articleVersions) as IArticleVersion[];
    let onlyAllowedVersions = [];
    let shouldReconect = false;

    if (this.currUser) {
      if (
        this.currUser.allowed_article_versions?.includes(CONSTANTS.OWN) &&
        this.currUser.allowed_article_versions.length == 1 &&
        !this.serviceShare.oldVersion
      ) {
        shouldReconect = true;
      }

      onlyAllowedVersions = this.currUser.allowed_article_versions
        .filter((v: string) => v != CONSTANTS.LATEST && v != CONSTANTS.OWN)
        .map((v: string) => +v);

      if (this.currUser.allowed_article_versions?.includes(CONSTANTS.OWN)) {
        let minVersion = onlyAllowedVersions.length
          ? Math.min(...onlyAllowedVersions.map((v: string) => +v))
          : undefined;
        const userId = this.currUser.user_id || this.currUser.id;

        versions.forEach((version, index: number) => {
          if (index > 0) {
            if (minVersion) {
              if (minVersion <= index && !onlyAllowedVersions.includes(index)) {
                onlyAllowedVersions.push(index);
                this.currUser.allowed_article_versions.push(index);
                minVersion = undefined;
              }
            } else {
              if (
                version.users.find((u) => u.id == userId) &&
                !onlyAllowedVersions.includes(index)
              ) {
                onlyAllowedVersions.push(index);
                this.currUser.allowed_article_versions.push(index);
              }
            }
          }
        });
      }

      if (shouldReconect) {
        const version = Math.max(
          ...this.currUser.allowed_article_versions.map((v) => +v).filter((v) => !isNaN(v))
        );

        if (version) {
          this.versionSubject.next(VersionChange.reRender);
          this.serviceShare.router.navigate([this.roomName], {
            fragment: `${version}`,
          });

          this.provider.destroy();
          return;
        }
      }
    }

    if (oldVersion && typeof oldVersion == 'number') {
      let version: any;
      let version2: any;

      if (versions.length == oldVersion) {
        version = { snapshot: Y.encodeSnapshot(Y.snapshot(this.ydoc)) };
        version2 = versions[oldVersion - 1];
      } else {
        version = versions[oldVersion];
        if (version.restored) {
          version2 = versions[0];
        } else {
          version2 = versions[oldVersion - 1];
        }
      }
      if (version) {
        let canMakeDiff = true;

        if (onlyAllowedVersions?.length) {
          const sortedVersions = onlyAllowedVersions.sort(
            (a: number, b: number) => a - b
          ) as number[];
          const index = sortedVersions.findIndex((v) => v == oldVersion);
          if (index != 0) {
            version2 = versions[sortedVersions[index - 1]];
          } else {
            canMakeDiff = false;
          }
        }

        this.lastSelectedVersion = oldVersion as number;
        this.ydoc.gc = false;
        this.versionDoc = ydoc;
        const snapshot = Y.snapshot(ydoc);
        YDoc = ydoc;
        this.versionData = {
          snapshot,
          prevSnapshot: version2
            ? canMakeDiff && !this.serviceShare.shouldSeeOnlyVersionPreview
              ? Y.decodeSnapshot(version2.snapshot)
              : snapshot
            : Y.emptySnapshot,
        };
      } else {
        YDoc = ydoc;
      }
      this.provider.destroy();
      const prevSnapDoc = Y.createDocFromSnapshot(
        ydoc,
        Y.decodeSnapshot(version2.snapshot),
        new Y.Doc({ gc: false })
      );
      this.ydoc
        .getMap('articleStructure')
        .set(
          'articleSectionsStructure',
          prevSnapDoc.getMap('articleStructure').get('articleSectionsStructure')
        );
      this.ydoc
        .getMap('articleStructure')
        .set(
          'articleSectionsStructureFlat',
          prevSnapDoc.getMap('articleStructure').get('articleSectionsStructureFlat')
        );
    } else {
      if (this.shouldRestore) {
        this.articleVersions = this.ydoc.getArray('versions');
        YDoc = this.ydoc;
        this.lastSelectedVersion = undefined;
      } else {
        YDoc = ydoc;
        this.articleVersions = this.ydoc.getArray('versions');
      }
    }

    this.sectionFormGroupsStructures = YDoc.getMap('sectionFormGroupsStructures');
    this.citableElementsMap = YDoc.getMap('citableElementsMap');

    this.figuresMap = YDoc.getMap('ArticleFiguresMap');
    this.tablesMap = YDoc.getMap('ArticleTablesMap');
    this.supplementaryFilesMap = YDoc.getMap('supplementaryFilesMap');
    this.endNotesMap = YDoc.getMap('endNotesMap');

    const figuresNumbers = this.figuresMap!.get('ArticleFiguresNumbers');
    const figuresTemplates = this.figuresMap!.get('figuresTemplates');
    const figures = this.figuresMap!.get('ArticleFigures');
    const figuresModalTemplate = this.figuresMap!.get('FiguresModalTemplate');

    const tablesNumbers = this.tablesMap!.get('ArticleTablesNumbers');
    const tablesTemplates = this.tablesMap!.get('tablesTemplates');
    const tablesInitialTemplate = this.tablesMap!.get('tablesInitialTemplate');
    const tablesInitialFormIOJson = this.tablesMap!.get('tablesInitialFormIOJson');
    const tablesModalTemplate = this.tablesMap.get('tablesModalTemplate');
    const tables = this.tablesMap!.get('ArticleTables');

    const supplementaryFiles = this.supplementaryFilesMap.get('supplementaryFiles');
    const supplementaryFilesTemplates = this.supplementaryFilesMap.get(
      'supplementaryFilesTemplates'
    );
    const supplementaryFilesInitialTemplate = this.supplementaryFilesMap!.get(
      'supplementaryFilesInitialTemplate'
    );
    const supplementaryFilesInitialFormIOJson = this.supplementaryFilesMap!.get(
      'supplementaryFilesInitialFormIOJson'
    );
    const supplementaryFilesNumbers = this.supplementaryFilesMap.get('supplementaryFilesNumbers');
    const supplementaryFilesModalTemplate = this.supplementaryFilesMap.get(
      'supplementaryFilesModalTemplate'
    );

    const endNotes = this.endNotesMap.get('endNotes');
    const endNotesNumbers = this.endNotesMap.get('endNotesNumbers');
    const endNotesInitialTemplate = this.endNotesMap!.get('endNotesInitialTemplate');
    const endNotesInitialFormIOJson = this.endNotesMap!.get('endNotesInitialFormIOJson');
    const endNotesTemplates = this.endNotesMap.get('endNotesTemplates');
    const endNotesModalTemplate = this.endNotesMap.get('endNotesModalTemplate');
    const endNotesCitations = this.endNotesMap.get('endNotesCitations');

    this.usersDataMap = YDoc.getMap('userDataMap');
    this.mathMap = YDoc.getMap('mathDataURLMap');
    this.printMap = YDoc.getMap('print');
    this.customSectionProps = YDoc.getMap('customSectionProps');
    const pdfSettings = this.printMap.get('pdfPrintSettings');
    const mathObj = this.mathMap.get('dataURLObj');
    const usersColors = this.usersDataMap.get('usersColors');
    this.referenceCitationsMap = YDoc.getMap('referenceCitationsMap');
    if (oldVersion) {
      this.referenceCitationsMap?.set('referencesInEditor', {});
    }
    const references = this.referenceCitationsMap?.get('references');
    const referencesInEditor = this.referenceCitationsMap?.get('referencesInEditor');
    const externalRefs = this.referenceCitationsMap?.get('externalRefs');
    const localRefs = this.referenceCitationsMap?.get('localRefs');
    const refsAddedToArticle = this.referenceCitationsMap?.get('refsAddedToArticle');
    const citedRefsInArticle = this.referenceCitationsMap.get('citedRefsInArticle');
    const referencesModalTemplate = this.referenceCitationsMap.get('referencesModalTemplate');
    const referenceCitations = this.referenceCitationsMap.get('referenceCitations');

    const customPropsObj = this.customSectionProps?.get('customPropsObj');
    const elementsCitations = this.citableElementsMap?.get('elementsCitations');

    this.PMMenusAndSchemasDefsMap = YDoc.getMap('PMMenusAndSchemasDefsMap');
    const menusAndSchemasDefs = this.PMMenusAndSchemasDefsMap?.get('menusAndSchemasDefs');

    this.TaxonsMap = YDoc.getMap('TaxonsMap');
    const taxonsDataObj = this.TaxonsMap.get('taxonsDataObj');

    if (!taxonsDataObj) {
      this.TaxonsMap.set('taxonsDataObj', {});
    }

    const citableElementMenusAndSchemaDefs: any = {};
    const allCitableElementsMenus = {};
    const allCitableElementsSchemas = {};
    const allCitableElementsDefsByTags = {};
    if (this.citableElementsSchemasSection) {
      //
      const tablesInitialTemplateRegex =
        /<ng-template #Tables>([\s\S]+?(?=<\/ng-template>))<\/ng-template>/gm;
      const supplementaryFilesInitialTemplateRegex =
        /<ng-template #SupplementaryMaterials>([\s\S]+?(?=<\/ng-template>))<\/ng-template>/gm;
      const endNotesInitialTemplateRegex =
        /<ng-template #Footnotes>([\s\S]+?(?=<\/ng-template>))<\/ng-template>/gm;
      const citableElementsSchemasHtmlTemplate = this.citableElementsSchemasSection.template;
      // modal templates regex
      const endNotesModalTemplateRegex =
        /<ng-template #FootnotesModalTemplate>([\s\S]+?(?=<\/ng-template>))<\/ng-template>/gm;
      const tablesModalTemplateRegex =
        /<ng-template #TablesModalTemplate>([\s\S]+?(?=<\/ng-template>))<\/ng-template>/gm;
      const supplementaryFilesModalTemplateRegex =
        /<ng-template #SupplementaryFilesModalTemplate>([\s\S]+?(?=<\/ng-template>))<\/ng-template>/gm;
      const referencesModalTemplateRegex =
        /<ng-template #ReferenceModalTemplate>([\s\S]+?(?=<\/ng-template>))<\/ng-template>/gm; //FiguresModalTemplate
      const figuresModalTemplateRegex =
        /<ng-template #FiguresModalTemplate>([\s\S]+?(?=<\/ng-template>))<\/ng-template>/gm; //FiguresModalTemplate

      const referencesModalTemplateResult = referencesModalTemplateRegex.exec(
        citableElementsSchemasHtmlTemplate
      );
      const endNotesModalTemplateResult = endNotesModalTemplateRegex.exec(
        citableElementsSchemasHtmlTemplate
      );
      const tablesModalTemplateResult = tablesModalTemplateRegex.exec(
        citableElementsSchemasHtmlTemplate
      );
      const supplementaryFilesModalTemplateResult = supplementaryFilesModalTemplateRegex.exec(
        citableElementsSchemasHtmlTemplate
      );
      const FiguresModalTemplateResult = figuresModalTemplateRegex.exec(
        citableElementsSchemasHtmlTemplate
      );

      if (FiguresModalTemplateResult && !figuresModalTemplate) {
        this.figuresMap.set('FiguresModalTemplate', FiguresModalTemplateResult[1]);
      }

      if (referencesModalTemplateResult && !referencesModalTemplate) {
        this.referenceCitationsMap.set('referencesModalTemplate', referencesModalTemplateResult[1]);
      }

      if (endNotesModalTemplateResult && !endNotesModalTemplate) {
        this.endNotesMap.set('endNotesModalTemplate', endNotesModalTemplateResult[1]);
      }

      if (tablesModalTemplateResult && !tablesModalTemplate) {
        this.tablesMap.set('tablesModalTemplate', tablesModalTemplateResult[1]);
      }

      if (supplementaryFilesModalTemplateResult && !supplementaryFilesModalTemplate) {
        this.supplementaryFilesMap.set(
          'supplementaryFilesModalTemplate',
          supplementaryFilesModalTemplateResult[1]
        );
      }

      const tablesSchemaResult = tablesInitialTemplateRegex.exec(
        citableElementsSchemasHtmlTemplate
      );
      const supplementaryFilesSchemaResult = supplementaryFilesInitialTemplateRegex.exec(
        citableElementsSchemasHtmlTemplate
      );
      const endNotesSchemaResult = endNotesInitialTemplateRegex.exec(
        citableElementsSchemasHtmlTemplate
      );

      const formIOSchemas = this.citableElementsSchemasSection.schema.override.categories;

      const tablesFormIoJson = formIOSchemas.Tables;
      const supplementaryFilesFormIoJson = formIOSchemas.SupplementaryMaterials;
      const endNotesFormIoJson = formIOSchemas.Footnotes;

      // extract citable element menutypes and allowed tags defs

      const parseCitableElementFormIODefs = (
        formIOJSON: any,
        labels: { menusL: string; tagsL: string },
        defsLable: string
      ) => {
        const result = parseSecFormIOJSONMenuAndSchemaDefs(formIOJSON, labels);
        citableElementMenusAndSchemaDefs[defsLable] = {
          sectionMenusAndSchemaDefsFromJSON: result.sectionMenusAndSchemaDefsFromJSON,
          sectionMenusAndSchemasDefsfromJSONByfieldsTags:
            result.sectionMenusAndSchemasDefsfromJSONByfieldsTags,
        };
        Object.assign(allCitableElementsMenus, result.sectionMenusAndSchemaDefsFromJSON.menus);
        Object.assign(allCitableElementsSchemas, result.sectionMenusAndSchemaDefsFromJSON.schemas);
        Object.assign(
          allCitableElementsDefsByTags,
          result.sectionMenusAndSchemasDefsfromJSONByfieldsTags
        );
        return result;
      };

      const parseCitableElementHTMLDefs = (
        html: string,
        labels: { menusL: string; tagsL: string },
        defsLable: string
      ) => {
        const result = parseSecHTMLMenuAndSchemaDefs(html, labels);
        citableElementMenusAndSchemaDefs[defsLable] = {
          sectionMenusAndSchemaHTMLDefs: result.sectionMenusAndSchemaHTMLDefs,
        };
        Object.assign(allCitableElementsMenus, result.sectionMenusAndSchemaHTMLDefs.menus);
        Object.assign(allCitableElementsSchemas, result.sectionMenusAndSchemaHTMLDefs.schemas);
        return result;
      };

      if (tablesSchemaResult && !tablesInitialTemplate) {
        const result = parseCitableElementHTMLDefs(
          tablesSchemaResult[1],
          {
            menusL: 'customTableHTMLMenuType',
            tagsL: 'customTableHTMLAllowedTags',
          },
          'tablesHTMLDefs'
        );
        this.tablesMap!.set('tablesInitialTemplate', result.sectionTemplate);
      }

      if (tablesFormIoJson && !tablesInitialFormIOJson) {
        const result = parseCitableElementFormIODefs(
          tablesFormIoJson,
          {
            menusL: 'customTableJSONMenuType',
            tagsL: 'customTableJSONAllowedTags',
          },
          'tablesFormIODefs'
        );
        this.tablesMap!.set('tablesInitialFormIOJson', result.formIOJSON);
      }

      if (supplementaryFilesSchemaResult && !supplementaryFilesInitialTemplate) {
        const result = parseCitableElementHTMLDefs(
          supplementaryFilesSchemaResult[1],
          {
            menusL: 'customSupplementaryFilesHTMLMenuType',
            tagsL: 'customSupplementaryFilesHTMLAllowedTags',
          },
          'supplementaryFilesHTMLDefs'
        );
        this.supplementaryFilesMap!.set(
          'supplementaryFilesInitialTemplate',
          result.sectionTemplate
        );
      }

      if (supplementaryFilesFormIoJson && !supplementaryFilesInitialFormIOJson) {
        const result = parseCitableElementFormIODefs(
          supplementaryFilesFormIoJson,
          {
            menusL: 'customSupplementaryFilesJSONMenuType',
            tagsL: 'customSupplementaryFilesJSONAllowedTags',
          },
          'supplementaryFilesFormIODefs'
        );
        this.supplementaryFilesMap!.set('supplementaryFilesInitialFormIOJson', result.formIOJSON);
      }

      if (endNotesSchemaResult && !endNotesInitialTemplate) {
        const result = parseCitableElementHTMLDefs(
          endNotesSchemaResult[1],
          {
            menusL: 'customEndNotesHTMLMenuType',
            tagsL: 'customEndNotesHTMLAllowedTags',
          },
          'endNotesHTMLDefs'
        );
        this.endNotesMap!.set('endNotesInitialTemplate', result.sectionTemplate);
      }

      if (endNotesFormIoJson && !endNotesInitialFormIOJson) {
        const result = parseCitableElementFormIODefs(
          endNotesFormIoJson,
          {
            menusL: 'customEndNotesJSONMenuType',
            tagsL: 'customEndNotesJSONAllowedTags',
          },
          'endNotesFormIODefs'
        );
        this.endNotesMap!.set('endNotesInitialFormIOJson', result.formIOJSON);
      }
    }

    citableElementMenusAndSchemaDefs.allCitableElementsMenus = allCitableElementsMenus;
    citableElementMenusAndSchemaDefs.allCitableElementsSchemas = allCitableElementsSchemas;
    citableElementMenusAndSchemaDefs.allCitableElementsDefsByTags = allCitableElementsDefsByTags;
    citableElementMenusAndSchemaDefs.allCitableElementFieldsKeys = Object.keys(
      allCitableElementsDefsByTags
    );
    if (!endNotesTemplates) {
      this.endNotesMap.set('endNotesTemplates', {});
    }
    if (!endNotesCitations) {
      this.endNotesMap.set('endNotesCitations', {});
    }
    if (!supplementaryFilesTemplates) {
      this.supplementaryFilesMap.set('supplementaryFilesTemplates', {});
    }
    if (!endNotes) {
      this.endNotesMap.set('endNotes', {});
    }
    if (!endNotesNumbers) {
      this.endNotesMap?.set('endNotesNumbers', []);
    }
    if (!refsAddedToArticle) {
      this.referenceCitationsMap.set('refsAddedToArticle', {});
    }
    if (!supplementaryFiles) {
      this.supplementaryFilesMap.set('supplementaryFiles', {});
    }
    if (!supplementaryFilesNumbers) {
      this.supplementaryFilesMap?.set('supplementaryFilesNumbers', []);
    }
    if (!menusAndSchemasDefs) {
      const layoutMenusAndAllowedTagsSettings: any = { menus: {}, schemas: {} };
      if (this.articleData.layout.settings) {
        const settings = this.articleData.layout.settings;
        if (settings.allowed_tags && Object.values(settings.allowed_tags).length > 0) {
          layoutMenusAndAllowedTagsSettings.schemas = settings.allowed_tags;
        }
        if (settings.menus && Object.values(settings.menus).length > 0) {
          layoutMenusAndAllowedTagsSettings.menus = settings.menus;
        }
      }
      this.PMMenusAndSchemasDefsMap.set('menusAndSchemasDefs', {
        citableElementMenusAndSchemaDefs,
        ...this.buildLayoutMenusAndSchemasDefs(layoutMenusAndAllowedTagsSettings),
      });
    }
    if (!elementsCitations) {
      this.citableElementsMap.set('elementsCitations', {});
    }
    if (!customPropsObj) {
      this.customSectionProps?.set('customPropsObj', {});
    }
    if (!localRefs) {
      this.referenceCitationsMap?.set('localRefs', {});
    }
    if (!externalRefs) {
      this.referenceCitationsMap?.set('externalRefs', {});
    }
    if (!references) {
      this.referenceCitationsMap?.set('references', {});
    }
    if (!referencesInEditor) {
      this.referenceCitationsMap?.set('referencesInEditor', {});
    }
    if (!citedRefsInArticle) {
      this.referenceCitationsMap.set('citedRefsInArticle', {});
    }
    if (!referenceCitations) {
      this.referenceCitationsMap.set('referenceCitations', {});
    }
    if (!usersColors) {
      this.usersDataMap.set('usersColors', {});
    }
    this.setUserColor(this.userInfo);

    const userAwarenessData = {
      access: this.userInfo.data.access,
      userColor: this.userInfo.color.userColor,
      userContrastColor: this.userInfo.color.userContrastColor,
      name: this.userInfo.data.name,
      email: this.userInfo.data.email,
      id: this.userInfo.data.id,
    };

    // if(this.userInfo.data.id == "99083e02-6e45-4f88-9315-0101b0be4e39") {
    // this.userInfo.data["hide_user_from_me"] = ["975c3b11-bbc8-447c-bbba-60c9d4b9ce7c"];
    // this.userInfo.data["hide_user_comments_from_me"] = ["96c57a87-3301-4ecf-8083-29a323b16b43", "975c3b11-bbc8-447c-bbba-60c9d4b9ce7c"];
    // userAwarenessData["hide_user_from_me"] = ["975c3b11-bbc8-447c-bbba-60c9d4b9ce7c"];
    // userAwarenessData["hide_user_comments_from_me"] = ["96c57a87-3301-4ecf-8083-29a323b16b43", "975c3b11-bbc8-447c-bbba-60c9d4b9ce7c"];
    // }

    this.provider?.awareness.setLocalStateField('userInfo', userAwarenessData);

    if (!pdfSettings) {
      const pdfPrintSettings =
        this.articleData &&
        this.articleData.layout &&
        this.articleData.layout.settings &&
        this.articleData.layout.settings.print_settings
          ? this.articleData.layout.settings.print_settings
          : {};
      this.printMap.set('pdfPrintSettings', pdfPrintSettings);
    }
    if (!figures) {
      this.figuresMap!.set('ArticleFigures', {});
    }
    if (!figuresTemplates) {
      this.figuresMap!.set('figuresTemplates', {});
    }
    if (!figuresNumbers) {
      this.figuresMap!.set('ArticleFiguresNumbers', []);
    }
    if (!tables) {
      this.tablesMap!.set('ArticleTables', {});
    }
    if (!tablesTemplates) {
      this.tablesMap!.set('tablesTemplates', {});
    }
    if (!tablesNumbers) {
      this.tablesMap!.set('ArticleTablesNumbers', []);
    }
    if (!mathObj) {
      this.mathMap!.set('dataURLObj', {});
    }

    this.articleStructureMap = YDoc.getMap('articleStructure');
    this.articleSectionsMap = YDoc.getMap('articleSections');
    this.trackChangesMetadata = YDoc.getMap('trackChangesMetadata');
    const trackChangesData = this.trackChangesMetadata?.get('trackChangesMetadata');
    if (!trackChangesData) {
      this.trackChangesMetadata?.set('trackChangesMetadata', {
        trackTransactions: false,
      });
    }
    this.comments = YDoc.getMap('comments');
    this.collaborators = YDoc.getMap('articleCollaborators');
    this.collaborators.observe(this.observeCollaboratorsFunc);
    this.checkIfUserIsInArticle(this.collaborators, true);
    if (this.shouldSetTheOwnerForTheNewArticle) {
      this.setArticleOwnerInfo();
    }
    const data = this.getData();

    setTimeout(() => {
      this.ydocStateObservable.next({ event: 'docIsBuild', data });
      if (this.shouldRestore) {
        setTimeout(() => {
          this.serviceShare.updateCitableElementsViewsAndCites({
            table: tables,
            figure: figures,
          });
        }, 100);
        this.shouldRestore = false;
      }
    }, 500);

    this.editorIsBuild = true;
  }

  curUserAccess: string;
  currCustomTypePermission: string;
  currUserRoleSubject = new Subject();
  currUser: ArticleCollaborator;
  checkIfUserIsInArticle(collaboratorsMap: Y.Map<any>, onInit?: boolean) {
    if (this.userInfo) {
      const userinfo = this.userInfo.data;
      const currentUserId = userinfo.id;
      if (this.shouldSetTheOwnerForTheNewArticle) {
        this.setArticleOwnerInfo();
      }

      if (onInit && this.articleData.collaborators.length) {
        const updatedCollaborators: Collaborator[] = this.articleData.collaborators
          .map((c: any) => {
            if (c.role != 'article_admin') {
              return {
                name: c.user_name,
                email: c.user_email,
                first_name: c.user_first_name,
                last_name: c.user_last_name,
                role: c.role,
                is_owner: c.is_owner,
                position: c.position,
                access: c.is_owner ? 'Owner' : roleMapping[c.role],
                affiliations: c.affiliations,
                type: c.type,
                role_label: c.role_label,
                auth_role: c.auth_role,
                settings: c.settings,
                hide_me_from_user: c.hide_me_from_user,
                hide_my_comments_from_user: c.hide_my_comments_from_user,
                allowed_article_versions: c.allowed_article_versions,
                is_co_author: c.is_co_author,
                id: c.user_id,
              };
            }
          })
          .filter((c: any) => c != undefined);

        const authorsList = updatedCollaborators
          .map((user: any) => {
            if (user.role == Role.author || user.role == Role.commenter) {
              if (user.id == null) {
                const authorId = user.email;
                return { authorId, authorEmail: user.email };
              } else {
                return { authorId: user.id, authorEmail: user.email };
              }
            }
          })
          .filter((u: any) => u != undefined);

        if (this.serviceShare.compareObjects(this.getAuthorsList(), authorsList)) {
          collaboratorsMap.set('authorsList', authorsList);
        }

        if (this.serviceShare.compareObjects(this.getCollaborators(), updatedCollaborators)) {
          collaboratorsMap?.set('collaborators', {
            collaborators: updatedCollaborators,
          });
        }
      }

      this.serviceShare.EnforcerService.enforceAsync('is-admin', 'admin-can-do-anything').subscribe(
        (admin) => {
          let userInArticle = this.getCollaborators().find((user) => user.id == currentUserId);
          const isCollaborator = !!userInArticle;

          const articleAdmin = this.articleData.collaborators.find(
            (c: any) => c.role == 'article_admin' && c.user_id == this.userInfo.data.id
          );

          if (admin) {
            if (userInArticle) {
              userInArticle = {
                access: 'Owner',
                email: userInArticle.email,
                role: Role.author,
                role_label: userInArticle.role_label,
                id: userInArticle.id,
                auth_role: userInArticle.auth_role,
                allowed_article_versions: userInArticle.allowed_article_versions,
                hide_my_comments_from_user: userInArticle.hide_my_comments_from_user,
                hide_me_from_user: userInArticle.hide_me_from_user,
                settings: userInArticle.settings,
                position: userInArticle.position,
                is_owner: userInArticle.is_owner,
              };
            } else {
              const updatedCollaborators = this.articleData.collaborators.find(
                (c: any) => c.role == 'article_admin' && c.user_id == this.userInfo.data.id
              );
              if (updatedCollaborators) {
                userInArticle = {
                  access: 'Owner',
                  email: updatedCollaborators.user_email,
                  first_name: updatedCollaborators.user_first_name,
                  last_name: updatedCollaborators.user_last_name,
                  role: updatedCollaborators.role,
                  role_label: updatedCollaborators.role_label,
                  id: updatedCollaborators.user_id,
                  auth_role: updatedCollaborators.auth_role,
                  allowed_article_versions: [],
                  hide_my_comments_from_user: [],
                  hide_me_from_user: [],
                  settings: {},
                  position: 0,
                  is_co_author: false,
                  is_owner: false,
                };
              }
            }
          } else if (articleAdmin) {
            userInArticle = {
              access: 'Owner',
              email: articleAdmin.user_email,
              first_name: articleAdmin.user_first_name,
              last_name: articleAdmin.user_last_name,
              role: articleAdmin.role,
              role_label: articleAdmin.role_label,
              id: articleAdmin.user_id,
              auth_role: articleAdmin.auth_role,
              allowed_article_versions: [],
              hide_my_comments_from_user: [],
              hide_me_from_user: [],
              settings: {},
              position: 0,
              is_co_author: false,
              is_owner: false,
            };
          }
          if (!userInArticle) {
            this.serviceShare.openNotAddedToEditorDialog();
          } else {
            userInArticle.access = userInArticle.is_owner
              ? 'Owner'
              : roleMapping[userInArticle.role];

            const isStandardCollaborator =
              this.curUserAccess && isCollaborator && userInArticle.role_label != 'Article admin';

            if (isStandardCollaborator && this.curUserAccess != userInArticle.role_label) {
              this.serviceShare.openNotifyUserAccessChangeDialog(
                this.curUserAccess,
                userInArticle.role_label,
                'Access Change'
              );
            } else if (
              isStandardCollaborator &&
              this.currCustomTypePermission != userInArticle.auth_role
            ) {
              this.serviceShare.openNotifyUserAccessChangeDialog(
                this.currCustomTypePermission.charAt(0).toUpperCase() +
                  this.currCustomTypePermission.slice(1),
                userInArticle.auth_role.charAt(0).toUpperCase() + userInArticle.auth_role.slice(1),
                'Permission Type Change'
              );
            }
            this.currUser = userInArticle;
            this.curUserAccess = userInArticle.role_label;
            this.currCustomTypePermission = userInArticle.auth_role;
            this.userInfo.data.access = this.curUserAccess;
            this.currUserRoleSubject.next(this.curUserAccess);
            this.checkHiddenCommentsAndUsers();
          }
        }
      );
    }
  }

  getCollaborators(): ArticleCollaborator[] {
    return this.collaborators?.get('collaborators')?.collaborators
      ? this.collaborators?.get('collaborators')?.collaborators
      : (this.ydoc.getMap('articleCollaborators') as any).get('collaborators')?.collaborators || [];
  }

  getAuthorsList() {
    return this.collaborators?.get('authorsList') instanceof Array
      ? this.collaborators.get('authorsList')
      : (this.ydoc.getMap('articleCollaborators') as any).get('authorsList') || [];
  }

  checkHiddenCommentsAndUsers() {
    const currUser = this.currUser;
    const collaborators = this.getCollaborators().filter((c: any) => c.id != currUser?.id);
    let idsThatShouldBeHidden = collaborators
      .filter(
        (c: any) =>
          c.hide_my_comments_from_user?.includes(currUser?.auth_role) ||
          c.hide_my_comments_from_user?.includes(currUser?.id)
      )
      .map((c: any) => c.id);
    if (this.serviceShare.hasOwnerCommentsPolicy) {
      idsThatShouldBeHidden = collaborators
        .map((c: any) => c.id)
        .filter((id: string) => id != currUser?.id);
    }
    this.serviceShare.removeStylesheetForHiddenComments();
    this.serviceShare.updateStylesheetForHiddenComments(idsThatShouldBeHidden);
    // this.serviceShare.CommentsService.commentsChangeSubject.next("change");
    this.serviceShare.CommentsService.updateAllComments();
  }

  collaboratorsSubject = new Subject<{ collaborators: ArticleCollaborator[] }>();
  observeCollaboratorsFunc = (event: Y.YMapEvent<any>, transaction: Y.Transaction) => {
    const collaboratorsData = this.collaborators.get('collaborators');
    if (collaboratorsData) {
      this.checkIfUserIsInArticle(this.collaborators);
    }
    this.collaboratorsSubject.next(collaboratorsData);
  };

  citableElementsSchemasSection;
  saveCitableElementsSchemas(citableElementsSchemasSection: any) {
    this.citableElementsSchemasSection = citableElementsSchemasSection;
  }

  hasFigures = false;
  hasReferences = false;
  hasTable = false;
  hasSupplementaryMaterials = false;
  hasFootnotes = false;
  saveArticleData(data) {
    const sections = {
      schema: {
        sections: [],
        override: {
          categories: {},
        },
      },
      template: '',
    };

    this.serviceShare.dictionarySubject.next(data.layout.settings.dictionary);
    const artilceFiguresSchemas = data.layout.template.sections.find((x) => x.name == 'Figures');
    if (artilceFiguresSchemas) {
      this.citableElementsSections.push(artilceFiguresSchemas);
      sections.template += artilceFiguresSchemas.template;
      data.layout.template.sections = data.layout.template.sections.filter(
        (x) => x.name !== 'Figures'
      );
      this.hasFigures = true;
    }
    const references = data.layout.template.sections.find((x) => x.name == 'References');
    if (references) {
      sections.template += references.template;
      this.citableElementsSections.push(references);
      data.layout.template.sections = data.layout.template.sections.filter(
        (x) => x.name !== 'References'
      );
      this.hasReferences = true;
    }

    const artilceTablesSchemas = data.layout.template.sections.find((x) => x.name == 'Tables');
    if (artilceTablesSchemas) {
      this.citableElementsSections.push(artilceTablesSchemas);
      sections.schema.sections.push('Tables');
      sections.schema.override.categories['Tables'] =
        artilceTablesSchemas.schema.override.categories.Tables;
      sections.template += artilceTablesSchemas.template;
      data.layout.template.sections = data.layout.template.sections.filter(
        (x) => x.name !== 'Tables'
      );
      this.hasTable = true;
    }

    const artilceSupplementaryMaterialsSchemas = data.layout.template.sections.find(
      (x) => x.name == 'SupplementaryMaterials'
    );
    if (artilceSupplementaryMaterialsSchemas) {
      this.citableElementsSections.push(artilceSupplementaryMaterialsSchemas);
      sections.schema.sections.push('SupplementaryMaterials');
      sections.schema.override.categories['SupplementaryMaterials'] =
        artilceSupplementaryMaterialsSchemas.schema.override.categories.SupplementaryMaterials;
      sections.template += artilceSupplementaryMaterialsSchemas.template;
      data.layout.template.sections = data.layout.template.sections.filter(
        (x) => x.name !== 'SupplementaryMaterials'
      );
      this.hasSupplementaryMaterials = true;
    }

    const artilceFootnotesSchemas = data.layout.template.sections.find(
      (x) => x.name == 'Footnotes'
    );
    if (artilceFootnotesSchemas) {
      this.citableElementsSections.push(artilceFootnotesSchemas);
      sections.schema.sections.push('Footnotes');
      sections.schema.override.categories['Footnotes'] =
        artilceFootnotesSchemas.schema.override.categories.Footnotes;
      sections.template += artilceFootnotesSchemas.template;
      data.layout.template.sections = data.layout.template.sections.filter(
        (x) => x.name !== 'Footnotes'
      );
      this.hasFootnotes = true;
    }

    if (!this.hasFigures && !this.hasReferences && !this.hasTable) {
      this.doNotRenderEndEditor = true;
    }

    this.saveCitableElementsSchemas(sections);

    const mainSectionValidations: mainSectionValidations = {};
    const fnc = (sec) => {
      if (sec.pivot_id) {
        if (sec.settings && sec.settings.main_section) {
          if (!sec.settings.max_instances) {
            sec.settings.max_instances = 9999;
          }
          mainSectionValidations[sec.pivot_id] = {
            min: sec.settings.min_instances,
            max: sec.settings.max_instances,
          };
        }
      }
    };
    data.layout.template.sections.forEach(fnc);
    data.mainSectionValidations = mainSectionValidations;
    this.articleData = data;
  }

  setArticleData(articleData: any, newarticle?: boolean) {
    this.saveArticleData(articleData);
    //this.articleData.layout.citation_style.style_updated = Date.now()
    if (newarticle) {
      this.creatingANewArticle = true;
    }
    this.checkLastTimeUpdated();
  }

  checkLastTimeUpdated() {
    if (new Date(this.articleData.updated_at).toDateString() !== new Date().toDateString()) {
      this.articleService.updateArticleUpdatedAt(this.articleData).subscribe((res) => {});
    }
  }

  destroyVersionDoc() {
    this.versionDoc = null;
    this.versionData = undefined;
    // this.lastSelectedVersion = undefined;
  }

  resetYdoc() {
    this.editorIsBuild = false;
    this.curUserAccess = undefined;
    this.ydoc = new Y.Doc({ gc: false });

    this.destroyVersionDoc();

    if (this.provider) {
      this.provider.awareness.destroy();
      this.provider.destroy();
    }
    this.provider = undefined;
    this.roomName = 'webrtc-test3';
    if (this.providerIndexedDb) {
      this.providerIndexedDb.destroy();
    }
    this.providerIndexedDb = undefined;

    //this.articleStructureFromBackend = undefined;
    this.articleStructureMap = undefined;
    this.articleData = undefined;
    this.sectionFormGroupsStructures = undefined;
    this.comments = undefined;
    this.PMMenusAndSchemasDefsMap = undefined;
    this.citableElementsSchemasSection = undefined;
    this.figuresMap = undefined;
    this.tablesMap = undefined;
    this.trackChangesMetadata = undefined;
    this.userInfo = undefined;
    this.creatingANewArticle = false;
    this.mathMap = undefined;
    this.referenceCitationsMap = undefined;
    this.printMap = undefined;
    this.customSectionProps = undefined;
    if (this.collaborators) {
      this.collaborators.unobserve(this.observeCollaboratorsFunc);
    }
    this.collaborators = undefined;
  }

  setUserColor(userInfo: any, onlyEmail?: boolean) {
    if (onlyEmail) {
      const userColorHex = intToRGB(hashCode(userInfo));
      const userColor = '#' + userColorHex;
      let userContrastColor = '#000000';

      if (isColorDark(userColorHex)) {
        userContrastColor = '#ffffff';
      }

      const userColors = {
        userColor,
        userContrastColor,
      };

      return userColors;
    }

    if (userInfo) {
      const usersColors = this.usersDataMap!.get('usersColors');
      const userId = userInfo.data.id;
      if (!usersColors[userId]) {
        const userColor = '#' + intToRGB(hashCode(userInfo.data.email));
        const userColors = {
          userColor,
          userContrastColor: '#000000',
        };
        usersColors[userId] = userColors;
        if (!this.serviceShare.compareObjects(this.usersDataMap.get('userColors'), usersColors)) {
          this.usersDataMap.set('userColors', usersColors);
        }
      }
      this.userInfo.color = usersColors[userId];
      return usersColors;
    }
  }

  createSnapshot(): void {
    this.articleVersions.push([
      {
        date: new Date().getTime(),
        snapshot: this.articleVersions.toArray()[this.lastSelectedVersion].snapshot,
        clientID: this.ydoc.clientID,
        users: [
          {
            name: this.userInfo.data.name,
            userColor: this.userInfo.color.userColor,
            id: this.userInfo.data.id,
            email: this.userInfo.data.email,
          },
        ],
        restored: this.articleVersions.toArray()[this.lastSelectedVersion].date,
      },
    ]);
    this.shouldRestore = true;
  }

  deleteDatabase(name: string) {
    //@ts-ignore
    window.indexedDB.deleteDatabase(name);
  }

  init(roomName: string, userInfo: any, articleData: any, oldVersion?: number | string) {
    this.serviceShare.ProsemirrorEditorsService.editMode = true;

    if (!this.articleData) {
      this.saveArticleData(articleData);
    }

    this.roomName = roomName;
    this.userInfo = userInfo;

    // if(!oldVersion) {
    //   // @ts-ignore
    //   if(typeof window.indexedDB.databases == "function") {
    //     //@ts-ignore
    //     window.indexedDB.databases().then((value: any[]) => {
    //       value.forEach((db: { name: string, version: number }) => {
    //         // if (db.name !== this.roomName) {
    //         //   //@ts-ignore
    //           window.indexedDB.deleteDatabase(db.name);
    //         // }
    //       })
    //     })
    //   }
    // }

    // this.providerIndexedDb = new IndexeddbPersistence(this.roomName, this.ydoc);
    const buildApp = () => {
      this.provider = new WebsocketProvider(
        `wss://${this.config.websocketHost}:${this.config.websocketPort}`,
        this.roomName,
        this.ydoc,
        {
          connect: true,
          params: { ydoc_version: this.serviceShare.ydocVersion },
          WebSocketPolyfill: WebSocket,
          awareness: new awarenessProtocol.Awareness(this.ydoc),
        }
      );
      // this.provider = new WebsocketProvider(`ws://localhost:9182`, roomName, this.ydoc, {
      //   connect: true,
      //   params: { ydoc_version: this.serviceShare.ydocVersion },
      //   WebSocketPolyfill: WebSocket,
      //   awareness: new awarenessProtocol.Awareness(this.ydoc),
      // });
      // this.provider.on('connection-close', function (WSClosedEvent: any) {
      //   console.log("---", WSClosedEvent, (new Date()).getTime());
      // });
      // this.provider.on('connection-error', function (WSErrorEvent: any) {
      //   console.log("---", WSErrorEvent, (new Date()).getTime());
      // });

      this.provider.on('synced', (isSynced: boolean) => {
        console.log('synced', isSynced);
        if (isSynced) {
          const docSize = this.ydoc.share.size;
          this.serviceShare.maintenancePageMode = false;

          if (this.creatingANewArticle) {
            this.ydoc.getMap('ydocVersion').set('version', this.serviceShare.ydocVersion);
            this.buildEditor(this.ydoc, undefined);
          } else if (docSize > 2) {
            if (this.ydoc.getMap('maintenance-page').get('maintenance')) {
              this.serviceShare.maintenancePageMode = true;
              this.provider.destroy();
              this.buildEditor(this.ydoc, oldVersion);
              this.serviceShare.ProsemirrorEditorsService.stopSpinner();
            } else {
              this.ydoc.getMap('ydocVersion').set('version', this.serviceShare.ydocVersion);
              this.buildEditor(this.ydoc, oldVersion);
            }
          } else if (docSize == 2) {
            this.authService.currentUser$.subscribe((data) => {
              this.creatingANewArticle = true;
              const selectedLayout = articleData.layout.template;
              this.newArticleIsCreated({ data }, articleData.uuid);
              selectedLayout.sections = selectedLayout.sections.filter(
                (x: any) =>
                  x.name != 'Figures' &&
                  x.name != 'References' &&
                  x.name != 'Tables' &&
                  x.name != 'SupplementaryMaterials' &&
                  x.name != 'Footnotes'
              );
              const articleStructure: ArticleSection[] = [];

              selectedLayout.sections.forEach((section: any) => {
                if (section.settings && section.settings.main_section == true) {
                  renderSectionFunc(section, articleStructure, this.ydoc, this.serviceShare, 'end');
                }
              });
              this.articleStructureFromBackend = articleStructure;
              setTimeout(() => {
                this.ydoc.getMap('ydocVersion').set('version', this.serviceShare.ydocVersion);
                this.buildEditor(this.ydoc, undefined);
              }, 100);
            });
          }
        }
      });
      /* this.provider = new WebrtcProvider(this.roomName, this.ydoc, {
        signaling: ['ws://dev.scalewest.com:4444','ws://localhost:4444',  'wss://y-webrtc-signaling-eu.herokuapp.com' , 'wss://signaling.yjs.dev'  ,'wss://y-webrtc-signaling-us.herokuapp.com'],
        password: null,
        awareness: new awarenessProtocol.Awareness(this.ydoc),
        maxConns: 20 + Math.floor(random.rand() * 15),
        filterBcConns: false,
        peerOpts: {},
      }); */

      /*this.provider?.on('onChange', (docArray: any) => {
        let params = new HttpParams({
          fromObject: {
            document: docArray,
            articleId: roomName
          }
        });


         sendUpdateToServiceWorker(params.toString());
        this.http.post('/products', params).subscribe(() => {
        });
      });*/

      const sendUpdateToServiceWorker = (update: string) => {
        if (navigator.onLine) {
          return;
        }
        const msg = {
          update: update,
        };
        navigator?.serviceWorker?.controller?.postMessage(msg);
      };

      if (!navigator.onLine) {
        //this.buildEditorsOffline();
        if (oldVersion) {
          this.buildEditor(this.ydoc, oldVersion);
        } else {
          this.buildEditor(this.ydoc, undefined);
        }
      } else {
        return;
        // Building the editor without backend for now just for developer purpose
        let buildeditor = false;
        //this.buildEditor();
        //return
        const onSubevent = fromEvent(this.provider!, 'connected').subscribe(() => {
          fromEvent(this.provider!, 'synced')
            .pipe(delay(500))
            .subscribe((data: any) => {
              if (!buildeditor) {
                //let synced = this.provider?.room?.synced
                buildeditor = true;
                if (data.synced) {
                  // this.buildEditor();
                } else {
                  renderDoc(data);
                }
              }
            });
          setTimeout(() => {
            if (!buildeditor) {
              buildeditor = true;
              // this.buildEditor();
            }
          }, 1500);
          /*
            // render only from backednt
            this.http.get('/products/' + roomName).subscribe((data) => {
            renderDoc(data);
            })


            // race render from backend on indexdb
            */
        });

        /* this.provider?.on('signalingConnected',()=>{
        }) */
      }
    };
    // if (this.providerIndexedDb.synced) {
    //   buildApp()
    // } else {
    //   this.providerIndexedDb.on('synced', () => {
    buildApp();
    //   })
    // }
    const renderDoc = (data: any) => {
      let currentState1: any;
      try {
        const documents = data
          .map((item: any) => {
            if (typeof item.document == 'string') {
              return Uint8Array.from(item.document.split(','));
            } else {
              return null;
            }
          })
          .filter((item: any) => item);
        documents.forEach((doc: any) => {
          Y.applyUpdate(this.ydoc, doc);
        });
      } catch (e) {
        console.error(e);
      }

      if (oldVersion) {
        this.buildEditor(this.ydoc, oldVersion);
      } else {
        this.buildEditor(this.ydoc);
      }
    };
  }

  shouldSetTheOwnerForTheNewArticle = false;
  ownerInfo: any;
  newArticleId: string = '';

  newArticleIsCreated(user: any, articleId: string) {
    this.shouldSetTheOwnerForTheNewArticle = true;
    this.ownerInfo = user;
    this.newArticleId = articleId;
  }

  setArticleOwnerInfo() {
    this.shouldSetTheOwnerForTheNewArticle = false;
    if (this.roomName == this.newArticleId) {
      this.collaborators.set('collaborators', {
        collaborators: [
          {
            name: this.ownerInfo.data.name,
            email: this.ownerInfo.data.email,
            first_name: this.ownerInfo.data.first_name,
            last_name: this.ownerInfo.data.last_name,
            role_label: 'Author',
            settings: [],
            auth_role: 'writer',
            type: 'WRITER',
            id: this.ownerInfo.data.id,
            hide_me_from_user: [],
            hide_my_comments_from_user: [],
            allowed_article_versions: [],
            is_owner: true,
            is_co_author: false,
            position: 1,
            access: 'Owner',
            role: Role.author,
            affiliations: [],
          },
        ],
        affiliations: [],
      });
      this.collaborators.set('authorsList', [
        {
          authorId: this.ownerInfo.data.id,
          authorEmail: this.ownerInfo.data.email,
        },
      ]);
    }
    this.ownerInfo = undefined;
    this.newArticleId = '';
  }
}
