import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Version, VersionChange, VersionUserInfo } from '@app/core/models/version.models';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editors.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { YdocService } from '@app/editor/services/ydoc.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit, AfterViewInit {
  @ViewChild('versionContainer', { read: ElementRef }) versionContainer: ElementRef;

  @Input() version: Version;
  @Input() versions: Version[];

  users = [];
  currentVersionTime: number;

  constructor(
    private sharedService: ServiceShare,
    private ydocService: YdocService,
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {}

  get canRestoreVersion(): boolean {
    return !this.ydocService.currUser.allowed_article_versions.length;
  }

  ngOnInit(): void {
    if (this.version.currentVersion) {
      this.currentVersionTime = new Date().getTime();

      const nextVersionChanges = this.ydocService.ydoc.getMap('nextVersionChanges');
      this.users = (nextVersionChanges.get('users') as VersionUserInfo[]) || [];

      nextVersionChanges.observe(() => {
        this.users = (nextVersionChanges.get('users') as VersionUserInfo[]) || [];
        this.currentVersionTime = new Date().getTime();
        this.changeDetector.detectChanges();
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.ydocService.lastSelectedVersion == this.version.index) {
      this.versionContainer.nativeElement?.classList.add('selected');
      setTimeout(() => {
        const element = document.querySelector('.all-versions-container') as HTMLElement;
        if (element) {
          element.scrollTop = this.versionContainer.nativeElement?.offsetTop;
        }
      }, 100);
    } else {
      this.versionContainer.nativeElement?.classList.remove('selected');
    }
    if (this.version.currentVersion && !this.sharedService.oldVersion) {
      this.versionContainer.nativeElement?.classList.add('selected');
    }
    this.changeDetector.detectChanges();
  }

  selectVersion(version: Version): void {
    if (this.ydocService.lastSelectedVersion != version.index) {
      (document.querySelector('.version-wrapper .selected') as HTMLElement)?.classList?.remove(
        'selected'
      );
      this.versionContainer.nativeElement?.classList.add('selected');
      this.prosemirrorEditorsService.spinSpinner();
      this.ydocService.versionSubject.next(VersionChange.reRender);
      this.router.navigate([`${this.ydocService.roomName.split('/')[0]}`], {
        fragment: `${version.index}`,
      });
    }
  }

  restoreVersion(event: Event, version: Version): void {
    event.stopImmediatePropagation();
    this.prosemirrorEditorsService.spinSpinner();

    this.sharedService.httpClient
      .post(
        `${this.appConfig.apiUrl}/article-storage/article/${this.ydocService.articleData.uuid}/restore/${version.index}`,
        {}
      )
      .subscribe({
        next: () => {
          this.ydocService.lastSelectedVersion = undefined;
          this.router.navigate([`${this.ydocService.roomName.split('/')[0]}`]);
          this.ydocService.versionSubject.next(VersionChange.reconnect);
          this.ydocService.lastSelectedVersion = undefined;
        },
        error: (err: Error) => {
          console.error(err);
        },
      });
  }

  returnToNewestVersion(): void {
    this.ydocService.returnToNewestVersion();
  }
}
