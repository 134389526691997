import { Injectable } from '@angular/core';
import Echo, { Channel } from 'laravel-echo';
import * as io from 'socket.io-client';
import { Inject } from '@angular/core';
import { ECHO_CONFIG, EchoConfig } from './echo.models';

@Injectable({
  providedIn: 'root',
})
export class EchoService {
  private echo: Echo;

  constructor(@Inject(ECHO_CONFIG) private config: EchoConfig) {
    this.echo = new Echo({
      broadcaster: 'socket.io',
      host: this.config.options.host,
      namespace: this.config.notificationNamespace,
      client: io,
      auth: {
        headers: {
          Authorization: '', // We will set this later
        },
      },
    });
  }

  setAuthToken(token: string): void {
    this.echo.connector.options.auth.headers['Authorization'] = `Bearer ${token}`;
  }

  joinChannel(channel: string): Channel {
    return this.echo.channel(channel);
  }

  listen(channel: Channel, event: string, callback: (data: any) => void): void {
    channel.listen(event, callback);
  }

  leaveChannel(channel: string): void {
    this.echo.leave(channel);
  }

  disconnect(): void {
    this.echo.disconnect();
  }
}
