import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { basicSetup, EditorState, EditorView } from '@codemirror/basic-setup';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';

//@ts-ignore
import { html } from '@codemirror/lang-html';
import { javascript } from '@codemirror/lang-javascript';

import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import { FormioModule } from '@formio/angular';
import { FormControlNameDirective } from '@app/editor/directives/form-control-name.directive';
import { MatFormioModule } from '@app/formio-angular-material/angular-material-formio.module';
import { MaterialModule } from '@app/shared/material.module';
import { TaxonSectionComponent2 } from '@app/editor/section/taxon-section/taxon-section.component';
import { ArticleSection, basicArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editors.service';
import { TreeService } from '@app/editor/meta-data-tree/tree-service/tree.service';
import { FormBuilderService } from '@app/editor/services/form-builder.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { DetectFocusService } from '@app/editor/utils/detectFocusPlugin/detect-focus.service';
import { HelperService } from '../helpers/helper.service';
import { TextSelection } from 'prosemirror-state';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialsModule } from '../materials-section/materials-section.component';

@Component({
  selector: 'app-section-modal',
  templateUrl: './section-modal.component.html',
  styleUrls: ['./section-modal.component.scss'],
})
export class SectionModalComponent implements AfterViewInit, AfterViewChecked {
  @ViewChild('content', { read: ViewContainerRef }) content?: ViewContainerRef;

  renderForm = false;
  modalTemplate: string;
  hidehtml = true;
  hidejson = true;
  hideDefsjson = true;
  error = false;
  errorMessage = '';
  codemirrorHTMLEditor?: EditorView;
  codemirrorJsonEditor?: EditorView;
  codemirrorMenusAndSchemasDefsEditor?: EditorView;
  FormStructure: any;
  renderSection = false;
  sectionTreeTitleValue = '';
  basicSection: basicArticleSection;

  complexSection = false;
  complexSectionDeletedChildren: ArticleSection[] = [];
  complexSectionAddedChildren: ArticleSection[] = [];

  @Output() onSubmitChange = new EventEmitter<(data: any) => Promise<any>>();
  @Input() onSubmit!: (data: any) => Promise<any>;
  @Input() component!: any;
  @Input() section!: ArticleSection;
  @Output() sectionChange = new EventEmitter<ArticleSection>();
  @Input() sectionContent: any = undefined;

  @Input() triggerCustomSecSubmit: Subject<any>;
  @Output() triggerCustomSecSubmitChange = new EventEmitter<Subject<any>>();

  _sectionForm!: UntypedFormGroup;
  sectionFormClone!: UntypedFormGroup;

  @Input() set sectionForm(val) {
    if (this.section.title.name == 'Material') {
      this._sectionForm = new UntypedFormGroup({});
    } else {
      this._sectionForm = val;
      this.sectionFormClone = this.formBuilderService.cloneAbstractControl(this._sectionForm);
    }
  }

  get sectionForm() {
    return this._sectionForm;
  }

  codemirrorHtmlTemplate: HTMLElement;
  codemirrorJsonTemplate: HTMLElement;
  codemirrorMenusAndSchemasDefs: HTMLElement;
  ProsemirrorEditor: HTMLElement;
  container: HTMLElement;
  formio: HTMLElement;

  initFunc: (selfRef: any) => () => void;
  onChange: (selfRef: any) => (change: any) => void;
  submitSection: (selfRef: any) => () => void;
  ready: (selfRef: any) => (form: any) => void;
  isValidHTML: (selfRef: any) => (html: string) => void;
  formatHTML: (selfRef: any) => (html: string) => any;
  renderCodemMirrorEditors: (selfRef: any) => () => any;
  renderComplexSectionTree: (selfRef: any) => () => any;

  defaultSubmitSection: any;
  defaultReady: any;
  defaultOnSubmit: any;
  defaultOnChange: any;

  constructor(
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private treeService: TreeService,
    private formBuilderService: FormBuilderService,
    public detectFocusService: DetectFocusService,
    public helperService: HelperService,
    private serviceShare: ServiceShare,
    private changeDetectionRef: ChangeDetectorRef,
    @Inject(APP_CONFIG) readonly config: AppConfig
  ) {
    const self = this;

    this.initFunc = (selfRef: any) => () => {
      if (selfRef.section.title.name == 'Material' && selfRef.section.mode == 'editMode') {
        selfRef.renderForm = true;
        return;
      }
      if (!selfRef.sectionContent) {
        selfRef.sectionContent = self.formBuilderService.populateDefaultValues(
          self.treeService.sectionFormGroups[selfRef.section.sectionID].getRawValue(),
          selfRef.section.formIOSchema,
          selfRef.section.sectionID,
          selfRef.section,
          selfRef.sectionForm
        );
      }
      selfRef.renderSection = true;

      try {
        selfRef.renderCodemMirrorEditors(selfRef)();
      } catch (e) {
        console.error(e);
      }

      let editorContainer =
        self.prosemirrorEditorsService.editorContainers[selfRef.section.sectionID];
      if (editorContainer) {
        let editorView = editorContainer.editorView;
        const doc = editorContainer.editorView.state.doc;
        const nodeSize = doc.nodeSize;
        editorView.focus();
        editorView.dispatch(
          editorView.state.tr.scrollIntoView().setSelection(TextSelection.create(doc, nodeSize - 2))
        );
        self.detectFocusService.sectionName = selfRef.section.sectionID;
      }

      selfRef.sectionTreeTitleValue = self.treeService.findNodeById(
        selfRef.section.sectionID
      ).label;
      if (
        (selfRef.sectionContent.components as Array<any>).find((val) => {
          return val.key == 'submit' && val.type == 'button';
        })
      ) {
        selfRef.sectionContent.components = selfRef.sectionContent.components.filter((val) => {
          return val.key != 'submit' || val.type != 'button';
        });
      }

      if (selfRef.sectionContent.props) {
        selfRef.sectionContent.props.initialSectionTitle = self.basicSection.label;
        selfRef.sectionContent.props.isSectionPopup = true;
        selfRef.sectionContent.props.sectionLabelTemplate = self.section.title.template;
      } else {
        selfRef.sectionContent.props = {
          initialSectionTitle: self.basicSection.label,
          isSectionPopup: true,
          sectionLabelTemplate: selfRef.section.title.template,
        };
      }

      if (
        selfRef.sectionContent.components.length > 0 &&
        selfRef.sectionContent.components[0].key == 'sectionTreeTitle'
      ) {
        selfRef.sectionContent.components[0].defaultValue =
          '<p controlpath="" customproppath="" formcontrolname="" contenteditablenode="" menutype="" allowedtags="" commentable="" invalid="false" style="" class="set-align-left">' +
          self.basicSection.label +
          '</p>';
      }

      selfRef.renderForm = true;
    };

    this.onChange = (selfRef: any) => (change: any) => {
      self.prosemirrorEditorsService.editMode = true;
      if (change instanceof Event) {
      } else {
        selfRef.isValid = change.isValid;
        selfRef.isModified = change.isModified;
        selfRef.formIoSubmission = change.data;
        let vals = JSON.parse(JSON.stringify(change.data));
        if ((change.changed && change.changed.instance) || selfRef.formIoRoot) {
          let rawVals = change.changed
            ? change.changed.instance.root.rawVals
            : selfRef.formIoRoot.rawVals;
          if (rawVals) {
            Object.keys(rawVals).forEach((key) => {
              vals[key] = rawVals[key];
            });
          }
        }
        if (
          /{{\s*\S*\s*}}|<span(\[innerHTML]="[\S]+"|[^>])+>[^<]*<\/span>/gm.test(
            selfRef.section.title.template
          )
        ) {
          self.serviceShare.ProsemirrorEditorsService?.interpolateTemplate(
            selfRef.section.title.template,
            vals,
            selfRef.sectionForm
          ).then((newTitle: string) => {
            selfRef.sectionTreeTitleValue = newTitle;
          });
        } else if (vals.sectionTreeTitle != null && vals.sectionTreeTitle != undefined) {
          selfRef.sectionTreeTitleValue = vals.sectionTreeTitle;
        }
        if (change.changed && change.changed.instance) {
          selfRef.formIoRoot = change.changed.instance.root;
        }
      }
    };

    this.submitSection = (selfRef: any) => () => {
      this.section.prosemirrorHTMLNodesTempl = this.codemirrorHTMLEditor?.state.doc.sliceString(
        0,
        this.codemirrorHTMLEditor?.state.doc.length
      );

      if (selfRef.formIoRoot) {
        selfRef.formIoRoot.submit();
      } else if (
        selfRef.section.title.name == 'Taxon' ||
        selfRef.section.title.name == '[MM] Materials' ||
        selfRef.section.title.name == 'Material' ||
        selfRef.section.title.name == '[AM] Funder' ||
        selfRef.section.title.name == '[MM] Taxon treatments'
      ) {
        selfRef.triggerCustomSecSubmit.next(true);
      }
    };

    this.ready = (selfRef: any) => (form: any) => {
      selfRef.FormStructure = form;
    };

    this.isValidHTML = (selfRef: any) => (html: string) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/xml');
      if (doc.documentElement.querySelector('parsererror')) {
        return doc.documentElement.querySelector('parsererror')!;
      } else {
        return true;
      }
    };

    this.formatHTML = (selfRef: any) => (html: string) => {
      let tab = '\t';
      let result = '';
      let indent = '';

      html.split(/>\s*</).forEach(function (element) {
        if (element.match(/^\/\w/)) {
          indent = indent.substring(tab.length);
        }

        result += indent + '<' + element + '>\r\n';

        if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
          indent += tab;
        }
      });

      return result.substring(1, result.length - 3);
    };

    this.renderCodemMirrorEditors = (selfRef: any) => () => {
      try {
        selfRef.codemirrorHtmlTemplate = document.getElementById('section-codemirrorHtmlTemplate');
        selfRef.codemirrorJsonTemplate = document.getElementById('section-codemirrorJsonTemplate');
        selfRef.codemirrorMenusAndSchemasDefs = document.getElementById(
          'section-codemirrorMenusAndSchemasDefs'
        );

        if (!selfRef.config.production) {
          selfRef.codemirrorJsonEditor = new EditorView({
            state: EditorState.create({
              doc: `${JSON.stringify(selfRef.sectionContent, null, '\t')}`,
              extensions: [basicSetup, javascript()],
            }),
            parent: selfRef.codemirrorJsonTemplate,
          });

          let {
            importantMenusDefsForSection,
            importantScehmasDefsForSection,
            menusAndSchemasForCitableElements,
          } = self.prosemirrorEditorsService.getMenusAndSchemaDefsImportantForSection(
            selfRef.section.sectionID
          );

          selfRef.codemirrorMenusAndSchemasDefsEditor = new EditorView({
            state: EditorState.create({
              doc: `${JSON.stringify({ importantMenusDefsForSection, importantScehmasDefsForSection }, null, '\t')}`,
              extensions: [basicSetup, javascript()],
            }),
            parent: selfRef.codemirrorMenusAndSchemasDefs,
          });
        }

        if (!selfRef.section.prosemirrorHTMLNodesTempl) {
          console.error(
            `prosemirrorHTMLNodesTempl is ${selfRef.section.prosemirrorHTMLNodesTempl}.Should provide such a property in the article sections structure.`
          );
          return;
        }

        if (!selfRef.section.prosemirrorHTMLNodesTempl) {
          console.error(
            `prosemirrorHTMLNodesTempl is ${selfRef.section.prosemirrorHTMLNodesTempl}.Should provide such a property in the article sections structure.`
          );
          return;
        }
        let prosemirrorNodesHtml = selfRef.section.prosemirrorHTMLNodesTempl;
        prosemirrorNodesHtml = selfRef.formatHTML(selfRef)(prosemirrorNodesHtml);

        selfRef.codemirrorHTMLEditor = new EditorView({
          state: EditorState.create({
            doc: prosemirrorNodesHtml,
            extensions: [basicSetup, html()],
          }),

          parent: selfRef.codemirrorHtmlTemplate,
        });
      } catch (e) {
        console.error(e);
      }
    };

    this.defaultSubmitSection = this.submitSection(this);
    this.defaultReady = this.ready(this);
    this.defaultOnChange = this.onChange(this);
  }

  ngAfterViewChecked(): void {
    this.changeDetectionRef.detectChanges();
  }

  isValid: boolean = true;
  isModified: boolean = false;
  formIoSubmission: any;
  formIoRoot: any;

  ngAfterViewInit(): void {
    this.basicSection = this.treeService.findNodeById(this.section.sectionID);
    this.modalTemplate = this.section.modalTemplate;
    this.defaultOnSubmit = this.onSubmit;

    if (this.modalTemplate && this.section.mode == 'editMode') {
      setTimeout(() => {
        this.createComponent(this.modalTemplate);
      }, 0);
    } else {
      this.initFunc(this)();
    }
  }

  componentRef: ComponentRef<any>;
  createComponent(template: string): void {
    const self = this;
    const config = this.config;

    const component = Component({
      template,
      standalone: true,
      imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatFormioModule,
        FormioModule,
        FormControlNameDirective,
        TaxonSectionComponent2,
        MaterialsModule,
        FlexLayoutModule,
      ],
    })(
      class implements AfterViewInit {
        renderForm = false;
        hidehtml = true;
        hidejson = true;
        hideDefsjson = true;
        error = false;
        errorMessage = '';
        codemirrorHTMLEditor?: EditorView;
        codemirrorJsonEditor?: EditorView;
        codemirrorMenusAndSchemasDefsEditor?: EditorView;
        FormStructure: any;
        renderSection = false;
        sectionTreeTitleValue = '';
        config = config;
        basicSection: basicArticleSection;

        isValid: boolean = true;
        isModified: boolean = false;
        formIoSubmission: any;
        formIoRoot: any;

        complexSection = false;
        complexSectionDeletedChildren: ArticleSection[] = [];
        complexSectionAddedChildren: ArticleSection[] = [];

        component = self.component;
        section = self.section;
        sectionContent = self.sectionContent;

        _sectionForm!: UntypedFormGroup;
        sectionFormClone!: UntypedFormGroup;
        get sectionForm() {
          return self._sectionForm;
        }

        triggerCustomSecSubmit = new Subject();

        initFunc = self.initFunc;
        onChange = self.onChange(this);
        submitSection = self.submitSection(this);
        ready = self.ready(this);
        isValidHTML = self.isValidHTML(this);
        onSubmit = self.onSubmit;
        formatHTML = self.formatHTML;
        renderCodemMirrorEditors = self.renderCodemMirrorEditors;
        renderComplexSectionTree = self.renderComplexSectionTree;

        ngAfterViewInit(): void {
          this.initFunc(this)();
        }
      }
    );

    this.componentRef = this.content.createComponent(component);
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy();
    this.content?.clear();
  }
}
