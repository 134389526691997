import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { editorContainer } from '@app/editor/services/prosemirror-editors.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { schema } from '@app/editor/utils/Schema';
import { PageEvent } from '@angular/material/paginator';
import { UntypedFormControl } from '@angular/forms';
import { debounce } from 'rxjs/operators';
import { interval } from 'rxjs';
import { AddFromGalleryComponentData, GalleryImageData } from '../add-figure-dialog-v2.component';

@Component({
  selector: 'app-add-from-gallery-component-dialog',
  templateUrl: './add-from-gallery-component.html',
  styleUrls: ['./add-from-gallery-component.scss'],
})
export class AddFromGalleryComponentDialogComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  @ViewChild('componentDescription', { read: ElementRef }) componentDescription?: ElementRef;
  searchForm = new UntypedFormControl('');
  filteredImages: GalleryImageData[] = [];

  pageIndex = 0;
  pageSize: number;
  pageSizeOptions: number[] = [2, 4, 6, 9, 12];
  minImageWidth = 120;
  pageEvent: PageEvent;
  selectedUrl: string;
  selectedImage: null | GalleryImageData = null;

  get paginatedImageArray(): GalleryImageData[] {
    let startIndex = this.pageIndex * this.pageSize;
    return this.filteredImages.slice(startIndex, startIndex + this.pageSize);
  }

  get gridColumns() {
    let cols: number;
    switch (this.pageSize) {
      case 2:
      case 4:
        cols = 2;
        break;
      case 6:
      case 9:
        cols = 3;
        break;
      case 12:
        cols = 4;
        break;
      default:
        cols = 1;
    }
    return `repeat(${cols}, minmax(${this.minImageWidth}px, 1fr))`;
  }

  setDefaultPageSize(imagesLength: number) {
    if (imagesLength >= 1 && imagesLength <= 2) {
      this.pageSize = 2;
    } else if (imagesLength >= 3 && imagesLength <= 4) {
      this.pageSize = 4;
    } else if (imagesLength >= 5 && imagesLength <= 9) {
      this.pageSize = 6;
    } else {
      this.pageSize = 12;
    }
  }

  componentDescriptionPmContainer: editorContainer;
  constructor(
    private serviceShare: ServiceShare,
    private dialogRef: MatDialogRef<AddFromGalleryComponentDialogComponent>,
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      component?: AddFromGalleryComponentData;
      galleryImageArray?: GalleryImageData[];
    }
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnInit(): void {
    this.filteredImages = [...this.data.galleryImageArray];
    this.setDefaultPageSize(this.filteredImages.length);
  }

  setFormControlChangeListener() {
    this.searchForm.valueChanges.pipe(debounce((val) => interval(100))).subscribe((val) => {
      if (val && val.trim().length > 0) {
        const searchVal = val.toLocaleLowerCase();
        this.filteredImages = this.data.galleryImageArray.filter(
          (image) =>
            image.recordNumber && image.recordNumber.toLocaleLowerCase().includes(searchVal)
        );
      } else {
        this.filteredImages = [...this.data.galleryImageArray];
      }
      this.pageIndex = 0;
    });
  }

  clearSearch() {
    this.searchForm.setValue('');
  }

  ngAfterViewInit() {
    let header = this.componentDescription?.nativeElement;
    this.componentDescriptionPmContainer =
      this.serviceShare.ProsemirrorEditorsService.renderSeparatedEditorWithNoSync(
        header,
        'pm-pdf-menu-container',
        schema.nodes.paragraph.create({})
      );
    //@ts-ignore
    this.componentDescriptionPmContainer.editorView.isPopupEditor = true;
    this.setFormControlChangeListener();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  submitDialog() {
    let description = this.componentDescriptionPmContainer.editorView.dom.innerHTML.replace(
      /<br[^>]+>|<img[^>]+>|<span[^>]+><\/span>/g,
      ''
    );
    let newComponent: AddFromGalleryComponentData = {
      description: this.componentDescriptionPmContainer.editorView.dom.innerHTML,
      url: this.selectedUrl,
      componentType: 'image',
    };
    if (this.data && this.data.component) {
      newComponent = JSON.parse(JSON.stringify(this.data.component));
      newComponent.description = description;
      newComponent.url = this.selectedUrl;
      newComponent['componentType'] = 'image';
      this.dialogRef.close({ component: newComponent });
    }
    this.dialogRef.close({ component: newComponent });
  }

  selectImage = (image: string) => {
    this.selectedUrl = image;
    this.submitDialog();
  };

  handlePage(e: PageEvent) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
  }
}
