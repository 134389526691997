import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  public getDate(timestamp: number): string {
    const date = new Date(+timestamp);
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const time =
      date.getHours() +
      ':' +
      (date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()) +
      ' ' +
      days[date.getDay()] + // Adjusted to account for Sunday
      ' ' +
      date.getDate() +
      '/' +
      (date.getMonth() + 1) + // Adjusted for 1-based month
      '/' +
      date.getFullYear();
    return time;
  }
}
