import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { ServiceShare } from '@app/editor/services/service-share.service';

@Injectable({
  providedIn: 'root',
})
export class CasbinResolver implements Resolve<boolean> {
  resolverKey = 'CasbinResolver';
  constructor(private serviceShare: ServiceShare) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.serviceShare.resolversData[this.resolverKey];
  }
}
