import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';

import { YEvent, Map } from 'yjs';

import { ServiceShare } from '@app/editor/services/service-share.service';
import {
  CiToTypes,
  EditorsRefsManagerService,
} from '@app/layout/pages/library/lib-service/editors-refs-manager.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { RefsAddNewInArticleDialogComponent } from '../refs-add-new-in-article-dialog/refs-add-new-in-article-dialog.component';
import { clearRefFromFormControl } from '../refs-in-article-dialog/refs-in-article-dialog.component';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';
import { CslService } from '@app/layout/pages/library/lib-service/csl.service';

@Component({
  selector: 'app-refs-in-article-cite-dialog',
  templateUrl: './refs-in-article-cite-dialog.component.html',
  styleUrls: ['./refs-in-article-cite-dialog.component.scss'],
})
export class RefsInArticleCiteDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  refsInYdoc: any;
  refMap: Map<any>;
  checkedReferences = {};
  CiToTypes = CiToTypes;
  citationText = '';
  defaultCitationStyleLayoutLabel = 'Default';
  defaultCitationStyleSortLabel = 'Default';
  citations = [];
  isEditMode: boolean;
  layoutOptions = [];
  sortOptions = [];

  @ViewChild('searchrefs', { read: ElementRef }) searchrefs?: ElementRef;

  searchControl = new UntypedFormControl('');
  citationLayoutControl = new UntypedFormControl('Default');
  citationSortOptControl = new UntypedFormControl(['Default']);

  selectedSortOptions: string[] = [];
  selectedLayoutOption = 'Default';

  ydocRefsSubject = new Subject<any>();
  subscription = new Subscription();

  constructor(
    private ydocService: YdocService,
    public dialogRef: MatDialogRef<RefsInArticleCiteDialogComponent>,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private serviceShare: ServiceShare,
    private cslService: CslService,
    private editorsRefsManager: EditorsRefsManagerService,
    private snackBar: SnackbarService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: {
        text: string;
        refCitationIDs: string[];
        citationLayout: any;
        sortOptions: string[];
        refCitationID: string;
        citedRefsCiTOs: string[];
      };
      isEditMode: boolean;
    }
  ) {
    this.refMap = this.ydocService.referenceCitationsMap;
    this.refMap.observe(this.observeRefMapChanges);
    this.getRefsInYdoc();

    const citationOptions =
      this.serviceShare.YdocService.articleData.layout.settings['allowed_tags']?.cslCitationOptions;
    if (citationOptions?.defaultCitationStyleSortLabel) {
      this.defaultCitationStyleSortLabel = citationOptions.defaultCitationStyleSortLabel;
    }
    this.sortOptions.push({
      name: this.defaultCitationStyleSortLabel,
      tag: ['Default'],
    });
    if (citationOptions) {
      if (citationOptions.defaultCitationStyleLayoutLabel) {
        this.defaultCitationStyleLayoutLabel = citationOptions.defaultCitationStyleLayoutLabel;
      }
      if (citationOptions.layoutOptions) {
        Object.keys(citationOptions.layoutOptions).forEach((key: string, i: number) => {
          this.layoutOptions.push({
            name: key,
            layout: citationOptions.layoutOptions[key],
          });
        });
      }
      if (citationOptions.sortOptions) {
        Object.keys(citationOptions.sortOptions).forEach((key: string) => {
          let tag = citationOptions.sortOptions[key] as string[];
          if (tag.join('').includes('<sort>')) {
            tag = [tag.join('').replace('<sort>', '').replace('</sort>', '')];
          }
          this.sortOptions.push({ name: key, tag });
        });
      }
    }
  }

  searchValue: string = '';
  ngOnInit(): void {
    this.subscription.add(
      this.searchControl.valueChanges.subscribe((value) => {
        this.searchValue = value;
        this.passRefsToSubject();
      })
    );
    this.subscription.add(
      this.citationLayoutControl.valueChanges.subscribe((value: string) => {
        this.selectedLayoutOption = value;
        this.renderCitation();
      })
    );
    this.subscription.add(
      this.citationSortOptControl.valueChanges.subscribe((selected: string[]) => {
        if (selected.includes('Default') && !this.selectedSortOptions.includes('Default')) {
          this.selectedSortOptions = [this.defaultCitationStyleSortLabel];
          this.citationSortOptControl.setValue([this.defaultCitationStyleSortLabel], {
            emitEvent: false,
          });
        } else if (
          selected.includes('Default') &&
          this.selectedSortOptions.includes('Default') &&
          selected.length > 1
        ) {
          const values = selected.filter((value: string) => value !== 'Default');
          this.selectedSortOptions = values;
          this.citationSortOptControl.setValue(values, { emitEvent: false });
        } else if (this.selectedSortOptions.length < selected.length) {
          selected.forEach((value: string) => {
            if (!this.selectedSortOptions.includes(value)) {
              this.selectedSortOptions.push(value);
            }
          });
        } else if (this.selectedSortOptions.length > selected.length) {
          if (selected.length == 0) {
            this.citationSortOptControl.setValue([this.defaultCitationStyleSortLabel], {
              emitEvent: false,
            });
            this.selectedSortOptions = [this.defaultCitationStyleSortLabel];
          } else {
            const value = this.selectedSortOptions.find(
              (value: string) => !selected.includes(value)
            );
            if (value) {
              this.selectedSortOptions = this.selectedSortOptions.filter(
                (val: string) => val !== value
              );
            }
          }
        }

        this.renderCitation();
      })
    );
    this.setReferenceData();
  }

  setReferenceData(): void {
    if (this.data.isEditMode) {
      this.citationText = this.data.data.text;
      this.data.data.refCitationIDs.forEach((id: string) => {
        this.checkedReferences[id] = this.refsInYdoc[id];
      });
      if (this.data.data.sortOptions.length > 0) {
        this.selectedSortOptions = this.data.data.sortOptions.map((opt: any) => opt.name);
        this.citationSortOptControl.setValue(
          this.data.data.sortOptions.map((opt: any) => opt.name)
        );
      } else {
        this.selectedSortOptions = [this.defaultCitationStyleSortLabel];
        this.citationSortOptControl.setValue([this.defaultCitationStyleSortLabel]);
      }
      this.citationLayoutControl.setValue(this.data.data.citationLayout.name || 'Default');
      this.isEditMode = true;
    } else {
      this.selectedSortOptions = [this.defaultCitationStyleSortLabel];
      this.citationLayoutControl.setValue('Default');
      this.citationSortOptControl.setValue([this.defaultCitationStyleSortLabel]);
    }
  }

  openAddNewRefToEditorDialog() {
    const dialogRef = this.dialog.open(RefsAddNewInArticleDialogComponent, {
      panelClass: ['refs-add-new-in-article-dialog', 'editor-dialog-container'],
      disableClose: !this.editorsRefsManager.closeOnClickOutside,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result instanceof Array) {
        result.forEach((refInstance) => {
          let refId = refInstance.ref.ref.id;
          this.refsInYdoc[refId] = refInstance.ref;
          this.checkedReferences[refId] = refInstance.ref;
          this.renderCitation();
        });
        this.saveNewRefsInYdoc();
      }
    });
  }

  ngAfterViewInit(): void {
    this.searchrefs.nativeElement.focus();
    this.ref.detectChanges();
  }

  saveNewRefsInYdoc() {
    let refsWithNoFormControls = clearRefFromFormControl(this.refsInYdoc);
    this.refMap.set('refsAddedToArticle', refsWithNoFormControls);
    setTimeout(() => {
      this.editorsRefsManager.updateRefsInEndEditorAndTheirCitations();
    }, 20);
  }

  observeRefMapChanges = (Yevent: YEvent, tr: any) => {
    this.getRefsInYdoc();
  };

  getRefsInYdoc() {
    this.refsInYdoc = this.refMap.get('refsAddedToArticle');
    setTimeout(() => {
      this.passRefsToSubject();
    }, 20);
  }

  refsCiTOsControls: { [key: string]: UntypedFormControl } = {};
  passRefsToSubject() {
    let newRefs = this.refsInYdoc;

    Object.values(newRefs).forEach((ref: any, i) => {
      let formC: UntypedFormControl;
      if (this.refsCiTOsControls[ref.ref.id]) {
        formC = this.refsCiTOsControls[ref.ref.id];
        formC.setValue(CiToTypes.find((t) => t.label == 'None'));
      } else if (this.isEditMode) {
        formC = new UntypedFormControl(CiToTypes.find((t) => t.label == 'None'));
        this.data.data.citedRefsCiTOs.forEach((cito: string, i: number) => {
          if (ref.ref.id == this.data.data.refCitationIDs[i]) {
            formC.setValue(CiToTypes.find((t) => t.label == cito));
          }
        });
        this.refsCiTOsControls[ref.ref.id] = formC;
      } else {
        formC = new UntypedFormControl(CiToTypes.find((t) => t.label == 'None'));
        this.refsCiTOsControls[ref.ref.id] = formC;
      }
      ref.refCiTOControl = formC;
    });

    this.ydocRefsSubject.next([
      ...Object.values(newRefs).filter((x: any) => {
        if (!x.citation.textContent) {
          let container = document.createElement('div');
          container.innerHTML = x.citation.bibliography;
          x.citation.textContent = container.textContent;
        }
        return x.citation.textContent.toLowerCase().includes(this.searchValue.toLowerCase());
      }),
    ]);
  }

  checkBoxChange(checked: boolean, ref: any): void {
    const refId = ref.ref.id;

    if (checked) {
      this.checkedReferences[refId] = this.refsInYdoc[refId];
    } else {
      delete this.checkedReferences[refId];
    }

    this.renderCitation();
  }

  renderCitation(): void {
    if (Object.keys(this.checkedReferences).length > 0) {
      const citationSettings = {
        selectedSortOptions: this.selectedSortOptions.map(
          (value: string) =>
            this.sortOptions[this.sortOptions.findIndex((opt: any) => value == opt.name)].tag
        ),
        layout:
          this.selectedLayoutOption == 'Default'
            ? 'Default'
            : this.layoutOptions
                .find((opt: any) => opt.name == this.selectedLayoutOption)
                .layout.join('\n'),
      };
      try {
        const sortedCitationStrings = this.cslService.generateCitation(
          this.checkedReferences,
          citationSettings
        );
        this.citationText = sortedCitationStrings.text;
      } catch (error) {
        this.snackBar.error('Incorrect layout or sort options!');
        this.citationText = '';
      }
    } else {
      this.citationText = '';
    }
  }

  deleteCitation(citationId: string) {
    delete this.checkedReferences[citationId];
    this.renderCitation();
  }

  isExist(ref: any) {
    return !!Object.keys(this.checkedReferences).find((id: string) => id == ref.ref.id);
  }

  removeSelectedSortOption(value: string): void {
    this.selectedSortOptions = this.selectedSortOptions.filter((val: string) => val != value);
    if (this.selectedSortOptions.length == 0) {
      this.citationSortOptControl.setValue([this.defaultCitationStyleSortLabel], {
        emitEvent: false,
      });
      this.selectedSortOptions = [this.defaultCitationStyleSortLabel];
    } else {
      this.citationSortOptControl.setValue(this.selectedSortOptions, {
        emitEvent: false,
      });
    }
  }

  citeSelectedRefs() {
    clearRefFromFormControl(this.refsInYdoc);

    const citation = {
      text: this.citationText,
      refCitationIDs: Object.keys(this.checkedReferences),
      citationLayout:
        this.selectedLayoutOption == 'Default'
          ? { name: 'Default', layout: 'Default' }
          : this.layoutOptions.find((opt: any) => opt.name == this.selectedLayoutOption),
      sortOptions: this.selectedSortOptions.map(
        (value: string) =>
          this.sortOptions[this.sortOptions.findIndex((opt: any) => value == opt.name)]
      ),
    };

    this.dialogRef.close({ citation });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.refMap.unobserve(this.observeRefMapChanges);
    this.subscription.unsubscribe();
  }
}
