import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TextSelection } from 'prosemirror-state';

import { TrackChangesService } from '@app/editor/services/track-changes/track-changes.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { changeData } from '../changes-section.component';
import { AuthService } from '@core/services/auth.service';
import { acceptChange, rejectChange } from '../../utils/trackChanges/acceptReject';
import { DateService } from '@app/core/services/date-service/date.service';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss'],
})
export class ChangeComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() change: changeData;
  @Input() index: number;

  @Input() doneRenderingChangesSubject?: Subject<any>;
  @Output() doneRenderingChangesSubjectChange = new EventEmitter<Subject<any>>();

  @Output() selected = new EventEmitter<boolean>();

  sub?: Subscription;

  initialShowMore = false;
  previewMode;
  constructor(
    public changesService: TrackChangesService,
    public serviceShare: ServiceShare,
    public ydocService: YdocService,
    private readonly authService: AuthService,
    private dateService: DateService
  ) {
    this.previewMode = serviceShare.ProsemirrorEditorsService!.previewArticleMode;
    this.changesService.lastSelectedChange$.subscribe((change) => {
      if (this.ydocService.curUserAccess && this.ydocService.curUserAccess == 'Reader') {
        return;
      }
      if (this.change.changeMarkId == change.changeMarkId) {
        this.selected.emit(true);
      } else {
        this.selected.emit(false);
      }
    });
  }

  selectChange() {
    let view =
      this.serviceShare.ProsemirrorEditorsService.editorContainers[this.change.section].editorView;
    let actualChange: changeData;
    let allChanges = this.serviceShare.TrackChangesService.changesObj;
    Object.keys(allChanges).forEach((changeid) => {
      let change = allChanges[changeid];
      if (change && change.changeMarkId == this.change.changeMarkId) {
        actualChange = change;
      }
    });
    if (actualChange) {
      view.focus();
      view.dispatch(
        view.state.tr
          .setSelection(
            new TextSelection(
              view.state.doc.resolve(actualChange.pmDocStartPos),
              view.state.doc.resolve(actualChange.pmDocStartPos)
            )
          )
          .setMeta('selected-comment', true)
      );
      this.serviceShare.ProsemirrorEditorsService.dispatchEmptyTransaction();
    }
  }

  ngOnDestroy(): void {
    this.sub ? this.sub.unsubscribe() : undefined;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  currUserId;
  ngOnInit(): void {
    this.authService.currentUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((userInfo) => {
      this.currUserId = userInfo.id;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.doneRenderingChangesSubject.next('rendered');
    }, 10);
    this.changesService.lastSelectedChange$.subscribe((change) => {
      if (this.ydocService.curUserAccess && this.ydocService.curUserAccess == 'Reader') {
        return;
      }
      if (this.change.changeMarkId == change.changeMarkId) {
        this.selected.emit(true);
      } else {
        this.selected.emit(false);
      }
    });
  }

  acceptChange(type: any, attrs: any) {
    let view =
      this.serviceShare.ProsemirrorEditorsService.editorContainers[this.change.section].editorView;

    const changeId = attrs.id;
    const endNotes = JSON.parse(JSON.stringify(this.ydocService.endNotesMap.get('endNotes')));
    const endNote = Object.values(endNotes).find(
      (note: any) => note.trackChangeId == changeId
    ) as any;

    if (endNote) {
      delete endNotes[endNote.end_note_ID].trackChangeId;
      this.ydocService.endNotesMap.set('endNotes', endNotes);
    }

    acceptChange(view, type, attrs);

    this.serviceShare.YdocService.ydoc.getMap('change').set('change', 'change');
    this.changesService.getChangesInAllEditors();
  }

  declineChange(event: Event, type: any, attrs: any) {
    let view =
      this.serviceShare.ProsemirrorEditorsService.editorContainers[this.change.section].editorView;

    const changeId = attrs.id;
    const endNotes = JSON.parse(JSON.stringify(this.ydocService.endNotesMap.get('endNotes')));
    const endNote = Object.values(endNotes).find(
      (note: any) => note.trackChangeId == changeId
    ) as any;

    if (endNote) {
      event.stopPropagation();

      const endNotesNumbers = JSON.parse(
        JSON.stringify(this.ydocService.endNotesMap.get('endNotesNumbers'))
      ) as string[];
      delete endNotes[endNote.end_note_ID];

      this.serviceShare.CitableElementsService.writeElementDataGlobal(
        endNotes,
        endNotesNumbers.filter((id) => id != endNote.end_note_ID),
        'end_note_citation'
      );
    } else {
      rejectChange(view, type, attrs);
    }

    this.serviceShare.YdocService.ydoc.getMap('change').set('change', 'change');
    this.changesService.getChangesInAllEditors();
  }

  getDate(timeStamp: number): string {
    return this.dateService.getDate(timeStamp);
  }
}
