import { Component } from '@angular/core';
import { StateInfoService } from '../state-info-service/state-info.service';
import { EditingRights, StateNameEntry, stateNames } from '../state-info.models';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'awt-state-info',
  templateUrl: './state-info.component.html',
  styleUrls: ['./state-info.component.scss'],
})
export class StateInfoComponent {
  documentStateName: string = '';
  documentLink: string = '';
  editingRights: EditingRights = EditingRights.readOnly;

  readonly documentState$: Observable<StateNameEntry> = this.stateInfoService
    .getDocumentState()
    .pipe(
      distinctUntilChanged(),
      map((name) => stateNames.find((el) => el.name === name))
    );

  constructor(private stateInfoService: StateInfoService) {}
}
