import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';

import { AuthService } from '@core/services/auth.service';
import { APP_CONFIG, AppConfig } from '@core/services/app-config';
import { OauthClient } from '@core/services/oauth-client';
import { take } from 'rxjs/operators';
import { User } from '../models/article.models';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private _authservice: AuthService,
    private router: Router,
    @Inject(APP_CONFIG) private config: AppConfig,
    private readonly oauthClient: OauthClient,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isLogged = this._authservice.isLoggedIn();

    if (!isLogged) {
      this._authservice.removeGlobalStyleForUser();
      localStorage.clear();
      if (state.url.includes('invitation_token')) {
        const [_, invitationToken] = state.url.split('?');

        const sessionToken = invitationToken.split('=')[1];
        const returnUrl = `${document.location.origin}/${route.params.id}`;
        /*sessionStorage.setItem("invitation_token", invitationToken.split('=')[1]);
        sessionStorage.setItem("return_uri", `${document.location.origin}/${route.params.id}`);*/

        sessionStorage.setItem('return_uri', `${document.location.origin}/${route.params.id}`);

        window.location.assign(
          `${this.config.authService}/login?invitation_token=${sessionToken}&return_uri=${returnUrl}`
        );
      } else {
        this._authservice.userInfo = undefined;
        sessionStorage.setItem('return_uri', state.url);

        return new Promise<boolean>(async (resolve, reject) => {
          this.oauthClient.lpClient
            .signIn()
            .then(async (signInResult) => {
              if (signInResult) {
                const token: string = await this.oauthClient.lpClient.getToken();
                this.authService.storeToken(token);
                this.authService
                  .getUserInfo(token)
                  .pipe(take(1))
                  .subscribe((user: User | undefined) => {
                    if (user) {
                      resolve(true);
                    } else {
                      resolve(false);
                    }
                  });
              } else {
                resolve(false);
              }
            })
            .catch((err) => {
              console.error(err);
              resolve(false);
            });
        });
      }
      return Promise.resolve(false);
    } else {
      return Promise.resolve(true);
    }
  }
}
