import { CommentConfig } from '../comment.models';

export const commentConfig: CommentConfig = {
  resolveButtonText: {
    resolved: 'Resolve',
    unresolved: 'Reopen',
  },
  resolveRecordText: {
    resolved: 'Marked as resolved',
    unresolved: 'Reopened',
  },
  colors: {
    background: '#00B1B2',
    foreground: 'white',
  },
  maxContentWidth: 290,
  defaultLastAddBoxHeight: 0,
};
