import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';

@Component({
  selector: 'insert-image-dialog',
  templateUrl: './insert-image-dialog.component.html',
  styleUrls: ['./insert-image-dialog.component.scss'],
})
export class InsertImageDialogComponent implements OnInit, AfterViewInit {
  imgLinkControl = new UntypedFormControl('', Validators.required);
  lastSource: 'dropzone' | 'user';
  @ViewChild('imgurlInput', { read: ElementRef }) imgurlInput?: ElementRef;
  constructor(
    private dialogRef: MatDialogRef<InsertImageDialogComponent>,
    private ref: ChangeDetectorRef,
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.imgLinkControl.valueChanges.subscribe((val) => {
      this.lastSource = 'user';
    });
  }

  getErrorMessage() {
    if (this.imgLinkControl.invalid && this.imgLinkControl.touched) {
      return 'This is not a valid img url.';
    } else {
      return '';
    }
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  doAction(data: any) {
    let imgUrl = this.imgLinkControl.value;
    let imgContainer = document.getElementById('insert-img-in-editor-container');
    let dimensions = imgContainer.getBoundingClientRect();
    let width;
    if (this.lastSource == 'dropzone' && imgUrl.includes(this.config.CDNService)) {
      imgUrl = imgUrl + `/resize/x300`;
      width = '300';
    } else {
      width = `${dimensions.width}`;
      if (dimensions.width > 300) {
        width = '300';
      }
    }
    this.dialogRef.close({ data, imgURL: imgUrl, width });
  }
  fileIsUploaded(uploaded) {
    if (uploaded.collection == 'images' && uploaded.base_url) {
      this.uploadedFileInCDN(uploaded);
    } else {
      console.error('File is not an image.');
    }
  }
  uploadedFileInCDN(fileData: any) {
    this.imgLinkControl.setValue(fileData.base_url);
    setTimeout(() => {
      this.lastSource = 'dropzone';
    }, 30);
  }
  ngAfterViewInit(): void {
    this.imgurlInput.nativeElement.focus();
    this.ref.detectChanges();
  }
}
