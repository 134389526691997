import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MaterialNestedComponent } from '../MaterialNestedComponent';
//@ts-ignore
import EditGridComponent from 'formiojs/components/editgrid/EditGrid.js';
import { FormioComponent } from '../../formio.component';
//@ts-ignore
import Components from 'formiojs/components/Components';

enum EditRowState {
  NEW = 'new',
  EDITING = 'editing',
  SAVED = 'saved',
  REMOVED = 'removed',
  DRAFT = 'draft',
}

/* tslint:disable no-bitwise only-arrow-functions */
const hashCode = function (str: any) {
  let hash = 0;
  let i = 0;
  let chr;
  str = str.replace(/\s/g, '');
  if (str.length === 0) {
    return hash;
  }
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
/* tslint:enable no-bitwise only-arrow-functions */

// Do nothing to createRowComponents, let mat-formio handle it.
/* tslint:disable only-arrow-functions */
EditGridComponent.prototype.createRowComponents = function () {
  return [];
};

const checkRow = EditGridComponent.prototype.checkRow;
EditGridComponent.prototype.checkRow = function (data: any, editRow: any, flags: any = {}) {
  if (flags.checkRow) {
    return checkRow.call(this, data, editRow, flags);
  } else {
    return true;
  }
};
/* tslint:enable only-arrow-functions */

const DEFAULT_HEADER_TEMPLATES = [
  hashCode((EditGridComponent as any).defaultHeaderTemplate),
  hashCode(`
  <div class="row">
    {% (components || []).forEach(function(component) { %}
      <div class="col-sm-2">{{ component.label }}</div>
    {% }) %}
  </div>`),
];

const DEFAULT_ROW_TEMPLATES = [
  hashCode((EditGridComponent as any).defaultRowTemplate),
  hashCode(`<div class="row">
  {% util.eachComponent(components, function(component) { %}
    <div class="col-sm-2">
      {{ getView(component, row[component.key]) }}
    </div>
  {% }) %}
  {% if (!instance.options.readOnly) { %}
    <div class="col-sm-2">
      <div class="btn-group pull-right">
        <button class="btn btn-default btn-sm editRow">Edit</button>
        <button class="btn btn-danger btn-sm removeRow">Delete</button>
      </div>
    </div>
  {% } %}
</div>`),
];

@Component({
  selector: 'mat-formio-editgrid',
  styleUrls: ['./editgrid.component.css'],
  templateUrl: './editgrid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialEditGridComponent extends MaterialNestedComponent implements AfterViewInit {
  @ViewChild('header') headerElement?: ElementRef;
  @ViewChild('footer') footerElement?: ElementRef;
  @ViewChildren('rows') rowElements?: QueryList<ElementRef>;
  @ViewChildren('forms') forms?: QueryList<FormioComponent>;
  public header?: string;
  public footer?: string;
  public displayedColumns?: string[];
  public columns: any = {};
  public colWidth = 0;
  public valid = true;
  public RowStates = EditRowState;
  public isModified: boolean;

  getRowTemplate(content: any) {
    return `<mat-list style="display: flex;">
      {% (components || []).forEach(function(component) { %}
        {% if (!component.hasOwnProperty('tableView') || component.tableView) { %}
          <mat-list-item style="width: {{ colWidth }}%; margin: 0 0.8rem">${content}</mat-list-item>
        {% } %}
      {% }) %}
    </mat-list>`;
  }

  validate(index: any, change: any) {
    if (!this.forms) {
      return;
    }
    const forms = this.forms.toArray();
    if (!forms[index]) {
      return;
    }

    if (!(change instanceof Event)) {
      this.isModified = change.isModified;
    }

    const formioComponent = forms[index];
    const { data } = formioComponent.formio.submission;

    formioComponent.formio.components?.forEach((c) => {
      if (data.type == 'institution') {
        if (c.component.label == 'First' || c.component.label == 'Last') {
          (c.materialComponent.element.nativeElement as HTMLElement).style.display = 'none';
        } else if (c.component.label == 'Name') {
          (c.materialComponent.element.nativeElement as HTMLElement).style.display = 'block';
          (c.materialComponent.element.nativeElement as HTMLElement).style.visibility = 'visible';
          (c.materialComponent.element.nativeElement as HTMLElement).style.position = 'relative';
          (c.materialComponent.element.nativeElement as HTMLElement).style.marginBottom = '1em';
        }
      } else if (data.type == 'anonymous') {
        if (
          c.component.label == 'First' ||
          c.component.label == 'Last' ||
          c.component.label == 'Name'
        ) {
          (c.materialComponent.element.nativeElement as HTMLElement).style.display = 'none';
        }
      } else if (data.type == 'person') {
        if (c.component.label == 'First' || c.component.label == 'Last') {
          (c.materialComponent.element.nativeElement as HTMLElement).style.display = 'block';
          (c.materialComponent.element.nativeElement as HTMLElement).style.visibility = 'visible';
          (c.materialComponent.element.nativeElement as HTMLElement).style.position = 'relative';
          (c.materialComponent.element.nativeElement as HTMLElement).style.marginBottom = '1em';
        }
      }
    });

    if (formioComponent.formio._form?.label == 'Authors') {
      if (data.type == 'person' && data.first.trim() && data.last.trim()) {
        this.valid = true;
      } else if (data.type == 'institution' && data.name.trim()) {
        this.valid = true;
      } else if (data.type == 'anonymous') {
        this.valid = true;
      } else {
        this.valid = false;
      }
    }
  }

  setInstance(instance: any) {
    if (
      instance.component.templates.header &&
      DEFAULT_HEADER_TEMPLATES.indexOf(hashCode(instance.component.templates.header)) !== -1
    ) {
      instance.component.templates.header = this.getRowTemplate('{{ component.label }}');
    }

    if (
      instance.component.templates.row &&
      DEFAULT_ROW_TEMPLATES.indexOf(hashCode(instance.component.templates.row)) !== -1
    ) {
      instance.component.templates.row = this.getRowTemplate(
        '{{ getView(component, row[component.key]) }}'
      );
    }

    this.displayedColumns = instance.component.components
      .map((comp: any) => {
        if (comp.hasOwnProperty('tableView') && !comp.tableView) {
          return false;
        }

        this.columns[comp.key] = comp;
        return comp.key;
      })
      .filter((name: any) => !!name);
    const dataValue = instance.dataValue || [];
    this.colWidth = instance.component.components.length
      ? Math.floor(100 / instance.component.components.length)
      : 100;
    if (instance.component.templates && instance.component.templates.header) {
      this.header = instance.renderString(instance.component.templates.header, {
        components: instance.component.components,
        value: dataValue,
        colWidth: this.colWidth,
      });
    }
    if (instance.component.templates && instance.component.templates.footer) {
      this.footer = instance.renderString(instance.component.templates.footer, {
        components: instance.component.components,
        value: dataValue,
        colWidth: this.colWidth,
      });
    }
    setTimeout(() => {
      this.renderTemplate(this.headerElement!, this.header);
      this.renderTemplate(this.footerElement!, this.footer);
      instance.editRows.forEach((row, i) => {
        this.saveRow(row, i);
      });
    }, 0);
    super.setInstance(instance);
  }

  addAnother() {
    const row = this.instance.addRow();
  }

  editRow(row: any, index: any) {
    const { state } = row;
    const { NEW, REMOVED } = this.RowStates;
    if (state === NEW || state === REMOVED) {
      return;
    }
    this.instance.editRow(index);
    const forms = this.forms!.toArray();

    function showElement(element: HTMLElement) {
      element.hidden = false;
      element.style.display = 'block';
      element.style.visibility = 'visible';
      element.style.position = 'relative';
      element.style.marginBottom = '1em';
    }

    if (forms[index]) {
      forms[index].formio.submission = { data: JSON.parse(JSON.stringify(row.data)) };
      setTimeout(() => {
        forms[index].formio.components?.forEach((c: any) => {
          if (row.data?.select == 'Other URL') {
            c.materialComponent.element.nativeElement.hidden = false;
            (c.materialComponent.element.nativeElement as HTMLElement).style.visibility = 'visible';
            (c.materialComponent.element.nativeElement as HTMLElement).style.position = 'relative';
            (c.materialComponent.element.nativeElement as HTMLElement).style.marginBottom = '1em';
          } else if (row.data?.type == 'institution') {
            if (c.component.label == 'First' || c.component.label == 'Last') {
              (c.materialComponent.element.nativeElement as HTMLElement).style.display = 'none';
            } else if (c.component.label == 'Name') {
              (c.materialComponent.element.nativeElement as HTMLElement).style.display = 'block';
              (c.materialComponent.element.nativeElement as HTMLElement).style.visibility =
                'visible';
              (c.materialComponent.element.nativeElement as HTMLElement).style.position =
                'relative';
              (c.materialComponent.element.nativeElement as HTMLElement).style.marginBottom = '1em';
            }
          } else if (row.data?.type == 'anonymous') {
            if (
              c.component.label == 'First' ||
              c.component.label == 'Last' ||
              c.component.label == 'Name'
            ) {
              (c.materialComponent.element.nativeElement as HTMLElement).style.display = 'none';
            }
          } else if (row.data?.type == 'Formation period') {
            // BEGIN Handle conditional rendering of filds in Temporal Coverage section
            if (c.component.label == 'Formation Period') {
              showElement(c.materialComponent.element.nativeElement as HTMLElement);
            } else if (c.component.label == 'Date') {
              c.materialComponent.element.nativeElement.hidden = true;
            }
          } else if (row.data?.type == 'Living time period') {
            if (c.component.label == 'Living time period') {
              showElement(c.materialComponent.element.nativeElement as HTMLElement);
            } else if (c.component.label == 'Date') {
              c.materialComponent.element.nativeElement.hidden = true;
            }
          } else if (row.data?.type == 'Single date') {
            if (c.component.label == 'Date') {
              showElement(c.materialComponent.element.nativeElement as HTMLElement);
            }
          } else if (row.data?.type == 'Data range') {
            if (c.component.label == 'Start date' || c.component.label == 'End date') {
              showElement(c.materialComponent.element.nativeElement as HTMLElement);
            } else if (c.component.label == 'Date') {
              c.materialComponent.element.nativeElement.hidden = true;
            }
            // END Handle conditional rendering of filds in Temporal Coverage section
          }
        });
      }, 100);
    }
  }

  /**
   * Updates the row template.
   *
   * @param row
   * @param index
   */
  updateRowTemplate(rowElement: ElementRef, index: any, comps: any) {
    const self = this;
    const editRow: any = { ...this.instance.editRows[index] };
    if (editRow.state !== this.RowStates.NEW) {
      this.renderTemplate(
        rowElement,
        this.instance.renderString(this.instance.component.templates.row, {
          row: this.instance.dataValue[index] || {},
          data: this.instance.data,
          rowIndex: index,
          colWidth: this.colWidth,
          components: this.instance.component.components,
          getView: function getView(component: any, data: any) {
            if (!comps[component.type]) {
              comps[component.type] = Components.create(component, {}, {}, true);
            }
            return comps[component.type] ? comps[component.type].getView(data) : '';
          },
        })
      );
    }
  }

  /**
   * Saves a row.
   *
   * @param row - The edit grid row.
   * @param index - The index for this row.
   */
  saveRow(row: any, index: any) {
    const forms = this.forms!.toArray();
    if (forms[index]) {
      const formioComponent = forms[index];
      row.data = JSON.parse(JSON.stringify(formioComponent.formio.submission.data));
      this.instance.saveRow(index);
      const rows = this.rowElements!.toArray();
      if (rows && rows[index]) {
        this.updateRowTemplate(rows[index], index, {});
      }
    }
  }

  cancelRow(index: any) {
    this.instance.cancelRow(index);
    this.valid = true;
  }

  renderTemplate(element: ElementRef, template: any) {
    if (!element || !element.nativeElement) {
      return;
    }
    element.nativeElement.innerHTML = template;
  }

  trackByRowId(index: number, row: any) {
    return row.id;
  }

  ngAfterViewInit() {
    this.rowElements!.changes.subscribe((rows: QueryList<ElementRef>) => {
      const rowCache = {};
      rows.forEach((row: ElementRef, index) => this.updateRowTemplate(row, index, rowCache));
    });
  }
}
EditGridComponent.MaterialComponent = MaterialEditGridComponent;
export default EditGridComponent;
