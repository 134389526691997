import { Component } from '@angular/core';
import { ServiceShare } from '../services/service-share.service';

@Component({
  selector: 'app-editor-container',
  templateUrl: './editor-container.component.html',
  styleUrls: ['./editor-container.component.scss'],
})
export class EditorContainerComponent {
  renderEditor = true;

  constructor(private serviceShare: ServiceShare) {
    this.serviceShare.YdocService.versionSubject.subscribe((event: string) => {
      this.serviceShare.ProsemirrorEditorsService.editMode = true;
      if (event == 'reconnect') {
        this.renderEditor = false;
        this.serviceShare.YdocService.shouldReconnect = true;
        setTimeout(() => {
          this.renderEditor = true;
        }, 1000);
      } else {
        this.renderEditor = false;
        this.serviceShare.YdocService.shouldReconnect = false;
        setTimeout(() => {
          this.renderEditor = true;
        }, 1000);
      }
    });
  }
}
