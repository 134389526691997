import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@core/services/app-config';

export interface authorListData {
  authorId: string;
  authorEmail: string;
}
export interface contributorData {
  name: string;
  access?: 'Edit & comment' | 'Comment only' | 'View only';
  email: string;
  id: string | null;
}

export const roleMapping = {
  Owner: 'Owner',
  author: 'Author',
  author_commenter: 'Author (can comment only)',
  contributor: 'Contributor',
  technical_evaluator: 'Technical evaluator',
  reviewer: 'Reviewer',
  subject_editor: 'Subject editor',
  copy_editor: 'Copy editor',
  reader: 'Reader',
};

export const mapping = {
  // 'WRITER': 'Edit & comment',
  // 'COMMENTER': "Comment only",
  // 'READER': "View only"
  WRITER: 'Writer',
  COMMENTER: 'Commenter',
  READER: 'Reader',
  REVIEWER: 'Reviewer',
  EDITOR: 'Editor',
};

export let accessMaping = {
  // 'Edit & comment':'WRITER',
  // "Comment only":'COMMENTER',
  // "View only":'READER'
  Writer: 'WRITER',
  Commenter: 'COMMENTER',
  Reader: 'READER',
  Reviewer: 'REVIEWER',
  Editor: 'EDITOR',
};

@Injectable({
  providedIn: 'root',
})
export class AllUsersService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  hiddenCollaborators = [
    'technical_evaluator',
    'subject_editor',
    'copy_editor',
    'reviewer',
    'article_admin',
  ];

  isHidden(role: string) {
    return this.hiddenCollaborators.includes(role);
  }

  public getAllUsers(params: { [key: string]: string | number }) {
    return this.http.get(`${this.config.apiUrl}/users`, { params }).pipe(
      map((x: any) => {
        return x.data || [];
      })
    );
  }

  public getAllUsersV2(params: { [key: string]: string | number }) {
    return this.http.get(`${this.config.apiUrl}/users`, { params });
  }

  sendCommentMentionInformation(body: any) {
    return this.http.post(`${this.config.apiUrl}/collaborators/comment`, body);
  }

  sendInviteInformation(body: any) {
    return this.http.post(`${this.config.apiUrl}/collaborators/invite`, body);
  }

  editCollaborator(body: any) {
    return this.http.patch(`${this.config.apiUrl}/collaborators/invite`, body);
  }

  removeCollaborator(body: any) {
    return this.http.request('DELETE', `${this.config.apiUrl}/collaborators/invite`, { body });
  }

  getCollaboratorsTypes() {
    return this.http.get(`${this.config.apiUrl}/collaborators/types`);
  }

  getCollaboratorsRoles() {
    return this.http.get(`${this.config.apiUrl}/collaborators/roles?visible=1`);
  }

  moveCollaborator(body: any) {
    return this.http.put(`${this.config.apiUrl}/collaborators/order`, body);
  }
}
