import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import { citableTable } from '@app/editor/utils/interfaces/citableTables';
import { supplementaryFile } from '@app/editor/utils/interfaces/supplementaryFile';

@Component({
  selector: 'app-supplementary-file',
  templateUrl: './supplementary-file.component.html',
  styleUrls: ['./supplementary-file.component.scss'],
})
export class SupplementaryFileComponent implements AfterViewInit {
  @Input() supplementaryFile?: supplementaryFile;
  @Output() supplementaryFileChange = new EventEmitter<supplementaryFile>();
  @Input() supplementaryFileIndex?: number;

  urlSafe?: SafeResourceUrl;
  constructor(
    public sanitizer: DomSanitizer,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  ngAfterViewInit(): void {}

  downloadFile(event: MouseEvent) {
    event.preventDefault();
    const url = (event.target as HTMLAnchorElement).href;
    // .replace(this.config.CDNService, "/");
    const filename = url.split('/').pop();

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);

        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }, 0);
      })
      .catch((err) => console.error(err));
  }
}
