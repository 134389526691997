import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CollaboratorsService } from '@app/editor/dialogs/add-contributors-dialog/collaborators.service';

import { YdocService } from '@app/editor/services/ydoc.service';

@Component({
  selector: 'app-head-prosemirror-view',
  templateUrl: './head-prosemirror-view.component.html',
  styleUrls: ['./head-prosemirror-view.component.scss'],
})
export class HeadProsemirrorViewComponent implements AfterViewInit {
  @ViewChild('ProsemirrorEditor', { read: ElementRef }) ProsemirrorEditor?: ElementRef;

  @Input() versionData: {
    snapshot: any;
    prevSnapshot: any;
    userData: any;
  };

  constructor(
    private ydocService: YdocService,
    private collaboratorsService: CollaboratorsService
  ) {}

  ngAfterViewInit(): void {
    try {
      if (this.ydocService.editorIsBuild) {
        this.collaboratorsService.renderHeadEditor(
          this.ProsemirrorEditor?.nativeElement,
          this.versionData
        );
      } else {
        this.ydocService.ydocStateObservable.subscribe(({ event }) => {
          if (event == 'docIsBuild') {
            this.collaboratorsService.renderHeadEditor(
              this.ProsemirrorEditor?.nativeElement,
              this.versionData
            );
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
}
