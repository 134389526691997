import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import { ImageLoaderModule } from '@app/editor/utils/image-loader/image-loader.component';
import { MaterialModule } from '@app/shared/material.module';

@Component({
  selector: 'app-figure-component-preview',
  templateUrl: './figure-component-preview.component.html',
  styleUrls: ['./figure-component-preview.component.scss'],
})
export class FigureComponentPreviewComponent implements AfterViewInit {
  @Input() component: any;
  @Output() componentChange = new EventEmitter<any>();
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit(): void {}
  getResizedImg(url: string, num: number) {
    return url.includes(this.config.CDNService)
      ? this.sanitizer.bypassSecurityTrustResourceUrl(url + '/fit_crop/' + num + 'x' + num)
      : url;
  }
}

@Component({
  selector: 'app-figure-component-preview',
  templateUrl: './figure-component-preview.component.html',
  styleUrls: ['./figure-component-preview.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule, ImageLoaderModule],
})
export class FigureComponentPreviewComponent2 implements AfterViewInit {
  @Input() component: any;
  @Output() componentChange = new EventEmitter<any>();
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit(): void {}
  getResizedImg(url: string, num: number) {
    return url.includes(this.config.CDNService)
      ? this.sanitizer.bypassSecurityTrustResourceUrl(url + '/fit_crop/' + num + 'x' + num)
      : url;
  }
}
