// src/app/shared/custom-snackbar/custom-snackbar.component.ts
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { SNACKBAR_DATA } from './snackbar-tokens';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
  standalone: true,
  imports: [MatButtonModule],
})
export class CustomSnackbarComponent {
  @Output() dismissed = new EventEmitter<void>();

  constructor(
    @Inject(SNACKBAR_DATA) public data: any,
    private overlayRef: OverlayRef
  ) {}

  onAction() {
    if (this.data.callback) {
      this.data.callback();
    }
    this.overlayRef.dispose();
    this.dismissed.emit();
  }
}
