import { Injectable } from '@angular/core';
import { keyMatchFunc } from 'casbin/lib/cjs/util';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { GlobalObjContainer } from '../interfaces';
import { Article } from '@app/core/models/article.models';

@Injectable({
  providedIn: 'root',
})
export class CasbinGlobalObjectsService {
  referenceItem: GlobalObjContainer = {
    items: {},
    isOwner: (robj, ...args) => {
      // check if there is arg that maches with requested obj
      const matched = args.some((arg) => keyMatchFunc(robj, `${arg}`));

      if (!matched) return false;

      const reqObj = robj;
      const currUserId = this.sharedService.EnforcerService.userInfo.id;

      const reqObjData = reqObj.split('/');
      let objId = reqObjData[reqObjData.length - 1];
      let ref = this.referenceItem.items[objId];
      let refOwnerId = ref.user.id;
      return refOwnerId == currUserId;
    },
  };

  constructor(private sharedService: ServiceShare) {}

  isOwner(rsub: string, effect: string, articleId: string): boolean {
    if (rsub && effect && articleId) {
      const article = this.sharedService.ArticlesService.articles?.find(
        (a: Article) => a.uuid == articleId
      );

      if (article) {
        return effect == 'allow' && article.user.id == rsub;
      }
    }

    return true;
  }

  comments(args: string[]): boolean {
    if (typeof this[args[0]] == 'function') {
      this[args[0]]();

      return true;
    } else {
      const matched = args.some((arg) =>
        keyMatchFunc(this.sharedService.YdocService.articleData?.uuid, `${arg}`)
      );

      return matched;
    }
  }

  taxons(args: string[]): boolean {
    // console.log("taxons(args: any[]) {");

    const matched = args?.some((arg) =>
      keyMatchFunc(this.sharedService.YdocService.articleData?.uuid, `${arg}`)
    );

    return matched;
  }

  sections(args: string[]): boolean {
    // console.log("sections(вargs: any[]) {");

    const matched = args?.some((arg) =>
      keyMatchFunc(this.sharedService.YdocService.articleData?.uuid, `${arg}`)
    );

    return matched;
  }

  editMode(args: string[]): boolean {
    console.log('editMode(args: any[]) {');

    const matched = args?.some((arg) =>
      keyMatchFunc(this.sharedService.YdocService.articleData?.uuid, `${arg}`)
    );

    return matched;
  }

  versions(args: string[]): boolean {
    // console.log("versions(args: any[]) {");

    const matched = args?.some((arg) =>
      keyMatchFunc(this.sharedService.YdocService.articleData?.uuid, `${arg}`)
    );

    return matched;

    // console.log(this.sharedService.YdocService.userInfo?.data?.ro);
    // return this.sharedService.YdocService.userInfo?.data?.role;
  }

  contributors(args: string[]): boolean {
    return true;
  }

  isUserVisibleForMe(args: string[]): boolean {
    console.log(this.sharedService.ProsemirrorEditorsService.userInfo);

    return true;
  }

  isCommentsVisibleForMe(args: string[]): boolean {
    return true;
  }

  addItemToGlobalContainer(glContainerKey: string, objId: string, obj: string): void {
    this[glContainerKey].items[objId] = obj;
  }

  actionButtons(): boolean {
    return true;
  }
}
