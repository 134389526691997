import { NodeSpec } from 'prosemirror-model';
import { MathNodes } from './math';
import { tableNodes } from './table';
import { listNodes } from './lists';
import {
  parseGenericAttributes,
  getGenericAttributes,
  genericAttributtesToDom,
  htmlTags,
} from '../helpers';
import { nodes as basicNodes } from './basic-nodes';
import { Node } from 'prosemirror-model';
import { trimEnd } from 'lodash';

function getSrc(numberOfColumns: number, imageSrc: string, imageId: string): string {
  const dataTreeContainer = (
    document.querySelector('.data-tree') as HTMLElement
  ).getBoundingClientRect();
  const matDrawer = (
    document.querySelector('.sidebar-container') as HTMLElement
  ).getBoundingClientRect();

  const editorPadding = 62;
  const imagesPadding = 9 * numberOfColumns;
  const floatingButtonsWidth = 38;
  const screenWidth = window.screen.width * window.devicePixelRatio;

  const outerSpace =
    (dataTreeContainer.width +
      matDrawer.width +
      editorPadding +
      imagesPadding +
      floatingButtonsWidth) *
    window.devicePixelRatio;
  const space = screenWidth - outerSpace;
  const result = (numberOfColumns ? space / numberOfColumns : space).toFixed(0);
  return `${imageSrc}/${imageId}/preview/${result}`;
}

function addStyleToClass(className, styleRule, imgSrc: string) {
  let styleSheet = document.createElement('style');
  document.head.appendChild(styleSheet);

  styleSheet.sheet.insertRule(`.${className} { ${styleRule} }`);

  const img = new Image();
  img.src = imgSrc;

  img.onload = () => {
    removeStyleFromClass('image-placeholder');
    removeStyleFromClass('figure-image');
  };
}

function removeStyleFromClass(className) {
  let stylesheets = document.styleSheets;

  for (let i = 0; i < stylesheets.length; i++) {
    let stylesheet = stylesheets[i];

    try {
      for (let j = 0; j < stylesheet.cssRules.length; j++) {
        let rule = stylesheet.cssRules[j];

        if (rule.type === CSSRule.STYLE_RULE) {
          let styleRule = rule as CSSStyleRule;

          if (styleRule.selectorText === `.${className}`) {
            stylesheet.deleteRule(j);
            break;
          }
        }
      }
    } catch (e) {
      console.warn('Cannot modify this stylesheet due to security restrictions');
    }
  }
}

export const image = {
  inline: true,
  attrs: {
    src: { default: '' },
    alt: { default: '' },
    title: { default: 'default image' },
    width: { default: undefined },
    numberOfColumns: { default: '1' },
    imageId: { default: '' },
    imageSrc: { default: '' },
    isFromCDN: { default: true },
    class: { default: 'figure-image' },
    ...getGenericAttributes({ styling: { default: 'max-width: 100%;' } }),
  },
  group: 'inline',
  draggable: true,
  parseDOM: [
    {
      tag: 'img[src]',
      getAttrs: function getAttrs(dom: any) {
        let width = dom.getAttribute('width');
        let src = dom.getAttribute('src');
        const numberOfColumns = +dom.getAttribute('numberOfColumns');
        const alt = dom.getAttribute('alt');
        const title = dom.getAttribute('title');
        const imageId = dom.getAttribute('imageId');
        const imageSrc = dom.getAttribute('imageSrc');
        const className = dom.getAttribute('class');
        const isFromCDN =
          dom.getAttribute('isFromCDN') != 'false' && dom.getAttribute('isFromCDN') != false;

        if (isFromCDN && !src) {
          src = getSrc(numberOfColumns, imageSrc, imageId);
        }

        return {
          src,
          title,
          alt,
          numberOfColumns,
          imageId,
          imageSrc,
          isFromCDN,
          class: className,
          width: width && width.length > 0 ? width : undefined,
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM: function toDOM(node: Node) {
    const figure = node.attrs;
    const numberOfColumns = +figure.numberOfColumns;
    const alt = figure.alt;
    const title = figure.title;
    const imageId = figure.imageId;
    const imageSrc = figure.imageSrc;
    const className = figure.class;
    const isFromCDN = figure.isFromCDN != 'false' && figure.isFromCDN != false;
    let src = figure.src;

    if (isFromCDN && !src && imageId && numberOfColumns) {
      src = getSrc(numberOfColumns, imageSrc, imageId);
    }

    const domAttrs = {
      src,
      alt,
      class: className,
      title,
      ...genericAttributtesToDom(node),
      numberOfColumns,
      imageSrc,
      imageId,
      isFromCDN,
    };
    if (node.attrs.width && node.attrs.width.length > 0) {
      domAttrs.width = node.attrs.width;
    }
    addStyleToClass('figure-image', 'display: none;', src);

    return ['img', domAttrs];
  },
};

const img_placeholder = {
  group: 'inline',
  content: 'inline+',
  inline: true,
  attrs: {
    imgSrc: { default: '' },
    alt: { default: '' },
    width: { default: undefined },
    numberOfColumns: { default: '1' },
    imageId: { default: '' },
    imageSrc: { default: '' },
    isFromCDN: { default: true },
    class: { default: 'image-placeholder' },
    ...getGenericAttributes({ styling: { default: 'max-width: 100%;' } }),
  },
  parseDOM: [
    {
      tag: 'img-placeholder',
      getAttrs(dom: any) {
        let width = dom.getAttribute('width');
        let imgSrc = dom.getAttribute('imageSrc');
        const numberOfColumns = +dom.getAttribute('numberOfColumns');
        const alt = dom.getAttribute('alt');
        const imageId = dom.getAttribute('imageId');
        const imageSrc = dom.getAttribute('imageSrc');
        const className = dom.getAttribute('class');
        const isFromCDN =
          dom.getAttribute('isFromCDN') != 'false' && dom.getAttribute('isFromCDN') != false;

        if (isFromCDN && !imgSrc) {
          imgSrc = getSrc(numberOfColumns, imageSrc, imageId);
        }

        return {
          imgSrc,
          alt,
          class: className,
          numberOfColumns,
          imageId,
          imageSrc,
          isFromCDN,
          width: width && width.length > 0 ? width : undefined,
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: Node) {
    const figure = node.attrs;
    const numberOfColumns = +figure.numberOfColumns;
    const alt = figure.alt;
    const imageId = figure.imageId;
    const imageSrc = figure.imageSrc;
    const className = figure.class;
    const isFromCDN = figure.isFromCDN != 'false' && figure.isFromCDN != false;
    let imgSrc = figure.imageSrc;

    let height: number;

    switch (numberOfColumns) {
      case 1: {
        height = 550;
        break;
      }
      case 2: {
        height = 400;
        break;
      }
      case 3: {
        height = 300;
        break;
      }
      case 4: {
        height = 200;
        break;
      }
    }
    if (isFromCDN && !imgSrc && imageId && numberOfColumns) {
      imgSrc = getSrc(numberOfColumns, imageSrc, imageId);
    }

    const domAttrs = {
      imgSrc,
      alt,
      class: className,
      ...genericAttributtesToDom(node),
      numberOfColumns,
      imageSrc,
      imageId,
      isFromCDN,
    };
    if (node.attrs.width && node.attrs.width.length > 0) {
      domAttrs.width = node.attrs.width;
    }

    addStyleToClass(
      'image-placeholder',
      `
      width: 100%; 
      height: ${height}px;
      background: linear-gradient(-45deg, #e0e0e0, #b3b3b3, #e0e0e0, #b3b3b3);
      animation: gradientShift 6s ease infinite, breathe 3s ease-in-out infinite;
      border-radius: 10px;`,
      imgSrc
    );

    return ['img-placeholder', domAttrs];
  },
};

export const video = {
  inline: true,
  attrs: {
    src: { default: '' },
    pdfImgOrigin: { default: '' },
    thumbnail: { default: '' },
    ...getGenericAttributes(),
  },
  group: 'inline',
  draggable: true,
  parseDOM: [
    {
      tag: 'iframe',
      getAttrs(dom: any) {
        return {
          src: dom.getAttribute('src'),
          pdfImgOrigin: dom.getAttribute('pdfImgOrigin'),
          thumbnail: dom.getAttribute('thumbnail'),
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: any) {
    let { src } = node.attrs;
    return [
      'iframe',
      {
        ...genericAttributtesToDom(node),
        controls: '',
        src,
        pdfImgOrigin: node.attrs.pdfImgOrigin,
        thumbnail: node.attrs.thumbnail,
      },
    ];
  },
};

export const videoSource = {
  inline: true,
  attrs: {
    src: { default: '' },
    width: { default: '' },
    height: { default: '' },
    ...getGenericAttributes(),
  },
  group: 'inline',
  draggable: true,
  parseDOM: [
    {
      tag: 'video',
      getAttrs(dom: any) {
        return {
          src: dom.getAttribute('src'),
          width: dom.getAttribute('width'),
          height: dom.getAttribute('height'),
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: any) {
    let { src, width, height } = node.attrs;
    return [
      'video',
      {
        ...genericAttributtesToDom(node),
        controls: '',
        src,
        width,
        height,
      },
    ];
  },
};

export const figures_nodes_container = {
  content: 'block*',
  group: 'block',
  inline: false,
  isolating: true,

  attrs: {
    containerid: { default: '' },
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'figures-nodes-container',
      getAttrs(dom: HTMLElement) {
        return {
          containerid: dom.getAttribute('containerid'),
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: any) {
    return [
      'figures-nodes-container',
      {
        containerid: node.attrs.containerid,
        ...genericAttributtesToDom(node),
      },
      0,
    ];
  },
};

export const block_figure = {
  content: 'block+',
  group: 'block',
  inline: false,
  isolating: true,
  attrs: {
    class: { default: '' },
    figure_number: {},
    figure_id: {},
    figure_columns: { default: '' },
    viewed_by_citat: { default: '' },
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'block-figure',
      getAttrs(dom: HTMLElement) {
        return {
          class: dom.getAttribute('class'),
          figure_number: dom.getAttribute('figure_number'),
          figure_id: dom.getAttribute('figure_id'),
          figure_columns: dom.getAttribute('figure_columns'),
          viewed_by_citat: dom.getAttribute('viewed_by_citat'),
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: any) {
    return [
      'block-figure',
      {
        class: node.attrs.class,
        figure_number: node.attrs.figure_number,
        figure_id: node.attrs.figure_id,
        figure_columns: node.attrs.figure_columns,
        viewed_by_citat: node.attrs.viewed_by_citat,
        ...genericAttributtesToDom(node),
      },
      0,
    ];
  },
};

export const figure_components_container = {
  group: 'block',
  content: 'block+',
  inline: false,
  attrs: {
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'figure-components-container',
      getAttrs(dom: any) {
        return {
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: Node) {
    return [
      'figure-components-container',
      {
        ...genericAttributtesToDom(node),
      },
      0,
    ];
  },
};

export const figure_component = {
  group: 'block',
  content: 'inline+',
  inline: false,
  attrs: {
    component_number: {},
    viewed_by_citat: { default: '' },
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'figure-component',
      getAttrs(dom: any) {
        return {
          component_number: dom.getAttribute('component_number'),
          viewed_by_citat: dom.getAttribute('viewed_by_citat'),
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: Node) {
    return [
      'figure-component',
      {
        viewed_by_citat: node.attrs.viewed_by_citat,
        component_number: node.attrs.component_number,
        ...genericAttributtesToDom(node),
      },
      0,
    ];
  },
};

export const figure_actions = {
  group: 'inline',
  content: 'inline+',
  inline: true,
  attrs: {
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'figure-actions',
      getAttrs(dom: any) {
        return {
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: Node) {
    return [
      'figure-actions',
      {
        ...genericAttributtesToDom(node),
      },
      0,
    ];
  },
};

const fig_action = {
  group: 'inline',
  inline: true,
  attrs: {
    class: { default: '' },
    src: { default: '' },
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'fig-action',
      getAttrs(dom: any) {
        return {
          class: dom.getAttribute('class'),
          src: dom.getAttribute('src'),
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: Node) {
    return [
      'fig-action',
      {
        src: node.attrs.src,
        class: node.attrs.class,
        ...genericAttributtesToDom(node),
      },
    ];
  },
};

export const figure_descriptions_container = {
  group: 'block',
  content: 'block+',
  isolating: true,
  inline: false,
  attrs: {
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'figure-descriptions-container',
      getAttrs(dom: any) {
        return {
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: Node) {
    return [
      'figure-descriptions-container',
      {
        ...genericAttributtesToDom(node),
      },
      0,
    ];
  },
};

export const figure_description = {
  content: 'block+',
  group: 'block',
  isolating: true,
  inline: false,
  attrs: {
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'figure-description',
      getAttrs(dom: any) {
        return {
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: any) {
    let attributesToDom: any = {
      ...genericAttributtesToDom(node),
      style: 'display:block;',
    };
    return ['figure-description', attributesToDom, 0];
  },
};

export const figure_component_description = {
  content: 'block+',
  isolating: true,
  group: 'block',
  inline: false,
  attrs: {
    component_number: {},
    viewed_by_citat: { default: '' },
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'figure-component-description',
      getAttrs(dom: any) {
        return {
          component_number: dom.getAttribute('component_number'),
          viewed_by_citat: dom.getAttribute('viewed_by_citat'),
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: any) {
    let attributesToDom: any = {
      viewed_by_citat: node.attrs.viewed_by_citat,
      component_number: node.attrs.component_number,
      ...genericAttributtesToDom(node),
      style: 'display:flex;',
    };
    return ['figure-component-description', attributesToDom, 0];
  },
};

export const figureNodes = {
  image,
  video,
  videoSource,
  //figure,
  //citation,
  block_figure,
  figure_components_container,
  figure_component,
  figures_nodes_container,
  figure_descriptions_container,
  figure_component_description,
  figure_description,
  figure_actions,
  fig_action,
  img_placeholder,
};
