export const pdfSettingsSave: any = {
  nodes: {
    h1: {
      marginTop: 10,
      marginBottom: 10,
      fontSize: 20,
      lineHeight: 1.3,
    },
    h2: {
      marginTop: 20,
      marginBottom: 10,
      fontSize: 15,
    },
    h3: {
      marginTop: 15,
      marginBottom: 10,
      fontSize: 12,
    },
    h4: {
      marginTop: 12,
      marginBottom: 8,
      fontSize: 11,
    },
    h5: {
      marginTop: 9,
      marginBottom: 6,
      fontSize: 10,
    },
    h6: {
      marginTop: 6,
      marginBottom: 4,
      fontSize: 9,
    },
    p: {
      marginTop: 2,
      marginBottom: 5,
      lineHeight: 1.2,
      fontSize: 9,
    },
    table: {
      marginTop: 5,
      marginBottom: 10,
    },
    tableLabel: {
      fontSize: 8,
      marginTop: 3.5,
      marginbottom: 3.5,
    },
    tableHeader: {
      fontSize: 7,
      marginTop: 3.5,
      marginbottom: 3.5,
    },
    tableContent: {
      fontSize: 7,
      marginTop: 3,
      marginbottom: 3,
      marginRight: 2,
      marginLeft: 2,
    },
    tableFooter: {
      fontSize: 7,
      marginTop: 3.5,
      marginbottom: 3.5,
    },
    figureHeader: {
      fontSize: 8,
      marginTop: 2,
    },
    figureContent: {
      fontSize: 7,
    },
    'block-figure': {
      marginTop: 10,
      marginBottom: 10,
    },
    ol: {
      marginTop: 5,
      marginBottom: 10,
      fontSize: 9,
    },
    ul: {
      marginTop: 5,
      marginBottom: 10,
      fontSize: 9,
    },
    'math-display': {
      marginTop: 10,
      marginBottom: 10,
    },
    'form-field': {
      marginTop: 5,
      marginBottom: 5,
      fontSize: 9,
    },
    br: {
      marginTop: 2,
      marginBottom: 2,
    },
    'form-field-inline': {
      marginTop: 2,
      marginBottom: 2,
      fontSize: 11,
    },
    'block-table': {
      marginTop: 5,
      marginBottom: 5,
    },
    'reference-citation-end': {
      marginLeft: 10,
    },
  },
  authorsSection: {
    fontSize: 7,
  },
  corespondentAuthors: {
    fontSize: 6,
  },
  maxFiguresImagesDownscale: '80%',
  maxMathDownscale: '80%',
  pageMargins: {
    marginTop: 72,
    marginRight: 72,
    marginBottom: 72,
    marginLeft: 72,
  },
  pageFormat: {
    A2: false,
    A3: false,
    A4: true,
    A5: false,
  },
  minParagraphLinesAtEndOfPage: 1,
  header: {
    marginTop: 20,
    marginBottom: 15,
    fontSize: 7,
  },
  footer: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 7,
  },
};
