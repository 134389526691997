import { Model, newEnforcer } from 'casbin';
import { from, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { log, matchAction } from '../models/matchers';
import JwtAdapter from './JwtAdapter';
import { AuthService } from '@core/services/auth.service';
import { ACL } from '../interfaces';
import { CasbinGlobalObjectsService } from '../services/casbin-global-objects.service';

export default class JwtEnforcer {
  casbin = null;
  accessControlLists: ACL[];
  authService: AuthService;

  constructor(
    accessControlLists: ACL[],
    authService: AuthService,
    private casbinGlobalObjectService: CasbinGlobalObjectsService
  ) {
    this.authService = authService;
    if (!accessControlLists) {
      throw new Error('CTOR: JWT ACLS are required!');
    }

    this.accessControlLists = accessControlLists;
  }

  aclFunction: (
    requestObj: string,
    policyObj: string,
    action: unknown,
    effect: unknown,
    test,
    requestingSubject,
    policySubject
  ) => boolean = (requestObj, policyObj, act, effect, test, requestingSubject) => {
    const re = new RegExp(/::|\(|\)/gi);

    const policyObjData = policyObj.split(re);

    if (
      typeof this.casbinGlobalObjectService?.[policyObjData[0]] == 'function' &&
      requestObj.includes(policyObjData[0])
    ) {
      const functionArgs = policyObjData[1] ? policyObjData[1].split(',') : [];

      return this.casbinGlobalObjectService?.[policyObjData[0]]([
        ...functionArgs,
        requestingSubject,
        effect,
      ]);
    } else if (typeof this.casbinGlobalObjectService?.[policyObjData[0]] == 'object') {
      const functionArgs = policyObjData[2].split(',');

      return this.casbinGlobalObjectService?.[policyObjData[0]]?.[policyObjData[1]](
        ...functionArgs
      );
    } else if (
      typeof this.casbinGlobalObjectService?.[policyObjData[0]] == 'function' &&
      policyObjData[0] == 'isOwner'
    ) {
      const requestedObjectParts = requestObj.split('/');
      const articleId = requestedObjectParts[requestedObjectParts.length - 1];
      return this.casbinGlobalObjectService?.[policyObjData[0]](
        requestingSubject,
        effect as string,
        articleId
      );
    }
    return false;
  };

  setup(model: Model): Observable<JwtEnforcer> {
    return from(newEnforcer(model, new JwtAdapter(this.accessControlLists))).pipe(
      concatMap((casbin) => {
        this.casbin = casbin;
        this.casbin.addFunction('matchAction', matchAction);
        this.casbin.addFunction('log', log);
        return from(this.casbin.addFunction('aclFunction', this.aclFunction)).pipe(map(() => this));
      })
    );
  }

  enforce(sub: string, obj: string, act: string): Observable<boolean> {
    if (!this.casbin) {
      throw new Error('Run setup() before enforcing!');
    }

    //casbin.enforce return a promise
    return from(this.casbin.enforce(sub, obj, act)) as Observable<boolean>;
  }

  enforcePromise(sub: string, obj: string, act: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.casbin.enforce(sub, obj, act).then((data) => {
        resolve(data);
      });
    });
  }

  enforceSync(sub: string, obj: string, act: string): boolean {
    const isAllowed = this.casbin.enforceSync(sub, obj, act);
    return isAllowed;
  }
}
