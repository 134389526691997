import { Injectable } from '@angular/core';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editors.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { Colors, UserDataInComment, YdocComment, YdocCommentThread } from '../comment.models';
import { commentConfig } from './comment.config';
import { Map } from 'yjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CommentService {
  private readonly commentRoles = ['author_commenter', 'reviewer'];

  private commentsMap: Map<YdocCommentThread>;

  private _mobileVersion: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private sharedService: ServiceShare,
    private ydocService: YdocService,
    private prosemirrorEditorService: ProsemirrorEditorsService
  ) {
    this._mobileVersion = this.prosemirrorEditorService.mobileVersion;

    if (this.ydocService.editorIsBuild) {
      this.commentsMap = this.ydocService.getCommentsMap();
    }
    this.ydocService.ydocStateObservable.subscribe((event) => {
      if (event == 'docIsBuild') {
        this.commentsMap = this.ydocService.getCommentsMap();
      }
    });

    this.prosemirrorEditorService.mobileVersionSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this._mobileVersion = data;
      });
  }

  get mobileVersion(): boolean {
    return this._mobileVersion;
  }

  get previewMode(): boolean {
    return this.prosemirrorEditorService.previewArticleMode.mode;
  }

  get isNewVersion(): boolean {
    return !this.sharedService.oldVersion;
  }

  get hasCommentRole(): boolean {
    const userRole = this.ydocService.currUser?.role;
    return this.commentRoles.includes(userRole);
  }

  get isNewPreviewMode(): boolean {
    return this.isNewVersion && this.previewMode;
  }

  get canActOnThread(): boolean {
    return !this.mobileVersion && (!this.isNewPreviewMode || this.hasCommentRole);
  }

  get canUserInteractInPreviewMode(): boolean {
    return (
      (!this.sharedService.oldVersion && !this.previewMode) ||
      (this.previewMode && this.hasCommentRole)
    );
  }

  get canResolveComments(): boolean {
    return this.canUserInteractInPreviewMode && this.hasCommentRole;
  }

  get canShowHeaderActions(): boolean {
    return (
      !this.sharedService.oldVersion &&
      this.ydocService.curUserAccess &&
      this.ydocService.curUserAccess !== 'Reader' &&
      this.sharedService.canAddComments
    );
  }

  isResolveRecord(record: YdocComment): boolean {
    return record.type === 'action';
  }

  getCommentThread(id: string): YdocCommentThread {
    return this.commentsMap?.get(id);
  }

  deleteCommentThread(id: string): void {
    this.commentsMap?.delete(id);
    this.sharedService.CommentsService.selectedThreadComment = undefined;
    this.sharedService.CommentsService.updateAllComments();
  }

  setCommentThread(id: string, data: YdocCommentThread): void {
    this.commentsMap?.set(id, data);
  }

  getUserColor(colorKey: keyof Colors, userData: UserDataInComment): string {
    const { colors } = commentConfig;
    const userColorKey = colorKey === 'background' ? 'userColor' : 'userContrastColor';
    return this.ydocService.currUser?.id === userData.id
      ? userData[userColorKey]
      : colors[colorKey];
  }

  getResolveButtonText(isResolved: boolean): string {
    const { resolveButtonText } = commentConfig;
    return isResolved ? resolveButtonText['unresolved'] : resolveButtonText['resolved'];
  }

  destroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
