import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';

@Injectable()
export class CasbinInterceptor implements HttpInterceptor {
  constructor(
    private sharedService: ServiceShare,
    private snackBar: SnackbarService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const casbinObj = this.getCasbinObj(request);

    if (casbinObj) {
      return this.enforceAccess(request, casbinObj, next);
    }

    return next.handle(request); // Proceed normally if no matching Casbin object
  }

  private enforceAccess(
    request: HttpRequest<unknown>,
    casbinObj: string,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.checkPermission(casbinObj, request.method).pipe(
      mergeMap((hasAccess) => (hasAccess ? next.handle(request) : this.handleUnauthorized(request)))
    );
  }

  private checkPermission(casbinObj: string, method: string): Observable<boolean> {
    return this.sharedService.EnforcerService.enforceAsync(casbinObj, method);
  }

  private handleUnauthorized(request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
    this.showUnauthorizedSnackBar();
    return this.createUnauthorizedResponse(request);
  }

  private showUnauthorizedSnackBar(): void {
    this.snackBar.error("You don't have permissions for this action.");
  }

  // Method to create the unauthorized response observable
  private createUnauthorizedResponse(
    request: HttpRequest<unknown>
  ): Observable<HttpEvent<unknown>> {
    return new Observable((sub) => {
      sub.next(
        new HttpResponse({
          body: { message: 'Not authenticated.', status: 404, url: request.url },
        })
      );
    });
  }

  private getCasbinObj(request: HttpRequest<unknown>): string | null {
    const urlParts = request.url.split('/');
    const lastPart = urlParts[urlParts.length - 1];

    if (this.isMatchingLayoutsOrStyles(request.url)) {
      return `/${lastPart}`;
    }
    if (this.isMatchingArticleItemUUID(request.url)) {
      return '/articles/items/*';
    }
    if (this.isMatchingReferencesOrItems(request.url)) {
      return `/${urlParts[urlParts.length - 2]}/${lastPart}`;
    }
    if (this.isMatchingLayoutsOrCitationStyles(request.url)) {
      return `/${urlParts[urlParts.length - 2]}/${lastPart}`;
    }
    if (this.isMatchingReferencesItemsOrSections(request.url)) {
      return `/${urlParts[urlParts.length - 3]}/${urlParts[urlParts.length - 2]}/${lastPart}`;
    }
    return null; // Let the request pass if no match
  }

  private isMatchingLayoutsOrStyles(url: string): boolean {
    return url.endsWith('/layouts') || url.endsWith('/citation-styles');
  }

  private isMatchingArticleItemUUID(url: string): boolean {
    return /articles\/items\/uuid\/\S+$/.test(url);
  }

  private isMatchingReferencesOrItems(url: string): boolean {
    return url.endsWith('/references/definitions') || url.endsWith('/articles/items');
  }

  private isMatchingLayoutsOrCitationStyles(url: string): boolean {
    return /\/layouts\/[^\/\s]+$/.test(url) || /\/citation-styles\/[^\/\s]+$/.test(url);
  }

  private isMatchingReferencesItemsOrSections(url: string): boolean {
    return (
      /\/references\/definitions\/[^\/\s]+$/.test(url) ||
      /\/articles\/items\/[^\/\s]+$/.test(url) ||
      /\/articles\/sections\/[^\/\s]+$/.test(url)
    );
  }
}
