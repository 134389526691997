import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import { ServiceShare } from '@app/editor/services/service-share.service';

import { ExportJsonLdComponent } from '../export-json-ld/export-json-ld.component';
import { exportAsJatsXML } from './jatsXML/exportAsJatsXML';
import { EditBeforeExportComponent } from '../edit-before-export/edit-before-export.component';
import { schema } from '@app/editor/utils/Schema';
import { EditorView } from 'prosemirror-view';
import { EditorState } from 'prosemirror-state';
import { pdfSettingsSave } from './pdfSettings';
import { NotificationsService } from '@app/layout/widgets/arpha-navigation/notifications/notifications.service';
import { Observable } from 'rxjs';
import { CollaboratorsService } from '../add-contributors-dialog/collaborators.service';

@Component({
  selector: 'app-export-options',
  templateUrl: './export-options.component.html',
  styleUrls: ['./export-options.component.scss'],
})
export class ExportOptionsComponent {
  selectedType: 'pdf' | 'rtf' | 'msWord' | 'jatsXml' | 'json-ld' = 'pdf';
  constructor(
    private dialog: MatDialog,
    private sharedService: ServiceShare,
    private notificationService: NotificationsService,
    private collaboratorsService: CollaboratorsService,
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  openEditBeforeExport(selected: any): void {
    if (selected == 'pdf') {
      if (this.config.production) {
        this.exportAsPdf().subscribe((data) => {
          console.log('pdf', data);
        });
      } else {
        this.dialog.open(EditBeforeExportComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '97%',
          width: '97%',
          panelClass: 'pdf-edit-and-preview',
          data: { selected },
        });
      }
    } else if (selected == 'json-ld') {
      const dialogRef = this.dialog.open(ExportJsonLdComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '97%',
        width: '97%',
        panelClass: 'pdf-edit-and-preview',
        data: { selected },
      });
    } else if (selected == 'jatsXml') {
      try {
        exportAsJatsXML(this.sharedService, this.notificationService, this.collaboratorsService);
      } catch (err) {
        this.sharedService.ProsemirrorEditorsService.stopSpinner();
        console.error(err);
      }
    }
  }

  exportAsPdf(): Observable<object> {
    const articleId = this.sharedService.YdocService.articleData.uuid;

    const headerContainer = document.createElement('div');
    const footerContainer = document.createElement('div');

    const header = new EditorView(headerContainer, {
      state: EditorState.create({
        doc: schema.nodes.doc.create(
          {},
          schema.nodes.form_field.create(
            {},
            schema.nodes.paragraph.create({}, schema.text('Header should be displayed here.'))
          )
        ),
      }),
    });
    const footer = new EditorView(footerContainer, {
      state: EditorState.create({
        doc: schema.nodes.doc.create(
          {},
          schema.nodes.form_field.create(
            {},
            schema.nodes.paragraph.create({}, schema.text('Footer should be displayed here.'))
          )
        ),
      }),
    });

    const headerPmNodesJson = header.state.doc.toJSON();
    const footerPmNodesJson = footer.state.doc.toJSON();
    const pdfSettings = this.fillSettings();

    return this.httpClient.post(
      `${this.config.apiUrl}/articles/items/` + articleId + '/pdf/export',
      { pdfSettings, headerPmNodesJson, footerPmNodesJson }
    );

    // return this.httpClient.post('http://127.0.0.1:3003/article/pdf', articleData);
  }

  private fillSettings() {
    const buildNodeSettings = (settingsFromUser: any) => {
      let nodeSettings: any;
      nodeSettings = JSON.parse(JSON.stringify(settingsFromUser.nodes));
      return nodeSettings;
    };
    const buildPdfSettings = (settingsFromUser: any) => {
      const pdfSettings: any = {};
      pdfSettings.maxFiguresImagesDownscale = settingsFromUser.maxFiguresImagesDownscale;
      pdfSettings.maxMathDownscale = settingsFromUser.maxMathDownscale;
      pdfSettings.minParagraphLinesAtEndOfPage = settingsFromUser.minParagraphLinesAtEndOfPage;
      pdfSettings.header = settingsFromUser.header;
      pdfSettings.footer = settingsFromUser.footer;
      pdfSettings.pageMargins = settingsFromUser.pageMargins;
      pdfSettings.nodes = settingsFromUser.nodes;
      Object.keys(settingsFromUser.pageFormat).forEach((format) => {
        if (settingsFromUser.pageFormat[format]) {
          pdfSettings.pageFormat = format;
        }
      });
      return pdfSettings;
    };
    const buildSettings = (settingsFromUser: any) => {
      const settings: any = {};
      const nodesSettings = buildNodeSettings(settingsFromUser);
      const pdfSettings = buildPdfSettings(settingsFromUser);
      settings.nodes = nodesSettings;
      settings.pdf = pdfSettings;
      return settings;
    };
    const data = JSON.parse(JSON.stringify(pdfSettingsSave));

    return buildSettings(data);
  }
}
