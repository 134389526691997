import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'substring',
})
export class SubstringPipe implements PipeTransform {
  transform(value: string, neededLength: number): string {
    if (value.length - 3 > neededLength) {
      let lastSpaceIndex = value.lastIndexOf(' ', neededLength);
      if (lastSpaceIndex === -1) lastSpaceIndex = neededLength;
      const result = value.substring(0, lastSpaceIndex) + '...';
      return result;
    }

    return value;
  }
}
