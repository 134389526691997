/* eslint-disable @typescript-eslint/naming-convention */
import { StateName } from '@app/editor/state-info/state-info.models';
import { Article } from './article.models';

export interface ArticlePJSInfo extends Article {
  pjs_data: PJSData | undefined[];
}

export type PJSData = {
  state_name: StateName;
  state_id: number;
  doi: string;
  citation: string;
};

export function isPJSData(value: unknown): value is PJSData {
  return (
    typeof value === 'object' && value !== null && 'state_name' in value && 'state_id' in value
  );
}
