import { PluginKey, TextSelection } from 'prosemirror-state';
import { ResolvedPos, Slice, Node, Mark } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';

import { uuidv4 } from 'lib0/random';
import { editorContainersObj } from '@app/editor/services/prosemirror-editors.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { CellSelection } from 'prosemirror-tables';
import { ArticleSection } from '../../interfaces/articleSection';
import { CITATION_ELEMENTS } from '../../menu/menu-dialogs';
import { commentMarkNames } from '../../commentsService/comments.service';
import { Options } from './editorPropsFunctions.model';

export function transformPastedHTML(html: string): string {
  if (html.includes('wikipedia.org')) {
    return html.replace(/Jump up to:/gm, '');
  }
  return html;
}

export function handlePaste(sharedService: ServiceShare, options?: Options) {
  return function handlePaste(view: EditorView, event: ClipboardEvent, slice: Slice) {
    if (options?.path == 'tableContent') {
      const $head = view.state.selection.$head;
      let isInTable = false;
      for (let d = $head.depth; d > 0; d--) {
        if ($head.node(d).type.spec.tableRole == 'row') {
          isInTable = true;
        }
      }
      if (isInTable) {
        return false;
      }

      let hasTable = false;
      view.state.doc.firstChild.content.forEach((childNode) => {
        if (childNode.type.name === 'table') {
          hasTable = true;
        }
      });

      const clipboard = event.clipboardData;
      const tableData = clipboard.getData('text/html');
      const tableRegex = /<table[\s\S]*?>[\s\S]*?<\/table>/gm;
      const isTable = tableRegex.test(tableData);

      if (hasTable && isTable) {
        return true;
      }
      return !isTable;
    }

    let newPastedCitation = false;
    let newPastedTableCitation = false;
    slice.content.nodesBetween(0, slice.size - 2, (node) => {
      if (!node.isText) {
        const citationMark = node.marks.find((mark) => mark.type.name === 'citation');
        if (citationMark) {
          const updatedCitationMark = citationMark.type.create({
            ...citationMark.attrs,
            citateid: uuidv4(),
          });
          const updatedMarks = node.marks.map((mark) =>
            mark === citationMark ? updatedCitationMark : mark
          );
          node = node.type.create(node.attrs, node.content, updatedMarks);
          newPastedCitation = true;
        }

        const tableCitationMark = node.marks.find((mark) => mark.type.name === 'table_citation');
        if (tableCitationMark) {
          const updatedTableCitationMark = tableCitationMark.type.create({
            ...tableCitationMark.attrs,
            citateid: uuidv4(),
          });
          const updatedMarks = node.marks.map((mark) =>
            mark === tableCitationMark ? updatedTableCitationMark : mark
          );
          node = node.type.create(node.attrs, node.content, updatedMarks);
          newPastedTableCitation = true;
        }

        const commentMarks = node.marks.filter((mark) => commentMarkNames.includes(mark.type.name));
        if (commentMarks.length > 0) {
          const updatedMarks = node.marks.map((mark) => {
            const updatedCommentMark = mark.type.create({ ...mark.attrs, commentmarkid: uuidv4() });
            return commentMarkNames.includes(mark.type.name) ? updatedCommentMark : mark;
          });

          node = node.type.create(node.attrs, node.content, updatedMarks);
        }

        if (node.type.name === 'math_inline' || node.type.name === 'math_display') {
          node = node.type.create(
            {
              ...node.attrs,
              // ignoring naming-convention rule as the math_id property is coming from the Prosemirror schema
              // eslint-disable-next-line @typescript-eslint/naming-convention
              math_id: uuidv4(),
            },
            node.content,
            node.marks
          );
        }

        const referenceCitationMark = node.marks.find(
          (mark) => mark.type.name === 'reference_citation'
        );
        if (referenceCitationMark) {
          const updatedReferenceCitationMark = referenceCitationMark.type.create({
            ...referenceCitationMark.attrs,
            refCitationID: uuidv4(),
          });
          const updatedMarks = node.marks.map((mark) =>
            mark === referenceCitationMark ? updatedReferenceCitationMark : mark
          );
          node = node.type.create(node.attrs, node.content, updatedMarks);
        }

        const linkMark = node.marks.find((mark) => mark.type.name === 'link');
        if (linkMark) {
          const updatedLinkMark = linkMark.type.create({
            ...linkMark.attrs,
            styling: '',
            title: node.textContent,
          });
          const updatedMarks = node.marks.map((mark) =>
            mark === linkMark ? updatedLinkMark : mark
          );
          node = node.type.create(node.attrs, node.content, updatedMarks);
        }
      }
    });

    const selection = view.state.selection;
    const { $from, $to } = selection;
    let noneditableNodes = false;
    if ($from.depth == $to.depth) {
      //@ts-expect-error path property doesn't exist in the type definitions for the current type
      let pathAtFrom: Array<Node | number> = $from.path;
      //@ts-expect-error path doesn't exist in the type definitions for the current type
      let pathAtTo: Array<Node | number> = $to.path;

      if (selection instanceof CellSelection) {
        //@ts-expect-error path property doesn't exist in the type definitions for the current type
        pathAtFrom = selection.$anchorCell.path;
        //@ts-expect-error path property doesn't exist in the type definitions for the current type
        pathAtTo = selection.$headCell.path;
      }

      let parentRef: Node | undefined;
      //search parents
      for (let i = pathAtTo.length; i > -1; i--) {
        if (i % 3 == 0) {
          const parentFrom = pathAtFrom[i] as Node;
          const parentTo = pathAtTo[i] as Node;
          if (parentFrom == parentTo) {
            if (!parentRef) {
              parentRef = parentFrom;
            } else if (
              parentFrom.type.name == 'form_field' &&
              parentRef.type.name !== 'form_field' &&
              parentRef?.attrs.contenteditableNode !== 'false' &&
              parentRef?.attrs.contenteditableNode !== false
            ) {
              parentRef = parentFrom;
            }
          }
        }
      }
      if (
        parentRef?.attrs.contenteditableNode == 'false' ||
        parentRef?.attrs.contenteditableNode === false
      ) {
        noneditableNodes = true;
      }
    }
    if (noneditableNodes) {
      return true;
    }
    /*  if(newPastedCitation){
      sharedService.YjsHistoryService.addUndoItemInformation({
        type: 'figure-citation',
        data: {}
      })
    }
    if(newPastedTableCitation){
      sharedService.YjsHistoryService.addUndoItemInformation({
        type: 'figure-citation',
        data: {}
      })
    } */
    if (newPastedCitation || newPastedTableCitation) {
      setTimeout(() => {
        //sharedService.FiguresControllerService.updateOnlyFiguresView();
        //sharedService.CitableTablesService.updateOnlyTablesView();
        sharedService.updateCitableElementsViews();
      }, 10);
    }
    return false;
  };
}

// ignore spellcheck because would have to edit prosemirror-editors-service
// eslint-disable-next-line spellcheck/spell-checker
export function selectWholeCitatMarksAndRefCitatNode(
  view: EditorView,
  anchor: ResolvedPos,
  head: ResolvedPos
): TextSelection {
  let newSelection = false;

  let newAnchor = anchor;
  let newHead = head;

  let unbreakableNodeAtAnchor = false;
  let startOfUnbreakableNodeAtAnchor: number;
  let endOfUnbreakableNodeAtAnchor: number;
  let unbreakableNodeAtHead = false;
  let startOfUnbreakableNodeAtHead: number;
  let endOfUnbreakableNodeAtHead: number;

  if (
    (anchor.nodeAfter &&
      anchor.nodeAfter.marks.some(
        (mark) => mark.type.name === 'citation' || mark.type.name === 'table_citation'
      ) &&
      anchor.nodeBefore &&
      anchor.nodeBefore.marks.some(
        (mark) => mark.type.name === 'citation' || mark.type.name === 'table_citation'
      )) ||
    (anchor.parent &&
      anchor.parent.type.name === 'reference_citation' &&
      anchor.nodeAfter &&
      anchor.nodeBefore)
  ) {
    unbreakableNodeAtAnchor = true;

    const nodeAfterSize = anchor.nodeAfter?.nodeSize;
    const nodeBeforeSize = anchor.nodeBefore?.nodeSize;

    if (nodeAfterSize !== undefined) {
      endOfUnbreakableNodeAtAnchor = nodeAfterSize + anchor.pos;
    }

    if (nodeBeforeSize !== undefined) {
      startOfUnbreakableNodeAtAnchor = anchor.pos - nodeBeforeSize;
    }
  }

  if (
    (head.nodeAfter &&
      head.nodeAfter.marks.some(
        (mark) => mark.type.name === 'citation' || mark.type.name === 'table_citation'
      ) &&
      head.nodeBefore &&
      head.nodeBefore.marks.some(
        (mark) => mark.type.name === 'citation' || mark.type.name === 'table_citation'
      )) ||
    (head.parent &&
      head.parent.type.name === 'reference_citation' &&
      head.nodeAfter &&
      head.nodeBefore)
  ) {
    unbreakableNodeAtHead = true;

    const nodeAfterSize = head.nodeAfter?.nodeSize;
    const nodeBeforeSize = head.nodeBefore?.nodeSize;

    if (nodeAfterSize !== undefined) {
      endOfUnbreakableNodeAtHead = head.pos + nodeAfterSize;
    }

    if (nodeBeforeSize !== undefined) {
      startOfUnbreakableNodeAtHead = head.pos - nodeBeforeSize;
    }
  }

  if (unbreakableNodeAtAnchor || unbreakableNodeAtHead) {
    newSelection = true;
  }
  if (anchor.pos > head.pos) {
    if (unbreakableNodeAtAnchor) {
      newAnchor = view.state.doc.resolve(endOfUnbreakableNodeAtAnchor);
    }
    if (unbreakableNodeAtHead) {
      newHead = view.state.doc.resolve(startOfUnbreakableNodeAtHead);
    }
  } else if (anchor.pos < head.pos) {
    if (unbreakableNodeAtAnchor) {
      newAnchor = view.state.doc.resolve(startOfUnbreakableNodeAtAnchor);
    }
    if (unbreakableNodeAtHead) {
      newHead = view.state.doc.resolve(endOfUnbreakableNodeAtHead);
    }
  }

  if (newSelection) {
    return new TextSelection(newAnchor, newHead);
  }
}

export function handleClick() {
  return (view: EditorView, pos: number, event: Event) => {
    const node = view.state.doc.nodeAt(pos);
    const marks = node?.marks?.length;
    if ((event as MouseEvent).detail == 1 && !marks) {
      const newSelection = TextSelection.create(view.state.doc, pos);
      view.dispatch(view.state.tr.setSelection(newSelection));
    }
    if ((event.target as HTMLElement).className == 'changes-placeholder') {
      setTimeout(() => {
        view.dispatch(view.state.tr.setMeta('addToLastHistoryGroup', true));
      }, 0);
      return true;
    }
    return false;
  };
}
export function handleTripleClickOn(view: EditorView): boolean {
  if (view.state.selection.$from.parent.type.name !== 'form_field') {
    return true;
  }
  return false;
}
export const handleDoubleClick = (hideShowPluginKey: PluginKey, serviceShare: ServiceShare) => {
  const previewMode = serviceShare.ProsemirrorEditorsService!.previewArticleMode!;
  return (view: EditorView, pos: number) => {
    const node = view.state.doc.nodeAt(pos);
    const marks = node?.marks;
    const hasTrackChangesMark = node?.marks.some((mark) => {
      return (
        mark!.type.name == 'insertion' ||
        mark!.type.name == 'deletion' ||
        mark!.type.name == 'insFromPopup' ||
        mark!.type.name == 'delFromPopup' ||
        mark!.type.name == 'format_change'
      );
    });
    if (hasTrackChangesMark && !previewMode.mode) {
      const cursorCoordinates = view.coordsAtPos(pos);
      const tr1 = view.state.tr.setMeta(hideShowPluginKey, {
        marks,
        focus: view.hasFocus(),
        coords: cursorCoordinates,
      });
      view.dispatch(tr1.setMeta('addToLastHistoryGroup', true));
      return true;
    }
    return false;
  };
};
export const handleKeyDown = (
  serviceShare: ServiceShare,
  options?: Options,
  section?: ArticleSection
) => {
  const previewMode = serviceShare.ProsemirrorEditorsService!.previewArticleMode!;
  return (view: EditorView, event: KeyboardEvent) => {
    try {
      event.stopPropagation();

      if (
        view.state.selection.from == 2 &&
        view.state.doc.content.size - 2 == view.state.selection.to &&
        section &&
        section.title.name != '[AM] Title'
      ) {
        return true;
      }

      const nodeAtSelection =
        view.state.selection.$head.parent || view.state.selection.$anchor.parent;

      if (
        nodeAtSelection &&
        (nodeAtSelection.content.size == 0 || view.state.selection.from == 2) &&
        event.key == 'Backspace' &&
        view.state.selection.from == view.state.selection.to
      ) {
        //@ts-expect-error parent doesn't exist in the type definitions for the current type
        const tagName = nodeAtSelection.parent?.attrs.tagName;
        if (
          tagName == 'H1' ||
          tagName == 'H2' ||
          tagName == 'H3' ||
          tagName == 'H4' ||
          tagName == 'H5' ||
          tagName == 'H6'
        ) {
          return true;
        }
      }

      if (
        view.state.selection.$from.parent.attrs.contenteditableNode == 'false' ||
        view.state.selection.$from.parent.attrs.contenteditableNode === false ||
        (nodeAtSelection.type.name == 'form_field' && event.key == 'Backspace') ||
        nodeAtSelection?.firstChild?.attrs?.contenteditableNode == 'false' ||
        nodeAtSelection?.firstChild?.attrs?.contenteditableNode === false
      ) {
        return true;
      }

      if (
        view.state.selection.$from.parent.firstChild?.firstChild?.attrs.contenteditableNode ==
          'false' ||
        (view.state.selection.$from.parent.firstChild?.firstChild?.attrs.contenteditableNode ===
          false &&
          view.state.selection.from !== view.state.doc.firstChild?.nodeSize + 1)
      ) {
        return true;
      }

      if (
        event.key == 'ArrowLeft' &&
        view.state.selection.from == view.state.doc.firstChild?.nodeSize + 2
      ) {
        view.focus();
        view.dispatch(
          view.state.tr.setSelection(
            TextSelection.create(view.state.doc, view.state.doc.firstChild?.nodeSize + 1)
          )
        );
        return true;
      }

      if (
        view.state.selection.$from.nodeAfter?.marks?.find((mark: Mark) =>
          CITATION_ELEMENTS.includes(mark.type.name)
        ) &&
        view.state.selection.$from.nodeBefore?.marks?.find((mark: Mark) =>
          CITATION_ELEMENTS.includes(mark.type.name)
        ) &&
        view.state.selection.from == view.state.selection.to
      ) {
        return false;
      }

      if (options?.path == 'tableContent') {
        // let { from, to } = view.state.selection
        // let coordinatesAtFrom = view.coordsAtPos(from);
        // let coordinatesAtTo = view.coordsAtPos(to);
        // let currentElement = document.elementFromPoint(coordinatesAtFrom.right, coordinatesAtTo.top)
        // const parent = currentElement?.parentElement
        // const grandParent = parent?.parentElement
        // if ((parent.classList.length||grandParent.classList.length) && !event.ctrlKey && !event.metaKey && !event.altKey && !event.shiftKey && event.key !== "Enter") {
        //   event.preventDefault();
        //   return;
        // }
        const pos = view.state.selection.$anchor.pos;
        const { parent } = view.state.doc.resolve(pos);
        if (
          parent.attrs.allowedTags &&
          !event.ctrlKey &&
          !event.metaKey &&
          !event.altKey &&
          !event.shiftKey
        ) {
          event.preventDefault();
          return true;
        }
      }

      const selection = view.state.selection;
      const { $from, $to, from, to } = selection;
      const key = event.key;
      let canEdit = false;
      if (key == 'Tab') {
        if (
          serviceShare.YjsHistoryService.undoStack.length > 0 &&
          (serviceShare.YjsHistoryService.undoStack[
            serviceShare.YjsHistoryService.undoStack.length - 1
          ].editors.length > 0 ||
            serviceShare.YjsHistoryService.undoStack[
              serviceShare.YjsHistoryService.undoStack.length - 1
            ].undoItemMeta)
        ) {
          serviceShare.YjsHistoryService.startCapturingNewUndoItem();
        }
      }
      if ($from.depth == $to.depth) {
        //@ts-expect-error path property doesn't exist in the type definitions for the current type
        let pathAtFrom: Array<Node | number> = $from.path;
        //@ts-expect-error path property doesn't exist in the type definitions for the current type
        let pathAtTo: Array<Node | number> = $to.path;

        if (selection instanceof CellSelection) {
          //@ts-expect-error path property doesn't exist in the type definitions for the current type
          pathAtFrom = selection.$anchorCell.path;
          //@ts-expect-error path property doesn't exist in the type definitions for the current type
          pathAtTo = selection.$headCell.path;
        }

        let parentRef: Node | undefined;
        //search parents
        for (let i = pathAtTo.length; i > -1; i--) {
          if (i % 3 == 0) {
            const parentFrom = pathAtFrom[i] as Node;
            const parentTo = pathAtTo[i] as Node;
            if (parentFrom == parentTo) {
              if (!parentRef) {
                parentRef = parentFrom;
              } else if (
                (!parentRef ||
                  !(
                    parentRef.attrs.contenteditableNode == 'false' ||
                    parentRef.attrs.contenteditableNode === false
                  )) &&
                parentFrom.type.name == 'form_field' &&
                parentRef.type.name !== 'form_field' &&
                (parentRef?.attrs.contenteditableNode != 'false' ||
                  parentRef?.attrs.contenteditableNode !== false)
              ) {
                parentRef = parentFrom;
              }
            }
          }
        }
        if (
          parentRef?.attrs.contenteditableNode != 'false' &&
          parentRef?.attrs.contenteditableNode !== false
        ) {
          canEdit = true;
        }
      }
      const nodeBeforeHasNoneditableMark =
        selection.$anchor.nodeBefore?.marks.some(
          (mark) =>
            mark.attrs.contenteditableNode === 'false' || mark.attrs.contenteditableNode === false
        ) || false;

      const nodeAfterHasNoneditableMark =
        selection.$anchor.nodeAfter?.marks.some(
          (mark) =>
            mark.attrs.contenteditableNode === 'false' || mark.attrs.contenteditableNode === false
        ) || false;

      let onNoneditableMarkBorder: undefined | 'left' | 'right' = undefined;
      if (nodeBeforeHasNoneditableMark && !nodeAfterHasNoneditableMark && selection.empty) {
        onNoneditableMarkBorder = 'right';
      } else if (!nodeBeforeHasNoneditableMark && nodeAfterHasNoneditableMark && selection.empty) {
        onNoneditableMarkBorder = 'left';
      } else if (nodeBeforeHasNoneditableMark && nodeAfterHasNoneditableMark) {
        canEdit = false;
      }
      let firstNodeToTheRight: Node;
      firstNodeToTheRight = selection.$to.nodeAfter;
      let posToTheRight = selection.to;
      while (!firstNodeToTheRight && posToTheRight + 1 < view.state.doc.content.size) {
        posToTheRight = posToTheRight + 1;
        const resolvedNext = view.state.doc.resolve(posToTheRight);
        firstNodeToTheRight = resolvedNext.nodeAfter;
      }
      let figNodeContToTheRight = false;
      if (firstNodeToTheRight && firstNodeToTheRight.type.name == 'figures_nodes_container') {
        figNodeContToTheRight = true;
      }

      if (onNoneditableMarkBorder) {
        if (onNoneditableMarkBorder == 'left') {
          if (key == 'Delete') {
            canEdit = false;
          }
        } else {
          if (key == 'Backspace') {
            canEdit = false;
          }
        }
      }
      if (from !== to) {
        if (
          view.state.selection.$from.nodeAfter?.marks.find((mark: Mark) =>
            CITATION_ELEMENTS.includes(mark.type.name)
          ) &&
          key != 'ArrowLeft' &&
          key != 'ArrowRight' &&
          key != 'ArrowDown' &&
          key != 'ArrowUp'
        ) {
          return true;
        }
      }

      if (key == 'Delete' && figNodeContToTheRight) {
        canEdit = false;
      }
      // check both sides for noneditable marks
      const check = (node: Node): boolean => {
        let returnValue = false;
        if (node) {
          const noneditableMarks = node.marks.filter((mark) => {
            return (
              mark.attrs.contenteditableNode == 'false' || mark.attrs.contenteditableNode === false
            );
          });
          returnValue = noneditableMarks.length > 0;
        }
        return returnValue;
      };
      const noneditableMarkAfterFrom = check($from.nodeAfter!);
      const noneditableMarkBeforeFrom = check($from.nodeBefore!);
      const noneditableMarkAfterTo = check($to.nodeAfter!);
      const noneditableMarkBeforeTo = check($to.nodeBefore!);

      if (
        noneditableMarkAfterFrom &&
        noneditableMarkBeforeFrom &&
        noneditableMarkAfterTo &&
        noneditableMarkBeforeTo
      ) {
        canEdit = false;
      } else if (noneditableMarkAfterFrom && noneditableMarkBeforeFrom) {
        canEdit = false;
      } else if (noneditableMarkAfterTo && noneditableMarkBeforeTo) {
        canEdit = false;
      }

      if (to == from) {
        if (
          noneditableMarkAfterFrom &&
          noneditableMarkAfterTo &&
          !noneditableMarkBeforeFrom &&
          !noneditableMarkBeforeTo
        ) {
          if (key == 'Delete') {
            view.dispatch(
              view.state.tr.setSelection(
                TextSelection.create(view.state.doc, from, from + $from.nodeAfter.nodeSize)
              )
            );
            canEdit = true;
          } else if (key == 'Backspace') {
            view.dispatch(view.state.tr.setSelection(TextSelection.create(view.state.doc, from)));
            canEdit = true;
          }
        } else if (
          !noneditableMarkAfterFrom &&
          !noneditableMarkAfterTo &&
          noneditableMarkBeforeFrom &&
          noneditableMarkBeforeTo
        ) {
          if (key == 'Delete') {
            view.dispatch(view.state.tr.setSelection(TextSelection.create(view.state.doc, from)));
            canEdit = true;
          } else if (key == 'Backspace') {
            view.dispatch(
              view.state.tr.setSelection(
                TextSelection.create(view.state.doc, from, from - $from.nodeBefore.nodeSize)
              )
            );
            canEdit = true;
          }
        }
      }

      if (to == from && $from.nodeAfter == null && canEdit == false) {
        const nodeAfterPlusOne = view.state.doc.nodeAt(from + 1);
        if (nodeAfterPlusOne) {
          const resolvedPos = view.state.doc.resolve(from + 1);
          let editableFirstParent = false;
          //@ts-expect-error path property doesn't exist in the type definitions for the current type
          const path = resolvedPos.path;
          for (let i = path.length - 3; i > -1; i -= 3) {
            const parentNode = path[i];
            if (
              !editableFirstParent &&
              !(
                parentNode.attrs.contenteditableNode === false ||
                parentNode.attrs.contenteditableNode == 'false'
              )
            ) {
              editableFirstParent = true;
            }
          }
          if (
            nodeAfterPlusOne.isText &&
            editableFirstParent &&
            !(
              key == 'ArrowRight' ||
              key == 'ArrowLeft' ||
              key == 'ArrowDown' ||
              key == 'Backspace' ||
              key == 'ArrowUp'
            )
          ) {
            view.dispatch(
              view.state.tr.setSelection(TextSelection.create(view.state.doc, from + 1))
            );
            canEdit = true;
          }
        }
      }
      const contentEditableNodeAroundPos = (pos: ResolvedPos): boolean => {
        let contenteditable = false;
        let noneditable = false;
        if ($from.nodeBefore && $from.nodeAfter) {
          //@ts-expect-error path property doesn't exist in the type definitions for the current type
          const path = $from.path;
          for (let i = path.length - 3; i > -1; i -= 3) {
            const parentNode = path[i];
            if (
              !noneditable &&
              !contenteditable &&
              !(
                parentNode.attrs.contenteditableNode === false ||
                parentNode.attrs.contenteditableNode == 'false'
              )
            ) {
              contenteditable = true;
            }
            if (
              !noneditable &&
              !contenteditable &&
              (parentNode.attrs.contenteditableNode === false ||
                parentNode.attrs.contenteditableNode == 'false')
            ) {
              noneditable = true;
            }
          }
        } else if (!$from.nodeBefore && $from.nodeAfter && pos.pos - 1 > 0) {
          const posMinusOne = view.state.doc.resolve(pos.pos - 1);

          //@ts-expect-error path property doesn't exist in the type definitions for the current type
          const path = posMinusOne.path;
          for (let i = path.length - 3; i > -1; i -= 3) {
            const parentNode = path[i];
            if (
              !contenteditable &&
              !(
                parentNode.attrs.contenteditableNode === false ||
                parentNode.attrs.contenteditableNode == 'false'
              )
            ) {
              contenteditable = true;
            }
            if (
              !noneditable &&
              !contenteditable &&
              (parentNode.attrs.contenteditableNode === false ||
                parentNode.attrs.contenteditableNode == 'false')
            ) {
              noneditable = true;
            }
          }
        } else if (
          $from.nodeBefore &&
          !$from.nodeAfter &&
          pos.pos + 1 < view.state.doc.content.size
        ) {
          const posPlusOne = view.state.doc.resolve(pos.pos + 1);

          //@ts-expect-error path property doesn't exist in the type definitions for the current type
          const path = posPlusOne.path;
          for (let i = path.length - 3; i > -1; i -= 3) {
            const parentNode = path[i];
            if (
              !contenteditable &&
              !(
                parentNode.attrs.contenteditableNode === false ||
                parentNode.attrs.contenteditableNode == 'false'
              )
            ) {
              contenteditable = true;
            }
            if (
              !noneditable &&
              !contenteditable &&
              (parentNode.attrs.contenteditableNode === false ||
                parentNode.attrs.contenteditableNode == 'false')
            ) {
              noneditable = true;
            }
          }
        }
        return contenteditable;
      };
      if (from != to) {
        const nonEditableParent = (path: unknown[]): boolean => {
          let noneditable = false;
          const counter = path.length - 3;
          for (let i = counter; i > -1; i -= 3) {
            const node = path[i] as Node;
            if (
              node.attrs.contenteditableNode === false ||
              node.attrs.contenteditableNode === 'false'
            ) {
              noneditable = true;
            }
          }
          return noneditable;
        };
        //@ts-expect-error path property doesn't exist in the type definitions for the current type
        const nonEditableNodeAtFrom = nonEditableParent($from.path);
        //@ts-expect-error path property doesn't exist in the type definitions for the current type
        const nonEditableNodeAtTo = nonEditableParent($to.path);
        if (nonEditableNodeAtFrom || nonEditableNodeAtTo) {
          canEdit = false;
        }
      }
      if (
        !canEdit &&
        to !== from &&
        contentEditableNodeAroundPos($from) &&
        contentEditableNodeAroundPos($to)
      ) {
        //view.dispatch(view.state.tr.setSelection(new TextSelection(view.state.doc.resolve(from - 1), view.state.doc.resolve(to + 1))))
        canEdit = true;
      }

      if (section) {
        const nodes = [];
        view.state.doc.nodesBetween(view.state.selection.from, view.state.selection.to, (node) => {
          if (node.type.name == 'heading' || node.type.name == 'form_field') {
            nodes.push(node);
          }
        });

        if (nodes.length == 2) {
          return true;
        }
      }

      if (!canEdit || previewMode.mode) {
        if (key == 'ArrowRight' || key == 'ArrowLeft' || key == 'ArrowDown' || key == 'ArrowUp') {
          return false;
        } else {
          return true;
        }
      }

      handleCitedTablesAndFiguresViews(view, serviceShare);
    } catch (e) {
      console.error(e);
    }
    if (event.key == 'Backspace') {
      serviceShare.YdocService.ydoc.getMap('change').set('change', 'change');
    }
    return false;
  };
};
export const createSelectionBetween = (
  editorsEditableObj: { [key: string]: boolean },
  editorId: string
) => {
  return (view: EditorView, anchor: ResolvedPos, head: ResolvedPos) => {
    if (anchor.pos == head.pos) {
      return new TextSelection(anchor, head);
    }
    let headRangeMin = anchor.pos;
    let headRangeMax = anchor.pos;
    const selection = view.state.selection;

    //@ts-expect-error path property doesn't exist in the type definitions for the current type
    const anchorPath = selection.$anchor.path;
    let counter = anchorPath.length - 1;
    let parentNode: Node | undefined = undefined;
    let parentNodePos: number | undefined = undefined;
    let formFieldParentFound = false;
    while (counter > -1 && !formFieldParentFound) {
      const pathValue = anchorPath[counter];
      if (typeof pathValue !== 'number') {
        // node
        const parentType = pathValue.type.name;
        if (parentType == 'form_field') {
          parentNode = pathValue; // store the form_field node that the selection is currently in
          parentNodePos = anchorPath[counter - 1];
          formFieldParentFound = true;
        } else if (parentType !== 'doc') {
          parentNode = pathValue; // store last node in the path that is different than the doc node
          parentNodePos = anchorPath[counter - 1];
        }
      }
      counter--;
    }

    if (parentNode && parentNodePos !== undefined && parentNode.nodeSize !== undefined) {
      headRangeMin = parentNodePos + 1; // the parent's inner start position
      headRangeMax = parentNodePos + parentNode.nodeSize - 1; // the parent's inner end position
    }

    if (headRangeMin > head.pos || headRangeMax < head.pos) {
      const headPosition = headRangeMin > head.pos ? headRangeMin : headRangeMax;
      const newHeadResolvedPosition = view.state.doc.resolve(headPosition);
      const from = Math.min(view.state.selection.$anchor.pos, newHeadResolvedPosition.pos);
      const to = Math.max(view.state.selection.$anchor.pos, newHeadResolvedPosition.pos);
      view.state.doc.nodesBetween(from, to, (node) => {
        if (node.attrs.contenteditableNode == 'false' || node.attrs.contenteditableNode === false) {
          editorsEditableObj[editorId] = false;
        }
      });
      const newSelection = new TextSelection(anchor, newHeadResolvedPosition);
      return newSelection;
    }
    const from = Math.min(anchor.pos, head.pos);
    const to = Math.max(anchor.pos, head.pos);
    view.state.doc.nodesBetween(from, to, (node) => {
      if (node.attrs.contenteditableNode == 'false' || node.attrs.contenteditableNode === false) {
        editorsEditableObj[editorId] = false;
      }
    });
    return undefined;
  };
};

export function handleScrollToSelection(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  editorContainers: editorContainersObj,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  section: ArticleSection
) {
  return () => {
    /*
    editorContainers[section.sectionID].containerDiv.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"}) */
    return false;
  };
}

export const handleBlur =
  (section: ArticleSection, serviceShare: ServiceShare) => (view: EditorView) => {
    const doc = view.state.doc;
    const previewMode = serviceShare.ProsemirrorEditorsService!.previewArticleMode!;
    if (previewMode.mode) {
      return false;
    }
    doc.forEach((node: Node) => {
      if (node.type.name == 'form_field' && node.attrs['exportMeta']) {
        const content = (
          serviceShare.ProsemirrorEditorsService.FullSchemaDOMPMSerializer.serializeNode(
            node
          ) as HTMLElement
        ).innerHTML
          .replace(/<([a-zA-Z0-9]+)([^>]*)\/?>/g, '<$1>')
          .trim();
        const body = {
          metadata: { [node.attrs['exportMeta']]: content },
        };

        if (
          body.metadata[node.attrs['exportMeta']] !=
          serviceShare.YdocService.articleData.metadata?.[node.attrs['exportMeta']]
        ) {
          serviceShare.httpClient
            .patch(
              `${serviceShare.config.apiGatewayService}/api/articles/items/${serviceShare.YdocService.articleData.id}`,
              body
            )
            .subscribe({
              next: (res: unknown) => {
                console.log(res);
              },
              error: (err: unknown) => {
                console.error(err);
              },
            });
        }
      }
    });
  };

/**
 * Refreshes views of cited tables and figures within the current editor selection.
 *
 * Scans the selected range in the editor view for "table" (`example`) or "figure" (`figure_component`) nodes.
 * If found, a slightly delayed update is triggered to refresh the views of these elements.
 */
export function handleCitedTablesAndFiguresViews(
  view: EditorView,
  sharedService: ServiceShare
): void {
  const { from, to } = view.state.selection;

  view.state.doc.nodesBetween(from, to, (node) => {
    if (node.type.name === 'example' || node.type.name === 'figure_component') {
      setTimeout(() => {
        sharedService.updateCitableElementsViews();
      }, 10);
    }
  });
}
