import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { AllUsersService, contributorData } from '@app/core/services/all-users.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { ErrorMessage, validateCountry } from '../edit-contributor/edit-contributor.component';
import { ArticleCollaborator } from '@app/core/models/article.models';
import { Role } from '@app/core/models/user.model';

export let countryNames = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua &amp; Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia &amp; Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Cape Verde',
  'Cayman Islands',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Cote D Ivoire',
  'Croatia',
  'Cruise Ship',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Polynesia',
  'French West Indies',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kuwait',
  'Kyrgyz Republic',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Pierre &amp; Miquelon',
  'Samoa',
  'San Marino',
  'Satellite',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'St Kitts &amp; Nevis',
  'St Lucia',
  'St Vincent',
  'St. Lucia',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  "Timor L'Este",
  'Togo',
  'Tonga',
  'Trinidad &amp; Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks &amp; Caicos',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'Uzbekistan',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (US)',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

@Component({
  selector: 'app-send-invitation',
  templateUrl: './send-invitation.component.html',
  styleUrls: ['./send-invitation.component.scss'],
})
export class SendInvitationComponent implements AfterViewInit, OnDestroy, AfterViewChecked {
  getAffiliationGroup() {
    return new UntypedFormGroup({
      affiliation: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      country: new UntypedFormControl('', [Validators.required, validateCountry]),
    });
  }

  affiliationErrorMessages: ErrorMessage[] = [
    { type: 'required', message: 'Affiliation is required.' },
  ];

  cityErrorMessages: ErrorMessage[] = [{ type: 'required', message: 'City is required.' }];

  countryErrorMessages: ErrorMessage[] = [
    { type: 'invalidValue', message: 'Country not recognized.' },
    { type: 'required', message: 'Country is required.' },
  ];

  filter(val: string) {
    return countryNames.filter((y: string) => y.toLowerCase().startsWith(val.toLowerCase()));
  }

  email = new UntypedFormControl(this.data.contributor.email || '', Validators.required);
  firstName = new UntypedFormControl(this.data?.contributor.first_name || '', Validators.required);
  lastName = new UntypedFormControl(this.data?.contributor.last_name || '', Validators.required);
  roleSelect = new UntypedFormControl('author', Validators.required);
  affiliations = new UntypedFormArray([this.getAffiliationGroup()]);
  message = new UntypedFormControl('Invitation message.', Validators.required);
  isCoAuthor = new UntypedFormControl(false);

  inviteUsersForm: any = new UntypedFormGroup({
    email: this.email,
    firstName: this.firstName,
    lastName: this.lastName,
    roleSelect: this.roleSelect,
    affiliations: this.affiliations,
    message: this.message,
    isCoAuthor: this.isCoAuthor,
  });

  removeAffiliation(index: number) {
    this.affiliations.removeAt(index);
  }

  addAffiliation() {
    this.affiliations.push(this.getAffiliationGroup());
  }

  isLoading = true;

  roleOptions: any[] = [
    {
      name: 'Author',
    },
    {
      name: 'Corresponding author',
    },
    {
      name: 'Contributor',
    },
  ];

  resultData = new Subject<contributorData[]>();

  constructor(
    private location: Location,
    private serviceShare: ServiceShare,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SendInvitationComponent>,
    public allUsersService: AllUsersService,
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public data: { contributor: ArticleCollaborator; fromComment: boolean }
  ) {
    this.allUsersService.getCollaboratorsRoles().subscribe({
      next: (res: any) => {
        this.roleOptions = res.data.filter((r: any) => !r.is_hidden);
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  get isAuthor() {
    return this.roleSelect.value == Role.author || this.roleSelect.value == Role.commenter;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submitInviteUsersForm() {
    if (this.roleSelect.value != Role.author && this.roleSelect.value != Role.commenter) {
      this.isCoAuthor.setValue(false);
    }

    this.dialogRef.close({
      usersChipList: [
        {
          id: this.data.contributor.id || null,
          first_name: this.firstName.value,
          last_name: this.lastName.value,
          email: this.email.value,
          name: `${this.firstName.value} ${this.lastName.value}`,
        },
      ],
      isCoAuthor: this.isCoAuthor.value,
      affiliations:
        this.roleSelect.value == Role.author || this.roleSelect.value == Role.commenter
          ? this.affiliations.value.filter((x) => {
              return !(
                (!x.affiliation || x.affiliation.length == 0) &&
                (!x.country || x.country.length == 0) &&
                (!x.city || x.city.length == 0)
              );
            })
          : [],
      roleSelect: this.roleSelect.value,
      message: this.message.value,
    });
  }
  dialogIsOpenedFromComment = false;
  collaboratorstSubs: Subscription;

  ngAfterViewInit(): void {
    this.roleSelect?.valueChanges.subscribe((value) => {
      if ((value === Role.author || value === Role.commenter) && this.affiliations.length === 0) {
        this.addAffiliation();
      } else if (
        value !== Role.author &&
        value !== Role.commenter &&
        this.affiliations.length > 0
      ) {
        this.affiliations.clear();
      }
    });

    if (this.data.fromComment) {
      this.dialogIsOpenedFromComment = true;
      this.roleSelect.setValue('contributor');
    }

    this.ref.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.collaboratorstSubs) {
      this.collaboratorstSubs.unsubscribe();
    }
  }
}
