import { Component, ElementRef, inject, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { basicArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { ImportWizardDialogComponent } from '../JATS-wizard-modal/import-wizard-modal.component';
import { ImportJatsService } from '../importAsJatsXML.service';
import * as PModel from 'prosemirror-model';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';
import { DataPaperService } from '../data-paper.service';

@Component({
  selector: 'app-jats-import-modal',
  templateUrl: './jats-import-modal.component.html',
  styleUrls: ['./jats-import-modal.component.scss'],
})
export class JATSImportModalComponent {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  isLoading = false;

  constructor(
    public dialog: MatDialog,
    public serviceShare: ServiceShare,
    public importJatsService: ImportJatsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {},
    private dialogRef: MatDialogRef<JATSImportModalComponent>,
    private snackBar: SnackbarService,
    private dataPaperService: DataPaperService
  ) {}

  /**
   * Checks what type the given XML is.
   * If the root element of the XML document is `eml:eml`, assume the XML is EML
   */
  determineXMLType(doc: Document) {
    const emlElement = doc.getElementsByTagName('eml:eml')[0];
    let xmlType = emlElement ? 'eml' : 'jats';

    return xmlType;
  }

  async import(event: any) {
    const file = event.target.files[0] as File;

    if (file && file.type == 'text/xml') {
      this.isLoading = true;
      let parser = new DOMParser();
      this.serviceShare.ProsemirrorEditorsService.spinSpinner();
      file.text().then((fileContent: string) => {
        const doc = parser.parseFromString(fileContent, 'text/xml');
        const xmlType = this.determineXMLType(doc);

        if (xmlType === 'eml') {
          const formData = new FormData();
          formData.append('file', file);

          this.dataPaperService.convertToJats(file).subscribe(
            (result: string) => {
              const doc = parser.parseFromString(result, 'text/xml');
              this.processJats(doc, event);
            },
            (error) => {
              console.error('Error converting EML to JATS', error);
              event.target.value = '';
              this.isLoading = false;
              this.serviceShare.ProsemirrorEditorsService.stopSpinner();
              this.snackBar.error('Error converting EML to JATS.');
            }
          );
        } else {
          this.processJats(doc, event);
        }
      });
    }
  }

  processJats(doc: Document, event: any) {
    this.importJatsService
      .parseXML(
        doc,
        this.serviceShare.YdocService.articleStructureMap.get(
          'articleSectionsStructure'
        ) as basicArticleSection[],
        this.serviceShare.YdocService.articleStructureMap.get(
          'articleSectionsStructureFlat'
        ) as string[]
      )
      .then(
        (data: {
          sectionsContent: {
            [key: string]: {
              doc: PModel.Node | null;
              sectionTitle: string;
              secID: string;
              level?: number;
              parsedSecTitle?: string;
            };
          };
          allJatsSections: { title: string; section: Element }[];
        }) => {
          this.dialog
            .open(ImportWizardDialogComponent, {
              width: '90%',
              height: '100px !important',
              panelClass: 'wizard-dialog',
              data: data,
              disableClose: true,
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                // wizard modal was submitted, close the import modal
                this.dialogRef.close();
              } else {
                // wizard modal was cancelled, leave import modal open
                this.isLoading = false;
              }
            });
          event.target.value = '';
          this.serviceShare.ProsemirrorEditorsService.stopSpinner();
        }
      );
  }

  cancelImport() {
    this.dialogRef.close();
  }
}
