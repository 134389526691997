import { Injectable } from '@angular/core';
import { merge, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { ArticlesService } from '@app/core/services/articles.service';
import { ArticlePJSInfo, isPJSData } from '@app/core/models/pjs.models';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';
import { NotificationsService } from '@app/layout/widgets/arpha-navigation/notifications/notifications.service';
import { StateName } from '../state-info.models';

@Injectable()
export class StateInfoService {
  private readonly statusRetrievalError =
    'An error occurred, while retrieving document status details!';

  constructor(
    private articlesService: ArticlesService,
    private snackBar: SnackbarService,
    private notificationService: NotificationsService
  ) {}

  getDocumentState(): Observable<StateName> {
    return merge(this.getLatestArticleStream(), this.getNotificationStream()).pipe(
      switchMap((response) => {
        const data = response.pjs_data;
        return isPJSData(data) ? of(data.state_name) : throwError('No pjs info');
      }),
      catchError((error) => {
        console.error('Error fetching document state:', error);
        this.snackBar.error(this.statusRetrievalError);
        return of(null);
      }),
      filter((data) => !!data),
      distinctUntilChanged()
    );
  }

  private getLatestArticleStream(): Observable<ArticlePJSInfo> {
    return this.articlesService.latestRetrievedArticle$;
  }

  private getNotificationStream(): Observable<ArticlePJSInfo> {
    return this.notificationService.articleUpdated$;
  }
}
