//@ts-ignore
import { NodeSpec } from 'prosemirror-model';
import { MathNodes } from './math';
import { tableNodes } from './table';
import { listNodes } from './lists';
import {
  parseGenericAttributes,
  getGenericAttributes,
  genericAttributtesToDom,
  htmlTags,
} from '../helpers';
import { nodes as basicNodes } from './basic-nodes';
import { figureNodes } from './figure-nodes';
import { tableNodes as citableTableNodes } from './citable-tables';
import { supplementaryFileNodes } from './supplementary-files';
import { endNotesNodes } from './end-notes';
import {
  reference_block_container,
  reference_citation_end,
  reference_container,
} from './ref-nodes';

export const paragraph = {
  content: 'inline*',
  group: 'block',
  attrs: {
    align: { default: 'set-align-left' },
    indent: { default: '0' },
    change: { default: undefined },
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'p',
      getAttrs(dom: any) {
        let classArray = dom.getAttribute('class');
        let indent = +dom.getAttribute('indent');
        let change = dom.getAttribute('change');
        const attrs = {
          align: classArray,
          indent,
          ...parseGenericAttributes(dom),
        };

        if (change && change) {
          attrs['style'] = change;
        }

        const num = Math.floor(indent * 2) > 54 ? 54 : Math.floor(indent * 2);
        if (num > 0) {
          if (attrs['style']) {
            attrs['style'] = attrs['style'] + `margin-left: ${num}em !important;`;
          } else {
            attrs['style'] = `margin-left: ${num}em !important;`;
          }
        } else {
          if (attrs['style']) {
            let string = (attrs['style'] as string).split(/margin-left: \w+ !important;/).join('');
            attrs['style'] = string;
          } else {
            attrs['style'] = '';
          }
        }

        return attrs;
      },
    },
  ],
  toDOM(node: any) {
    let attributesToDom: any = {
      ...genericAttributtesToDom(node),
    };
    attributesToDom['class'] = node.attrs.align;
    attributesToDom['indent'] = node.attrs.indent;
    attributesToDom['change'] = node.attrs.change;

    if (attributesToDom['change']) {
      attributesToDom['style'] = attributesToDom['change'];
    }

    const num =
      Math.floor(attributesToDom['indent']) * 2 > 54
        ? 54
        : Math.floor(attributesToDom['indent'] * 2);
    if (num > 0) {
      if (attributesToDom['style']) {
        attributesToDom['style'] = attributesToDom['style'] + `margin-left: ${num}em !important;`;
      } else {
        attributesToDom['style'] = `margin-left: ${num}em !important;`;
      }
    } else {
      if (attributesToDom['style']) {
        let string = (attributesToDom['style'] as string)
          .split(/margin-left: \w+ !important;/)
          .join('');
        attributesToDom['style'] = string;
      } else {
        attributesToDom['style'] = '';
      }
    }

    return ['p', attributesToDom, 0];
  },
};

export const form_field_inline = {
  content: 'inline*',
  group: 'inline',
  inline: true,
  isolating: true,
  attrs: {
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'form-field-inline',
      getAttrs(dom: any) {
        return {
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: any) {
    let attributesToDom: any = {
      ...genericAttributtesToDom(node),
    };
    return ['form-field-inline', attributesToDom, 0];
  },
};

export const form_field_inline_view = {
  content: 'block*',
  group: 'block',
  isolating: true,
  attrs: {
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'form-field-inline-view',
      getAttrs(dom: any) {
        return {
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: any) {
    let attributesToDom: any = {
      ...genericAttributtesToDom(node),
    };
    return ['form-field-inline-view', attributesToDom, 0];
  },
};

export const form_field = {
  content: '(paragraph|block)+',
  group: 'block',
  isolating: true,
  attrs: {
    ...getGenericAttributes(),
    exportMeta: { default: '' },
  },
  parseDOM: [
    {
      tag: 'form-field',
      getAttrs(dom: any) {
        return {
          ...parseGenericAttributes(dom),
          exportMeta: dom.getAttribute('exportMeta'),
        };
      },
    },
  ],
  toDOM(node: any) {
    let attributesToDom: any = {
      ...genericAttributtesToDom(node),
      exportMeta: node.attrs['exportMeta'],
    };
    return ['form-field', attributesToDom, 0];
  },
};

export const inline_block_container = {
  content: 'block+',
  group: 'block',
  attrs: {
    ...getGenericAttributes(),
  },
  parseDOM: [
    {
      tag: 'inline-block-container',
      getAttrs(dom: any) {
        return {
          ...parseGenericAttributes(dom),
        };
      },
    },
  ],
  toDOM(node: any) {
    let attributesToDom: any = {
      ...genericAttributtesToDom(node),
    };
    return ['inline-block-container', attributesToDom, 0];
  },
};

export const nodes: NodeSpec = {
  doc: {
    content: 'block*',
  },
  form_field,
  inline_block_container,
  paragraph,
  form_field_inline,
  form_field_inline_view,
  reference_citation_end,
  reference_container,
  reference_block_container,
  //placeholder,
  example: {
    attrs: {
      id: { default: '' },
    },
    inline: false,
    draggable: false,
    selectable: false,
    atom: false,
    group: 'block',
    toDOM(node) {
      return ['div', { 'data-type': 'example', id: node.attrs['id'] }, ''];
    },
    parseDOM: [
      {
        tag: 'div[data-type=example]',
        getAttrs(dom) {
          return {};
        },
      },
    ],
  },
  ...tableNodes({
    tableGroup: 'block',
    cellContent: 'form_field{1}',
    cellAttributes: {
      background: {
        default: null,
        //@ts-ignore
        getFromDOM(dom) {
          return dom.style.backgroundColor || null;
        },
        setDOMAttr(value: any, attrs: any) {
          if (value) attrs.style = (attrs.style || '') + `background-color: ${value};`;
        },
      },
    },
  }),
  ...figureNodes,
  ...citableTableNodes,
  ...supplementaryFileNodes,
  ...endNotesNodes,
  text: {
    inline: true,
    group: 'inline',
  },
  ...basicNodes,
  ...MathNodes,
  ...listNodes,
};
