import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { ServiceShare } from '@app/editor/services/service-share.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  constructor(
    private _http: HttpClient,
    private serviceShare: ServiceShare,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  journals: any[];
  logoUrl = 'i/journal_logos/';

  ngOnInit(): void {
    this.serviceShare.ArticleSectionsService.getJournals().subscribe((journalsData: any) => {
      if (journalsData) {
        this.journals = journalsData.data;
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.serviceShare.ProsemirrorEditorsService.spinning) {
      this.serviceShare.ProsemirrorEditorsService.stopSpinner();
    }
  }
}
