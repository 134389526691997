import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree, CanActivate } from '@angular/router';
import { from, Observable } from 'rxjs';
import { mergeMap, shareReplay, tap } from 'rxjs/operators';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { SnackbarService } from '@core/services/snackbar/snackbar.service';
import { Article, User, Collaborator } from '@app/core/models/article.models';
import { Response } from '@app/core/models/http.models';
import { AuthService } from '@app/core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CasbinGuard implements CanActivate {
  constructor(
    private router: Router,
    private sharedService: ServiceShare,
    private authService: AuthService,
    private snackBar: SnackbarService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const baseComponentPath = route.pathFromRoot[2].routeConfig.path;
    const isArticleRoute = route.pathFromRoot.length > 2 && baseComponentPath === ':id';
    const isCreateRoute = route.pathFromRoot.length > 2 && baseComponentPath === 'create';

    if (isArticleRoute) {
      const articleId = route.params.id;
      return this.handleArticleAccess(articleId, route);
    } else if (isCreateRoute) {
      return this.handleCreateAccess();
    }

    return true; // Default to allowing access for other routes
  }

  private handleArticleAccess(
    articleId: string,
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    return from(
      new Promise<boolean>((resolve) => {
        this.authService.currentUser$.subscribe({
          next: (userData) => {
            const articleRequest = this.getArticle(articleId);
            articleRequest
              .pipe(
                mergeMap((articleResponse) =>
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  this.handleArticleResponse(articleResponse, userData as any, articleId)
                ),
                tap((hasAccess) => this.handleAccessDenied(!hasAccess, route))
              )
              .subscribe(resolve);

            // Add resolver data here to ensure it's cached and available for resolvers
            this.sharedService.addResolverData('CasbinResolver', articleRequest);
          },
          error: () => this.redirectTo404(resolve),
        });
      })
    );
  }

  private getArticle(articleId: string): Observable<Response<Article>> {
    const cachedArticle = this.sharedService.ArticlesService.replayObservable;
    return (
      cachedArticle ||
      this.sharedService.ArticlesService.getArticleByUuid(articleId).pipe(shareReplay())
    );
  }

  private handleArticleResponse(
    articleResponse: Response<Article>,
    userData: User,
    articleId: string
  ): Observable<boolean> {
    if (articleResponse.status === 404) {
      this.router.navigate(['404']);
      return from([true]);
    }

    return this.checkUserAccessToArticle(articleResponse, userData).pipe(
      mergeMap((hasAccess) => {
        if (!hasAccess) {
          return from([false]);
        }

        return this.sharedService.ArticlesService.getArticleDomainPolicies(articleId).pipe(
          tap((policies) =>
            this.sharedService.EnforcerService.policiesChangeSubject.next(policies)
          ),
          mergeMap(() => from([true])) // Continue after fetching policies
        );
      })
    );
  }

  private checkUserAccessToArticle(
    articleResponse: Response<Article>,
    userData: User
  ): Observable<boolean> {
    const isAdmin$ = this.sharedService.EnforcerService.enforceAsync(
      'is-admin',
      'admin-can-do-anything'
    );
    const userId = userData.id;
    const collaborators = articleResponse.data.collaborators || [];

    return isAdmin$.pipe(
      mergeMap((isAdmin) => {
        if (
          // isAdmin || // TODO admin acces is still not supported more changes are needed in different part of the app (editor.component 376)
          articleResponse.data.user.id === userId ||
          this.isUserCollaborator(userId, collaborators)
        ) {
          return from([true]);
        } else {
          return from([false]);
        }
      })
    );
  }

  private isUserCollaborator(userId: string, collaborators: Collaborator[]): boolean {
    return collaborators.some((user) => user.user_id === userId);
  }

  private handleCreateAccess(): Observable<boolean> {
    return from(
      new Promise<boolean>((resolve) => {
        this.sharedService.EnforcerService.enforceAsync('is-admin', 'admin-can-do-anything')
          .pipe(
            mergeMap((isAdmin) => {
              if (isAdmin) {
                return from([true]);
              }
              return this.checkCreateArticlePermission();
            }),
            tap((hasAccess) => this.handleAccessDenied(!hasAccess))
          )
          .subscribe(resolve);
      })
    );
  }

  private checkCreateArticlePermission(): Observable<boolean> {
    return this.sharedService.EnforcerService.enforceAsync('/layouts', 'POST').pipe(
      mergeMap((canCreateArticle) => from([canCreateArticle]))
    );
  }

  private handleAccessDenied(isDenied: boolean, route?: ActivatedRouteSnapshot): void {
    if (isDenied && route?.pathFromRoot[2].routeConfig.path === ':id') {
      this.snackBar.error("You don't have permission to access this article.");
      this.router.navigate(['dashboard']);
    }
  }

  private redirectTo404(resolve: (value: boolean) => void): void {
    this.router.navigate(['404']);
    resolve(true);
  }
}
